import { makeStyles, Theme } from '@material-ui/core';
import { Styles } from '@material-ui/styles';
import BirthdateInput from 'components/CustomInput/BirthdateInput';
import PhoneNumberInput from 'components/CustomInput/PhoneNumberInput';
import { Separator } from 'components/formUtils';
import { Create, CreateProps, required, SimpleForm, TextInput, useTranslate } from 'react-admin';

const styles: Styles<Theme, any> = (theme) => ({
  inlineInfo: {
    [theme.breakpoints.up('md')]: { display: 'inline-block', marginRight: '15px' },
  },
});

const useStyles = makeStyles(styles);

export const UserCreate = (props: CreateProps) => {
  const translate = useTranslate();
  const classes = useStyles(styles);

  return (
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput
          source="person.firstName"
          validate={required()}
          formClassName={classes.inlineInfo}
        />
        <TextInput
          source="person.lastName"
          validate={required()}
          formClassName={classes.inlineInfo}
        />
        <Separator />
        <TextInput source="person.email" formClassName={classes.inlineInfo} />
        <PhoneNumberInput source="person.phone" formClassName={classes.inlineInfo} />
        <Separator />
        <TextInput source="person.addressLine1" formClassName={classes.inlineInfo} />
        <TextInput source="person.addressLine2" formClassName={classes.inlineInfo} />
        <TextInput source="person.city" formClassName={classes.inlineInfo} />
        <TextInput source="person.postcode" formClassName={classes.inlineInfo} />
        <TextInput source="person.country" formClassName={classes.inlineInfo} />
        <Separator />
        <BirthdateInput
          source="person.birthdate"
          label={translate('resources.User.fields.person.birthdate')}
        />
      </SimpleForm>
    </Create>
  );
};
