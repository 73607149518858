import { Button, InputAdornment, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { Styles } from '@material-ui/styles';
import { format } from 'date-fns';
import { CalendarPeriodFormProps } from 'types/calendar.types';

import PeriodSettingsModal from './rotationDaysModal/PeriodSettingsModal';
import { accommodationColors, fleetColors } from './utils/colors';

const styles: Styles<Theme, any> = () => ({
  periodInfos: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    margin: '5px 0',
  },
  dateTextField: {
    margin: '0 5px',

    '& .MuiInputBase-root': {
      height: '30px',

      '& .MuiInputBase-inputHiddenLabel': {
        textAlign: 'center',
        cursor: 'pointer',
        '&.Mui-disabled': {
          cursor: 'default',
        },
      },
    },
  },
  priceTextField: {
    margin: '0 5px',

    '& .MuiInputBase-root': {
      height: '30px',

      '& .MuiInputBase-inputHiddenLabel': {
        textAlign: 'center',
      },
    },
  },
  notchedOutline: {},
  cssFocused: {},
  ...fleetColors.reduce(
    (acc, color, index) => ({
      ...acc,
      [`color_${index}`]: {
        '&$cssFocused $notchedOutline': {
          borderColor: `${color} !important`,
        },
      },
    }),
    {}
  ),
  available: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${accommodationColors.available} !important`,
    },
  },
  unavailable: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${accommodationColors.unavailable} !important`,
    },
  },
  readOnly: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${accommodationColors.readOnly} !important`,
    },
  },
});

const useStyles = makeStyles(styles);

const CalendarPeriodForm = ({
  period,
  index,
  focusedRange,
  defaultArrivalDays,
  defaultDepartureDays,
  defaultMinimumDelay,
  defaultMinimumLength,
  setFocusedRange,
  deletePeriod,
  updatePeriods,
}: CalendarPeriodFormProps) => {
  const classes = useStyles(styles);
  const disabled = !!period.isReadOnly;
  return (
    <div
      className={classes.periodInfos}
      key={`${period.key}${period.isAvailable?.toString() || ''}`}
    >
      <TextField
        disabled={disabled}
        className={classes.dateTextField}
        variant="outlined"
        hiddenLabel={true}
        value={format(period.startDate, 'dd MMM yyyy')}
        onClick={!disabled ? () => setFocusedRange([index, 0]) : undefined}
        focused={!disabled && focusedRange && index === focusedRange[0] && focusedRange[1] === 0}
        InputProps={{
          readOnly: true,
          classes: {
            root: classes[period.colorClassName],
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
          },
        }}
      />
      <TextField
        disabled={disabled}
        className={classes.dateTextField}
        variant="outlined"
        hiddenLabel={true}
        value={format(period.endDate, 'dd MMM yyyy')}
        onClick={!disabled ? () => setFocusedRange([index, 1]) : undefined}
        focused={!disabled && focusedRange && index === focusedRange[0] && focusedRange[1] === 1}
        InputProps={{
          readOnly: true,
          classes: {
            root: classes[period.colorClassName],
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
          },
        }}
      />
      <TextField
        disabled={disabled || period.isAvailable === false}
        className={classes.priceTextField}
        variant="outlined"
        hiddenLabel={true}
        value={period.price}
        onClick={!disabled ? () => setFocusedRange([index, 0]) : undefined}
        focused={false}
        onChange={(e) => updatePeriods({ ...period, price: parseFloat(e.target.value) })}
        type="number"
        InputProps={{
          startAdornment: <InputAdornment position="start">€</InputAdornment>,
        }}
      />
      <PeriodSettingsModal
        disabled={disabled || period.isAvailable === false}
        updatePeriods={updatePeriods}
        defaultArrivalDays={defaultArrivalDays}
        defaultDepartureDays={defaultDepartureDays}
        defaultMinimumDelay={defaultMinimumDelay}
        defaultMinimumLength={defaultMinimumLength}
        period={period}
      />
      <Button
        disabled={disabled}
        color="primary"
        size="small"
        style={{ height: '30px' }}
        onClick={(e) => deletePeriod(e, period)}
      >
        <DeleteIcon />
      </Button>
    </div>
  );
};

export default CalendarPeriodForm;
