import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import EuroIcon from '@material-ui/icons/Euro';
import FavoriteIcon from '@material-ui/icons/Favorite';
import HotelIcon from '@material-ui/icons/Hotel';
import PeopleIcon from '@material-ui/icons/People';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import PinDropIcon from '@material-ui/icons/PinDrop';
import RateReviewIcon from '@material-ui/icons/RateReview';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import StyleIcon from '@material-ui/icons/Style';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import TvIcon from '@material-ui/icons/Tv';
import { useState } from 'react';
import { DashboardMenuItem, Menu, MenuItemLink, MenuProps, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';

import { AppState } from '../types/layout.types';
import SubMenu from './SubMenu';

type SubMenuName = 'reviews' | 'settings';

const CustomMenu = (props: MenuProps) => {
  const [state, setState] = useState({
    reviews: false,
    settings: false,
  });
  const translate = useTranslate();
  const open = useSelector((appState: AppState) => appState.admin.ui.sidebarOpen);
  useSelector((appState: AppState) => appState.theme); // force rerender on theme change

  const handleToggle = (menu: SubMenuName) => {
    setState((menuState) => ({ ...menuState, [menu]: !menuState[menu] }));
  };

  return (
    <Menu {...props}>
      <DashboardMenuItem />
      <MenuItemLink
        to={`/SearchOffer`}
        primaryText={translate('resources.SearchOffer.name')}
        leftIcon={<SearchIcon />}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to={`/Park`}
        primaryText={translate('resources.Park.name')}
        leftIcon={<PinDropIcon />}
      />
      <MenuItemLink
        to={`/Accommodation`}
        primaryText={translate('resources.Accommodation.name')}
        leftIcon={<HotelIcon />}
      />
      <MenuItemLink
        to={`/Stay`}
        primaryText={translate('resources.Stay.name')}
        leftIcon={<CardTravelIcon />}
      />
      <MenuItemLink
        to={`/User`}
        primaryText={translate('resources.User.name')}
        leftIcon={<PeopleIcon />}
      />
      <MenuItemLink
        to={`/Owner`}
        primaryText={translate('resources.Owner.name')}
        leftIcon={<PersonPinCircleIcon />}
      />
      <SubMenu
        handleToggle={() => handleToggle('reviews')}
        isOpen={state.reviews}
        sidebarIsOpen={open}
        name="pos.menu.reviews"
        icon={<RateReviewIcon />}
        dense={!!props.dense}
      >
        <MenuItemLink
          to={`/ParkApplication`}
          primaryText={translate('resources.ParkApplication.name')}
          leftIcon={<PinDropIcon />}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('settings')}
        isOpen={state.settings}
        sidebarIsOpen={open}
        name="pos.menu.settings"
        icon={<SettingsIcon />}
        dense={!!props.dense}
      >
        <MenuItemLink
          to={`/Admin`}
          primaryText={translate('resources.Admin.name')}
          leftIcon={<SupervisedUserCircleIcon />}
        />
        <MenuItemLink
          to={`/Theme`}
          primaryText={translate('resources.Theme.name')}
          leftIcon={<BeachAccessIcon />}
        />
        <MenuItemLink
          to={`/Location`}
          primaryText={translate('resources.Location.name')}
          leftIcon={<EditLocationIcon />}
        />
        <MenuItemLink
          to={`/AccommodationType`}
          primaryText={translate('resources.AccommodationType.name')}
          leftIcon={<StyleIcon />}
        />
        <MenuItemLink
          to={`/FavoriteAccommodation`}
          primaryText={translate('resources.FavoriteAccommodation.name')}
          leftIcon={<FavoriteIcon />}
        />
        <MenuItemLink
          to={`/ParkOffer`}
          primaryText={translate('resources.ParkOffer.name')}
          leftIcon={<EuroIcon />}
        />
        <MenuItemLink
          to={`/ParkFacility`}
          primaryText={translate('resources.ParkFacility.name')}
          leftIcon={<RoomServiceIcon />}
        />
        <MenuItemLink
          to={`/AccommodationEquipment`}
          primaryText={translate('resources.AccommodationEquipment.name')}
          leftIcon={<TvIcon />}
        />
      </SubMenu>
    </Menu>
  );
};

export default CustomMenu;
