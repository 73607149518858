import { translationLocales } from 'i18n';
import {
  BooleanInput,
  Create,
  CreateProps,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  TranslatableInputs,
  useTranslate,
} from 'react-admin';

import { getCategoryChoices } from './index';

export const AccommodationEquipmentCreate = (props: CreateProps) => {
  const translate = useTranslate();
  const categoryChoices = getCategoryChoices(translate);
  return (
    <Create {...props}>
      <SimpleForm redirect="list">
        <TranslatableInputs locales={translationLocales}>
          <TextInput source="localizedName" validate={[required()]} />
        </TranslatableInputs>
        <BooleanInput source="searchable" />
        <SelectInput source="category" choices={categoryChoices} validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};
