import TranslatableValueField from 'components/CustomFields/TranslatableValueField';
import SortableList from 'components/DragAndDrop/SortableList';
import { useState } from 'react';
import { Datagrid, List, ListProps, TextField } from 'react-admin';

import AccommodationEquipmentTabs from './AccommodationEquipmentTabs';

interface FilteredEquipmentListProps extends ListProps {
  category: string;
}

const AllEquipmentsList = (props: ListProps) => {
  return (
    <List {...props} sort={{ field: 'category', order: 'ASC' }} perPage={25}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TranslatableValueField translatePath="categories" source="category" />
      </Datagrid>
    </List>
  );
};

const FilteredEquipmentsList = ({ category, ...props }: FilteredEquipmentListProps) => {
  return (
    <SortableList
      orderedKey="priority"
      labelSource="name"
      filter={{ ...props.filter, category }}
      {...props}
    >
      <TextField source="name" />
      <TranslatableValueField translatePath="categories" source="category" />
    </SortableList>
  );
};

export const AccommodationEquipmentList = (props: ListProps) => {
  const [tab, setTab] = useState('ALL');
  return (
    <>
      <AccommodationEquipmentTabs tab={tab} setTab={setTab} />
      {tab === 'ALL' ? (
        <AllEquipmentsList {...props} />
      ) : (
        <FilteredEquipmentsList {...{ category: tab, ...props }} />
      )}
    </>
  );
};
