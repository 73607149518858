import { Alert, AlertTitle } from '@material-ui/lab';
import { get } from 'lodash';
import { FieldProps, useTranslate } from 'react-admin';
import { ModalSeverity } from 'types/materialui.types';

import { StayStatus } from '..';

export const PublishedAtStatusField = ({ record, source }: FieldProps) => {
  const translate = useTranslate();
  const status = source ? get(record, source) : null;
  let severity = ModalSeverity.Warning;

  if (status === StayStatus.Payed) {
    severity = ModalSeverity.Success;
  } else if (status === StayStatus.Cancelled) {
    severity = ModalSeverity.Error;
  } else if (status === StayStatus.Option) {
    severity = ModalSeverity.Info;
  } else {
    severity = ModalSeverity.Warning;
  }

  return (
    <Alert severity={severity}>
      <AlertTitle>{translate(`resources.Stay.statuses.${status}.title`)}</AlertTitle>
      {translate(`resources.Stay.statuses.${status}.description`)}
    </Alert>
  );
};
