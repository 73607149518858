import { Card as MuiCard, CardContent, InputAdornment } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ArchiveIcon from '@material-ui/icons/Archive';
import Done from '@material-ui/icons/DoneOutline';
import HotelIcon from '@material-ui/icons/Hotel';
import PinDropIcon from '@material-ui/icons/PinDrop';
import DateRangeInput from 'components/CustomInput/DateRangeInput';
import { SectionTitle } from 'components/formUtils';
import {
  AutocompleteInput,
  Filter,
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  Record,
  RecordMap,
  ReferenceInput,
  useTranslate,
} from 'react-admin';

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const useStyles = makeStyles({
  filterTitle: {
    textAlign: 'start',
    fontSize: '0.75rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: 2.66,
    letterSpacing: '0.08333em',
    textTransform: 'uppercase',
  },
  filter: {
    marginTop: '0.4px',
  },
  dateRangefilter: {
    minHeight: '3.2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const StayAsideFilter = ({ filterValues }: any) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        <FilterLiveSearch source="q" />
        <FilterList label="resources.Stay.filters.archivedAt" icon={<ArchiveIcon />}>
          <FilterListItem label="ra.boolean.true" value={{ archivedAt: true }} />
          <FilterListItem label="ra.boolean.false" value={{ archivedAt: false }} />
        </FilterList>
        <FilterList label="resources.Stay.filters.staysSelect.title" icon={<Done />}>
          <FilterListItem
            label="resources.Stay.filters.staysSelect.futurAndCurrent"
            value={{ filterDate: 'futurAndCurrent' }}
          />
          <FilterListItem
            label="resources.Stay.filters.staysSelect.futur"
            value={{ filterDate: 'futur' }}
          />
          <FilterListItem
            label="resources.Stay.filters.staysSelect.current"
            value={{ filterDate: 'current' }}
          />
          <FilterListItem
            label="resources.Stay.filters.staysSelect.past"
            value={{ filterDate: 'past' }}
          />
        </FilterList>
        <Filter
          classes={{
            form: classes.filter,
          }}
        >
          <ReferenceInput
            label={translate('resources.Stay.filters.park')}
            source="parkId"
            reference="Park"
            alwaysOn
          >
            <AutocompleteInput
              resettable={true}
              optionText={(record: RecordMap<Record>) =>
                record.id ? `${record.name} (${record.postcode})` : ''
              }
              options={{
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <PinDropIcon color="disabled" />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </ReferenceInput>
          <ReferenceInput
            label={translate('resources.Stay.filters.accommodation')}
            source="accommodationId"
            reference="Accommodation"
            disabled={!filterValues.parkId}
            filter={{ parkId: filterValues.parkId }}
            alwaysOn
          >
            <AutocompleteInput
              resettable={true}
              optionText={(record: RecordMap<Record>) => {
                return record.id ? `${record.name}` : '';
              }}
              options={{
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <HotelIcon color="disabled" />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </ReferenceInput>
        </Filter>
        <SectionTitle
          label={translate('resources.Stay.filters.startAt')}
          className={classes.filterTitle}
        />
        <Filter
          classes={{
            form: classes.dateRangefilter,
          }}
        >
          <DateRangeInput source="startDate" alwaysOn />
        </Filter>
        <SectionTitle
          label={translate('resources.Stay.filters.createdAt')}
          className={classes.filterTitle}
        />
        <Filter
          classes={{
            form: classes.dateRangefilter,
          }}
        >
          <DateRangeInput source="createdAt" toPast alwaysOn />
        </Filter>
      </CardContent>
    </Card>
  );
};

export default StayAsideFilter;
