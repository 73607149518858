import AddIcon from '@material-ui/icons/Add';
import { useMemo } from 'react';
import { Button, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';

interface CreateButtonProps {
  basePath: string;
}

const CreateButton = ({ basePath }: CreateButtonProps) => {
  const translate = useTranslate();
  const location = useMemo(
    () => ({
      pathname: `${basePath}/create`,
      state: { _scrollToTop: false },
    }),
    [basePath]
  );
  return (
    <Button
      component={Link}
      basePath={basePath}
      variant="text"
      label={translate('ra.action.create')}
      to={location}
    >
      <AddIcon />
    </Button>
  );
};

export default CreateButton;
