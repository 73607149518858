import { Dialog } from '@material-ui/core';
import { ReactElement } from 'react';
import { Edit, SimpleForm, useRedirect } from 'react-admin';

import { useDialogStyles } from '.';

interface EditFormDialogProps {
  id: string | undefined;
  redirectPath: string;
  renderReferenceForm: () => ReactElement;
  resource: string;
}

const EditFormDialog = ({
  id,
  resource,
  redirectPath,
  renderReferenceForm,
}: EditFormDialogProps) => {
  const classes = useDialogStyles();
  const redirect = useRedirect();
  const handleClose = () => redirect(redirectPath);

  return (
    <Dialog open onClose={handleClose}>
      <Edit
        id={id}
        mutationMode="optimistic"
        resource={resource}
        basePath={`/${resource}`}
        onSuccess={handleClose}
        className={classes.root}
      >
        <SimpleForm>{renderReferenceForm()}</SimpleForm>
      </Edit>
    </Dialog>
  );
};

export default EditFormDialog;
