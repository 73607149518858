import { AmplifyImageInput } from 'components/CustomInput/AmplifyImageInput';
import { translationLocales } from 'i18n';
import {
  Create,
  CreateProps,
  required,
  SimpleForm,
  TextInput,
  TranslatableInputs,
} from 'react-admin';

export const AccommodationTypeCreate = (props: CreateProps) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TranslatableInputs locales={translationLocales}>
          <TextInput source="localizedName" validate={[required()]} />
          <TextInput source="description" validate={[required()]} multiline />
        </TranslatableInputs>
        <AmplifyImageInput source="picture" accept="image/*" />
      </SimpleForm>
    </Create>
  );
};
