import { makeStyles } from '@material-ui/core/styles';
import MuiToolbar from '@material-ui/core/Toolbar';
import * as React from 'react';
import { FC, Fragment } from 'react';
import { ToolbarProps } from 'react-admin';

import { ParkApplication } from '../../../types/parkApplication.types';
import AcceptButton from '../../Buttons/AcceptButton';
import RejectButton from '../../Buttons/RejectButton';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'space-around',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
}));

const ParkApplicationEditToolbar: FC<ToolbarProps<ParkApplication>> = ({ record }) => {
  const classes = useStyles();
  if (!record) return null;
  return (
    <MuiToolbar className={classes.root}>
      <Fragment>
        <AcceptButton record={record} />
        <RejectButton record={record} />
      </Fragment>
    </MuiToolbar>
  );
};

export default ParkApplicationEditToolbar;
