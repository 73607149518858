import AddIcon from '@material-ui/icons/Add';
import { useMemo } from 'react';
import { Button, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';

interface ConnectButtonProps {
  basePath: string;
}

const ConnectButton = ({ basePath }: ConnectButtonProps) => {
  const translate = useTranslate();
  const location = useMemo(
    () => ({
      pathname: `${basePath}/connect`,
      state: { _scrollToTop: false },
    }),
    [basePath]
  );
  return (
    <Button
      component={Link}
      basePath={basePath}
      variant="text"
      label={translate('ra.action.add')}
      to={location}
    >
      <AddIcon />
    </Button>
  );
};

export default ConnectButton;
