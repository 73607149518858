import { Button } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { get } from 'lodash';
import { FieldProps, linkToRecord, useTranslate } from 'react-admin';
import { useHistory } from 'react-router-dom';

const StayPeriodStatusField = ({ record, source }: FieldProps) => {
  const translate = useTranslate();
  const history = useHistory();
  const currentStay = source ? get(record, source) : null;

  if (!currentStay) {
    return (
      <div style={{ margin: '10px 0' }}>
        <Alert severity="error">
          <AlertTitle>
            {translate(`resources.Accommodation.fields.statuses.stayPeriod.busy.title`)}
          </AlertTitle>
          {translate(`resources.Accommodation.fields.statuses.stayPeriod.busy.description`)}
        </Alert>
      </div>
    );
  }

  const stayShowPage = linkToRecord('/Stay', currentStay.id, 'show');
  return (
    <div style={{ margin: '10px 0' }}>
      <Alert
        severity="success"
        action={
          <Button color="inherit" size="small" onClick={() => history.push(stayShowPage)}>
            {translate(`resources.Accommodation.fields.statuses.stayPeriod.free.link`)}
          </Button>
        }
      >
        <AlertTitle>
          {translate(`resources.Accommodation.fields.statuses.stayPeriod.free.title`)}
        </AlertTitle>
        {translate(`resources.Accommodation.fields.statuses.stayPeriod.free.description`)}
      </Alert>
    </div>
  );
};
export default StayPeriodStatusField;
