import { Amplify } from '@aws-amplify/core';
import { AuthProvider, AuthProviderOptions } from 'authProvider';
// AWS Exports
import {
  AccommodationEquipmentCreate,
  AccommodationEquipmentEdit,
  AccommodationEquipmentList,
} from 'components/pages/accommodationEquipment';
import {
  FavoriteAccommodationCreate,
  FavoriteAccommodationList,
} from 'components/pages/favoriteAccommodation';
import { OwnerCreate, OwnerEdit, OwnerList } from 'components/pages/owners';
import { PropertyManagerCreate, PropertyManagerEdit } from 'components/pages/propertyManagers';
import { SearchOfferList } from 'components/pages/searchOffer';
import * as React from 'react';
import { Admin, DataProvider, LegacyDataProvider, Resource } from 'react-admin';

import Login from './auth/Login';
import {
  AccommodationCreate,
  AccommodationEdit,
  AccommodationList,
  AccommodationShow,
} from './components/pages/accommodation';
import {
  AccommodationTypeCreate,
  AccommodationTypeEdit,
  AccommodationTypeList,
} from './components/pages/accommodationType';
import { AdminCreate, AdminEdit, AdminList } from './components/pages/admins';
import Dashboard from './components/pages/dashboard';
import { FleetCreate, FleetEdit } from './components/pages/fleet';
import { LocationCreate, LocationEdit, LocationList } from './components/pages/location';
import { ParkApplicationTableList } from './components/pages/parkApplication';
import {
  ParkFacilityCreate,
  ParkFacilityEdit,
  ParkFacilityList,
} from './components/pages/parkFacility';
import { ParkOffersCreate, ParkOffersEdit, ParkOffersList } from './components/pages/parkOffers';
import { ParkCreate, ParkEdit, ParkList, ParkShow } from './components/pages/parks';
import { StayCreate, StayEdit, StayList, StaysShow } from './components/pages/stays';
import { ThemeCreate, ThemeEdit, ThemeList } from './components/pages/themes';
import { UserCreate, UserEdit, UserList, UserShow } from './components/pages/users';
// Core
import i18nProvider from './i18n';
import { Layout } from './layout';
import fleetScopeReducer from './reducers/fleetScopeReducer';
import propertyManagerScopeReducer from './reducers/propertyManagerReducer';
import themeReducer from './reducers/themeReducer';
import customRoutes from './routes';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  },
  Storage: {
    region: process.env.REACT_APP_REGION,
    bucket: process.env.REACT_APP_PICTURE_STORAGE_BUCKET,
  },
});

function buildAuthProvider(options?: AuthProviderOptions) {
  return new AuthProvider(options);
}

interface AppProps {
  dataProvider: DataProvider | LegacyDataProvider;
}

const App = ({ dataProvider }: AppProps): React.ReactElement => {
  return (
    <Admin
      loginPage={Login}
      dataProvider={dataProvider}
      authProvider={buildAuthProvider({ authGroups: ['SuperAdmin'] })}
      customReducers={{
        theme: themeReducer,
        fleetScope: fleetScopeReducer,
        propertyManagerScope: propertyManagerScopeReducer,
      }}
      customRoutes={customRoutes}
      dashboard={Dashboard}
      i18nProvider={i18nProvider}
      layout={Layout}
    >
      <Resource name="SearchOffer" list={SearchOfferList} />
      <Resource name="Park" list={ParkList} create={ParkCreate} edit={ParkEdit} show={ParkShow} />
      <Resource name="ParkApplication" list={ParkApplicationTableList} />
      <Resource name="Stay" list={StayList} create={StayCreate} edit={StayEdit} show={StaysShow} />
      <Resource
        name="Accommodation"
        list={AccommodationList}
        create={AccommodationCreate}
        edit={AccommodationEdit}
        show={AccommodationShow}
      />
      <Resource name="User" list={UserList} edit={UserEdit} create={UserCreate} show={UserShow} />
      <Resource name="Admin" list={AdminList} edit={AdminEdit} create={AdminCreate} />
      <Resource name="Owner" list={OwnerList} edit={OwnerEdit} create={OwnerCreate} />
      <Resource name="PropertyManager" create={PropertyManagerCreate} edit={PropertyManagerEdit} />
      <Resource name="Theme" list={ThemeList} create={ThemeCreate} edit={ThemeEdit} />
      <Resource
        name="ParkFacility"
        list={ParkFacilityList}
        create={ParkFacilityCreate}
        edit={ParkFacilityEdit}
      />
      <Resource
        name="AccommodationEquipment"
        list={AccommodationEquipmentList}
        create={AccommodationEquipmentCreate}
        edit={AccommodationEquipmentEdit}
      />
      <Resource name="Location" list={LocationList} edit={LocationEdit} create={LocationCreate} />
      <Resource
        name="AccommodationType"
        list={AccommodationTypeList}
        edit={AccommodationTypeEdit}
        create={AccommodationTypeCreate}
      />
      <Resource
        name="FavoriteAccommodation"
        list={FavoriteAccommodationList}
        create={FavoriteAccommodationCreate}
      />
      <Resource
        name="ParkOffer"
        list={ParkOffersList}
        edit={ParkOffersEdit}
        create={ParkOffersCreate}
      />
      <Resource name="Fleet" edit={FleetEdit} create={FleetCreate} />
      <Resource name="ParkPicture" />
      <Resource name="AccommodationPicture" />
      <Resource name="Person" />
      <Resource name="InvoiceLine" />
      <Resource name="Company" />
    </Admin>
  );
};

export default App;
