import { AmplifyImageField } from 'components/CustomFields/AmplifyImageField';
import { FileInputOptions, FileInputProps } from 'ra-ui-materialui/lib/input/FileInput';
import { ImageInput, InputProps } from 'react-admin';

import { useStorageInput } from '../../hooks/useStorageInput';

type Props = {
  source: string;
  multiple?: boolean;
  options?: any;
  storageOptions?: any;
  target?: string;
} & FileInputProps &
  InputProps<FileInputOptions>;

const MultiImageInput = ({ options = {}, storageOptions = {}, target, ...props }: Props) => {
  // Send picture tags to attach pictures to database records
  const pictureTags = new URLSearchParams({
    resource: String(props.resource),
    target: String(target),
    recordId: String(props.record.id),
  });
  storageOptions.tagging = pictureTags.toString();

  const { onDropAccepted, onRemove } = useStorageInput({
    source: props.source,
    resource: props.resource,
    multiple: props.multiple,
    onDropAcceptedCallback: options.onDropAccepted,
    path: 'pictures',
    storageOptions,
  });

  return (
    <ImageInput {...props} options={{ ...options, onDropAccepted, onRemove }}>
      <AmplifyImageField source="picture" storageOptions={storageOptions} />
    </ImageInput>
  );
};

export default MultiImageInput;
