import Rating from '@material-ui/lab/Rating';
import { InputProps, Labeled, useInput } from 'react-admin';

const StarRatingInput = (props: InputProps) => {
  const {
    input: { name, onChange, ...rest },
  } = useInput(props);

  return (
    <Labeled label={props.label}>
      <Rating
        name={name}
        size="large"
        onChange={(_e, star): void => onChange(star || 0)}
        {...rest}
      />
    </Labeled>
  );
};

export default StarRatingInput;
