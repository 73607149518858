import { Card as MuiCard, CardContent } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PublicIcon from '@material-ui/icons/Public';
import {
  AutocompleteInput,
  Filter,
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  ReferenceInput,
  useTranslate,
} from 'react-admin';

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const styles = () => ({
  locationRefInput: {
    marginTop: '24px',
  },
});

const useStyles = makeStyles(styles);

const ParkAsideFilter = () => {
  const translate = useTranslate();
  const classes = useStyles(styles);
  return (
    <Card>
      <CardContent>
        <FilterLiveSearch source="q" />
        <Filter>
          <ReferenceInput
            label={translate('resources.Park.filters.location')}
            source="locationId"
            reference="Location"
            alwaysOn
            className={classes.locationRefInput}
          >
            <AutocompleteInput
              resettable={true}
              optionText={(record: any) => (record.id ? `${record.name}` : '')}
            />
          </ReferenceInput>
          <ReferenceInput
            label={translate('resources.Park.filters.theme')}
            source="themeId"
            reference="Theme"
            alwaysOn
          >
            <AutocompleteInput
              resettable={true}
              optionText={(record: any) => (record.id ? `${record.name}` : '')}
            />
          </ReferenceInput>
        </Filter>
        <FilterList label="resources.Park.fields.publishedAt" icon={<PublicIcon />}>
          <FilterListItem label="ra.boolean.true" value={{ publishedAt: true }} />
          <FilterListItem label="ra.boolean.false" value={{ publishedAt: false }} />
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default ParkAsideFilter;
