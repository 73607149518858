import { Record, Translate } from 'react-admin';

export * from './LocationList';
export * from './LocationEdit';
export * from './LocationCreate';

export const getCategoryChoices = (translate: Translate): Record[] => [
  { id: 'CITY', name: translate('resources.Location.categories.city') },
  { id: 'COUNTRY', name: translate('resources.Location.categories.country') },
  { id: 'REGION', name: translate('resources.Location.categories.region') },
  { id: 'DEPARTEMENT', name: translate('resources.Location.categories.departement') },
  { id: 'CUSTOM', name: translate('resources.Location.categories.custom') },
];
