import { FileInputOptions, FileInputProps } from 'ra-ui-materialui/lib/input/FileInput';
import React from 'react';
import { ImageInput, InputProps } from 'react-admin';

import { useStorageInput } from '../../hooks/useStorageInput';
import { AmplifyImageField } from '../CustomFields/AmplifyImageField';

type Props = {
  source: string;
  multiple?: boolean;
  options?: any;
  storageOptions?: any;
} & FileInputProps &
  InputProps<FileInputOptions>;

export const AmplifyImageInput: React.FC<Props> = ({
  options = {},
  storageOptions = {},
  target,
  ...props
}) => {
  const pictureTags = new URLSearchParams({
    resource: String(props.resource),
    ...(target ? { target: String(target) } : {}),
    recordId: String(props.record.id),
  });
  storageOptions.tagging = pictureTags.toString();

  const { onDropAccepted, onRemove } = useStorageInput({
    onDropAcceptedCallback: options.onDropAccepted,
    storageOptions,
    path: 'pictures',
    ...props,
  });

  return (
    <ImageInput {...props} options={{ ...options, onDropAccepted, onRemove }}>
      <AmplifyImageField storageOptions={storageOptions} />
    </ImageInput>
  );
};
