import { makeStyles, Theme } from '@material-ui/core';
import { Styles } from '@material-ui/styles';
import MuiPhoneNumber from 'material-ui-phone-number';
import { InputProps, useInput, useTranslate } from 'react-admin';

export const styles: Styles<Theme, any> = () => ({
  phoneNumberInput: {
    width: '256px',
    marginTop: '8px',
    marginBottom: '4px',
    '& .MuiInputBase-root': {
      height: '3rem',
    },
  },
});

const useStyles = makeStyles(styles);

const PhoneNumberInput = (props: InputProps) => {
  const classes = useStyles(styles);
  const translate = useTranslate();
  const {
    input: { value, onChange },
  } = useInput(props);

  return (
    <MuiPhoneNumber
      className={classes.phoneNumberInput}
      variant="filled"
      label={translate('pos.phone')}
      data-cy="user-phone"
      defaultCountry="fr"
      value={value}
      onChange={onChange}
      autoFormat={false}
    />
  );
};

export default PhoneNumberInput;
