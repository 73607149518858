import { Auth } from '@aws-amplify/auth';

export interface AuthProviderOptions {
  authGroups?: string[];
}

const defaultOptions = {
  authGroups: [],
};

export class AuthProvider {
  public authGroups: string[];

  public constructor(options?: AuthProviderOptions) {
    this.authGroups = options?.authGroups || defaultOptions.authGroups;
  }

  public login = async (params: any) => {
    if (params.error) throw params.error;
    return Promise.resolve();
  };

  public logout = (): Promise<any> => {
    return Auth.signOut();
  };

  public checkAuth = async (): Promise<void> => {
    const session = await Auth.currentSession();

    if (this.authGroups.length === 0) {
      return;
    }

    const userGroups = session.getAccessToken().decodePayload()['cognito:groups'];

    if (!userGroups) {
      throw new Error('Unauthorized');
    }

    if (userGroups.some((group: any) => this.authGroups.includes(group))) return;

    throw new Error('Unauthorized');
  };

  public checkError = (): Promise<void> => {
    return Promise.resolve();
  };

  public getPermissions = async (): Promise<string[]> => {
    try {
      const session = await Auth.currentSession();
      const groups = await session.getAccessToken().decodePayload()['cognito:groups'];
      return groups ? Promise.resolve(groups) : Promise.reject();
    } catch (e) {
      throw new Error('Unauthorized');
    }
  };

  public getIdentity = async (): Promise<any> => {
    try {
      const { attributes } = await Auth.currentUserInfo();
      return Promise.resolve({ id: 1, fullName: attributes.name, avatar: attributes.picture });
    } catch (error) {
      return Promise.reject(error);
    }
  };
}
