import PublishedStatusField from 'components/CustomFields/PublishedStatusField';
import { Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin';

import StarRatingField from '../../CustomFields/StarRatingField';
import { SectionTitle, Separator } from '../../formUtils/index';
import AccommodationsStatusField from './fields/AccommodationsStatusField';
import { OpeningDaysStatusField } from './fields/OpeningDaysStatusField';

export const ParkShow = (props: ShowProps) => (
  <Show {...props}>
    <SimpleShowLayout>
      <SectionTitle label="Informations pratiques" />
      <PublishedStatusField source="publishedAt" />
      <OpeningDaysStatusField source="openingDays" />
      <AccommodationsStatusField source="count.accommodations" />
      <TextField source="name" />
      <TextField source="description.fr" />
      <StarRatingField source="ranking" />
      <TextField source="phone" />
      <TextField source="chain" />
      <Separator />
      <SectionTitle label="Localisation" />
      <TextField source="addressLine1" />
      <TextField source="city" />
      <TextField source="postcode" />
    </SimpleShowLayout>
  </Show>
);
