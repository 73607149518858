import DenominationField from 'components/CustomFields/DenominationField';
import CustomizableDatagrid from 'components/CustomizableDatagrid/CustomizableDatagrid';
import {
  DateField,
  EditButton,
  List,
  ListProps,
  Pagination,
  PaginationProps,
  ReferenceField,
  TextField,
  useTranslate,
} from 'react-admin';

import AccommodationAsideFilter from './AccommodationAsideFilter';

const AccommodationPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

export const AccommodationList = (props: ListProps) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      aside={<AccommodationAsideFilter />}
      bulkActionButtons={false}
      pagination={<AccommodationPagination />}
      perPage={25}
    >
      <CustomizableDatagrid rowClick="show">
        <TextField source="name" />
        <ReferenceField reference="Park" source="park.id" link="show">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="bedroom" />
        <ReferenceField
          label={translate('resources.AccommodationType.name')}
          source="accommodationType.id"
          reference="AccommodationType"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField reference="Fleet" source="fleet.id">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField reference="Owner" source="ownerId">
          <DenominationField />
        </ReferenceField>
        <DateField source="createdAt" />
        <EditButton />
      </CustomizableDatagrid>
    </List>
  );
};
