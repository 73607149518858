import { LegacyDataProvider } from 'react-admin';
import ReactDOM from 'react-dom';

import App from './App';
import prepareDataProvider from './dataProvider';
import reportWebVitals from './reportWebVitals';

prepareDataProvider().then((dataProvider: LegacyDataProvider) => {
  ReactDOM.render(<App dataProvider={dataProvider} />, document.getElementById('root'));
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
