export * from './StayList';
export * from './StayEdit';
export * from './StaysShow';
export * from './StayCreate';

export enum StayStatus {
  Option = 'OPTION',
  Waiting = 'WAITING',
  PartialPayment = 'PARTIAL_PAYMENT',
  PlannedPayment = 'PLANNED_PAYMENT',
  Payed = 'PAYED',
  Cancelled = 'CANCELLED',
}
