import { Reducer } from 'redux';

import { FLEET_SCOPE_BY_PARK } from '../components/pages/parks/actions';

const initialState = '';

const fleetScopeReducer: Reducer = (state = initialState, { type, payload }) => {
  if (type === FLEET_SCOPE_BY_PARK) {
    return payload;
  }
  return state;
};
export default fleetScopeReducer;
