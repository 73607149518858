import { Record, Translate } from 'react-admin';

export * from './AccommodationEquipmentList';
export * from './AccommodationEquipmentEdit';
export * from './AccommodationEquipmentCreate';

export const getCategoryChoices = (translate: Translate): Record[] => [
  { id: 'ALL', name: translate('pos.tabs.accommodationEquipments.all') },
  { id: 'STANDARD', name: translate('pos.tabs.accommodationEquipments.standard') },
  { id: 'KITCHEN', name: translate('pos.tabs.accommodationEquipments.kitchen') },
  { id: 'ROOM', name: translate('pos.tabs.accommodationEquipments.room') },
  { id: 'OUTDOOR', name: translate('pos.tabs.accommodationEquipments.outdoor') },
];
