import get from 'lodash/get';
import { FieldProps } from 'react-admin';

import { User } from '../../types/user.types';

const FullNameField = ({ record, source = '' }: FieldProps<User>) => {
  const person = record?.firstName && record?.lastName ? record : get(record, source);
  if (!person?.firstName && !person?.lastName) return null;
  return (
    <span>
      {person?.firstName && person.firstName} {person?.lastName && person.lastName}
    </span>
  );
};

FullNameField.defaultProps = {
  label: 'resources.User.fields.fullname',
  addLabel: true,
};

export default FullNameField;
