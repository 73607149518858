import { Theme, useMediaQuery } from '@material-ui/core';
import DenominationField from 'components/CustomFields/DenominationField';
import StarRatingField from 'components/CustomFields/StarRatingField';
import CustomizableDatagrid from 'components/CustomizableDatagrid/CustomizableDatagrid';
import {
  BooleanInput,
  DateField,
  EditButton,
  Filter,
  FilterProps,
  List,
  ListProps,
  ReferenceField,
  SearchInput,
  TextField,
} from 'react-admin';

import PercentNumberField from '../../CustomFields/PercentNumberField';
import ParkAsideFilter from './ParkAsideFilter';

const ParkFilter = (props: Omit<FilterProps, 'children'>) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <BooleanInput source="publishedAt" format={(v: any) => Boolean(v)} defaultValue={true} />
  </Filter>
);

export const ParkList = (props: ListProps) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  return (
    <List
      {...props}
      filters={isSmall ? <ParkFilter /> : undefined}
      aside={<ParkAsideFilter />}
      bulkActionButtons={false}
    >
      <CustomizableDatagrid
        defaultColumns={['name', 'ranking', 'postcode', 'city']}
        rowClick="show"
      >
        <TextField source="name" />
        <StarRatingField size="small" source="ranking" />
        <TextField source="postcode" />
        <TextField source="city" />
        <ReferenceField reference="Owner" source="ownerId">
          <DenominationField />
        </ReferenceField>
        <DateField source="updatedAt" />
        <PercentNumberField source="commissionPercent" />
        <EditButton />
      </CustomizableDatagrid>
    </List>
  );
};
