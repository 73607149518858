import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import React, { ChangeEvent } from 'react';
import { FieldTitle, useTranslate } from 'react-admin';
import { SelectionDialogProps } from 'types/customizableDatagrid.types';

const SelectionDialog = ({
  columns,
  selection,
  resource,
  onClose,
  onColumnClicked,
}: SelectionDialogProps) => {
  const translate = useTranslate();
  const handleColumnClicked = ({
    target: { value: columnName },
  }: ChangeEvent<HTMLInputElement>) => {
    onColumnClicked(columnName);
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      aria-labelledby="ra-columns-dialog-title"
      onClose={onClose}
      open
    >
      <DialogTitle style={{ textAlign: 'center' }} id="ra-columns-dialog-title">
        {translate('pos.listConfiguration')}
      </DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map(({ source, label }) => (
            <FormControlLabel
              key={source}
              control={
                <Checkbox
                  checked={!!selection[source]}
                  onChange={handleColumnClicked}
                  value={source}
                />
              }
              label={<FieldTitle label={label} source={source} resource={resource} />}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {translate('pos.validate')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectionDialog;
