import { Dialog } from '@material-ui/core';
import { ReactElement } from 'react';
import {
  Edit,
  Record,
  SaveButton,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  useRedirect,
  useRefresh,
  useResourceContext,
} from 'react-admin';

interface ConnectFormDialogProps {
  redirectPath: string;
  renderReferenceForm: (props: any) => ReactElement;
  record: Record;
}

const CustomToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

const ConnectFormDialog = ({
  redirectPath,
  renderReferenceForm,
  record,
}: ConnectFormDialogProps) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const handleClose = () => {
    redirect(redirectPath);
    refresh();
  };
  const resource = useResourceContext();
  return (
    <Dialog open onClose={handleClose}>
      <Edit
        id={record.id as string}
        mutationMode="optimistic"
        resource={resource}
        basePath={`/${resource}`}
        onSuccess={handleClose}
      >
        <SimpleForm toolbar={<CustomToolbar />}>{renderReferenceForm({ record })}</SimpleForm>
      </Edit>
    </Dialog>
  );
};

export default ConnectFormDialog;
