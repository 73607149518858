import { Divider, Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import CheckIconField from 'components/CustomFields/CheckIconField';
import ReviewTabs from 'components/Tabs/ReviewTabs';
import { FC, Fragment, ReactElement, useCallback } from 'react';
import {
  Datagrid,
  DatagridProps,
  EmailField,
  Filter,
  FilterProps,
  List,
  ListProps,
  SearchInput,
  TextField,
  useListContext,
} from 'react-admin';
import { Route, RouteChildrenProps, useHistory } from 'react-router-dom';

import ParkApplicationEdit from './ParkApplicationEdit';

const ParkApplicationFilter: FC<Omit<FilterProps, 'children'>> = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 250,
  },
  drawerPaper: {
    zIndex: 100,
  },
  total: { fontWeight: 'bold' },
}));

type TabbedDatagridProps = DatagridProps;

const ParkApplicationList = (props: DatagridProps): ReactElement => (
  <Datagrid {...props}>
    <TextField source="name" />
    <TextField source="postcode" />
    <EmailField source="email" />
    <TextField source="phone" />
    <CheckIconField source="interestTourOperator" />
    <CheckIconField source="interestAccommodationManagement" />
    <CheckIconField source="interestSeller" />
  </Datagrid>
);

const TabbedDatagrid: FC<TabbedDatagridProps> = (props): ReactElement => {
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;

  return (
    <Fragment>
      <ReviewTabs
        filterValues={filterValues}
        setFilters={setFilters}
        displayedFilters={displayedFilters}
      />
      <Divider />
      <ParkApplicationList {...props} rowClick="edit" />
    </Fragment>
  );
};

export const ParkApplicationTableList: FC<ListProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push('/ParkApplication');
  }, [history]);

  return (
    <div className={classes.root}>
      <Route path="/ParkApplication/:id">
        {({ match }: RouteChildrenProps<{ id: string }>) => {
          const isMatch = !!(match && match.params && match.params.id !== 'create');

          return (
            <Fragment>
              <List
                {...props}
                bulkActionButtons={false}
                sort={{ field: 'name', order: 'DESC' }}
                perPage={10}
                filterDefaultValues={{ status: 'pending' }}
                filters={<ParkApplicationFilter />}
                className={classnames(classes.list, { [classes.listWithDrawer]: isMatch })}
              >
                <TabbedDatagrid />
              </List>
              <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
                onClose={handleClose}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {isMatch && (
                  <ParkApplicationEdit
                    id={(match as any).params.id}
                    onCancel={handleClose}
                    {...props}
                  />
                )}
              </Drawer>
            </Fragment>
          );
        }}
      </Route>
    </div>
  );
};
