import {
  Card as MuiCard,
  CardContent,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  withStyles,
} from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import getPoolGroups from 'components/Utils/getPoolGroups';
import { ChangeEvent, useState } from 'react';
import {
  Datagrid,
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  List,
  ListProps,
  TextField,
  useListContext,
  useTranslate,
} from 'react-admin';

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const useStyles = makeStyles({
  selectInputTypeForm: {
    width: '100%',
    '& label': {
      marginLeft: '2px',
    },
  },
});

const AdminAsideFilter = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const [inputType, setInputType] = useState<string | unknown>('');
  const { setFilters } = useListContext();

  const handleChangeFilter = (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    setInputType(e.target.value);
    setFilters({}, {});
  };
  const getInputToDisplay = () => {
    const groups = getPoolGroups();
    const getFilterListItems = () => {
      return groups.map((group) => {
        return <FilterListItem label={group.name} value={{ group: group.id }} />;
      });
    };
    switch (inputType) {
      case 'name':
        return <FilterLiveSearch source="name" />;
      case 'email':
        return <FilterLiveSearch source="email" />;
      case 'group':
        return (
          <FilterList label={translate('resources.Admin.groups')} icon={<GroupIcon />}>
            {getFilterListItems()}
          </FilterList>
        );
      default:
        return null;
    }
  };

  return (
    <Card>
      <FormControl className={classes.selectInputTypeForm}>
        <InputLabel>{translate('pos.searchBy')}</InputLabel>
        <Select value={inputType} onChange={handleChangeFilter} variant="filled">
          <MenuItem value={'name'}>{translate('resources.Admin.fields.name')}</MenuItem>
          <MenuItem value={'email'}> {translate('resources.Admin.fields.email')}</MenuItem>
          <MenuItem value={'group'}> {translate('resources.Admin.fields.group')}</MenuItem>
        </Select>
      </FormControl>
      <CardContent>{getInputToDisplay()}</CardContent>
    </Card>
  );
};

export const AdminList = (props: ListProps) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      pagination={false}
      aside={<AdminAsideFilter />}
      filterDefaultValues={{ group: 'SuperAdmin' }}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" sortable={false} />
        <TextField source="email" sortable={false} />
      </Datagrid>
    </List>
  );
};
