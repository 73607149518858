import { Button, Dialog } from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useState } from 'react';
import { useTranslate } from 'react-admin';
import { PeriodSettingsModalProps } from 'types/calendar.types';

import PeriodSettingsModalHeader from './PeriodSettingsModalHeader';
import { SettingsSelection } from './SettingsSelection';

const PeriodSettingsModal = ({
  disabled = false,
  period,
  defaultArrivalDays,
  defaultDepartureDays,
  defaultMinimumDelay,
  defaultMinimumLength,
  updatePeriods,
}: PeriodSettingsModalProps) => {
  const startAt = format(period.startDate, 'dd MMM yyyy', { locale: fr });
  const endAt = format(period.endDate, 'dd MMM yyyy', { locale: fr });
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const handleCloseDialog = () => {
    setOpen(false);
    setPeriodSettings({
      minimumDelay: period.minimumDelay,
      minimumLength: period.minimumLength,
      arrivalDays: period.arrivalDays,
      departureDays: period.departureDays,
    });
  };
  const handleOpenDialog = () => setOpen(true);
  const [periodSettings, setPeriodSettings] = useState({
    minimumDelay: period.minimumDelay,
    minimumLength: period.minimumLength,
    arrivalDays: period.arrivalDays,
    departureDays: period.departureDays,
  });

  const handleValidateForm = () => {
    updatePeriods({ ...period, ...periodSettings });
    setOpen(false);
  };

  return (
    <>
      <Button
        disabled={disabled}
        color="primary"
        size="small"
        style={{ height: '30px' }}
        onClick={handleOpenDialog}
      >
        <ListAltIcon />
      </Button>
      <Dialog fullWidth maxWidth="xs" open={open} onClose={handleCloseDialog}>
        <PeriodSettingsModalHeader onClose={handleCloseDialog}>
          {translate('pos.periodSettingsTitle', { startAt, endAt })}
        </PeriodSettingsModalHeader>
        <MuiDialogContent>
          <SettingsSelection
            arrivalDays={periodSettings.arrivalDays}
            departureDays={periodSettings.departureDays}
            minimumDelay={periodSettings.minimumDelay}
            minimumLength={periodSettings.minimumLength}
            setPeriodSettings={setPeriodSettings}
          />
        </MuiDialogContent>
        <MuiDialogActions>
          <Button
            onClick={() =>
              setPeriodSettings({
                arrivalDays: defaultArrivalDays,
                departureDays: defaultDepartureDays,
                minimumDelay: defaultMinimumDelay,
                minimumLength: defaultMinimumLength,
              })
            }
          >
            {translate('pos.defaultSettings')}
          </Button>
          <Button onClick={handleValidateForm}>{translate('ra.action.confirm')}</Button>
        </MuiDialogActions>
      </Dialog>
    </>
  );
};

export default PeriodSettingsModal;
