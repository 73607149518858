import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';

export default function getAllAdminPoolGroups(type: string, resource: string) {
  const queryName = `${type}`;
  return {
    query: gql`query ${queryName} {
      data: ${queryName}
    }`,
    parseResponse: ({ data }: ApolloQueryResult<any>) => {
      if (data) return data;
      throw new Error(`Pool groups not found for ${resource} resource`);
    },
  };
}
