import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';

export default function bookingStay(
  type: string,
  resource: string,
  { accommodationId, price, startAt, endAt }: Record<string, any>
) {
  return {
    query: gql`
      mutation booking($accommodationId: ID, $price: Float, $startAt: Date, $endAt: Date) {
        data: booking(
          accommodationId: $accommodationId
          price: $price
          startAt: $startAt
          endAt: $endAt
        ) {
          id
        }
      }
    `,
    variables: {
      accommodationId,
      startAt,
      endAt,
      price,
    },
    parseResponse: ({ data }: ApolloQueryResult<any>) => {
      if (data) return data;
      throw new Error(`Can't book stay`);
    },
  };
}
