import { makeStyles } from '@material-ui/core/styles';
import { linkToRecord } from 'react-admin';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
}));

const LinkToRecord = (props: any) => {
  const classes = useStyles();
  return (
    <Link className={classes.link} to={linkToRecord(props.basePath, props.id, props.linkType)}>
      {props.text}
    </Link>
  );
};

export default LinkToRecord;
