import { Theme, useMediaQuery } from '@material-ui/core';
import FullNameField from 'components/CustomFields/FullNameField';
import CustomizableDatagrid from 'components/CustomizableDatagrid/CustomizableDatagrid';
import {
  BooleanInput,
  DateField,
  EditButton,
  Filter,
  FilterProps,
  List,
  Pagination,
  PaginationProps,
  ReferenceField,
  SearchInput,
  TextField,
  useTranslate,
} from 'react-admin';

import rowStyle from './rowStyle';
import StayAsideFilter from './StayAsideFilter';

const StayFilter = (props: Omit<FilterProps, 'children'>) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <BooleanInput source="archivedAt" format={(v: any) => Boolean(v)} />
  </Filter>
);

const StayPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

export const StayList = (props: any) => {
  const translate = useTranslate();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  return (
    <List
      {...props}
      filters={isSmall && <StayFilter />}
      filterDefaultValues={{ archivedAt: false, filterDate: 'futurAndCurrent' }}
      pagination={<StayPagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      aside={<StayAsideFilter />}
      perPage={25}
      bulkActionButtons={false}
    >
      <CustomizableDatagrid rowClick="show" rowStyle={rowStyle}>
        <ReferenceField
          label={translate('resources.Stay.fields.tenant.name')}
          source="tenant.person.id"
          reference="Person"
        >
          <FullNameField />
        </ReferenceField>
        <DateField source="createdAt" />
        <ReferenceField
          label={translate('resources.Stay.fields.accommodation.name')}
          source="accommodation.id"
          reference="Accommodation"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label={translate('resources.Stay.fields.accommodation.park.name')}
          source="accommodation.park.id"
          reference="Park"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField source="startAt" />
        <DateField source="endAt" />
        <EditButton />
      </CustomizableDatagrid>
    </List>
  );
};
