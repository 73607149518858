import { RotationDays } from 'types/fleet.types';

const getDefaultRotationDays = (): RotationDays => {
  return {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  };
};

export default getDefaultRotationDays;
