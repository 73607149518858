import DateFnsUtils from '@date-io/date-fns';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { InputProps, useInput } from 'react-admin';

const TimePickerInput = (props: InputProps) => {
  const {
    input: { value, ...inputProps },
  } = useInput(props);
  const time = new Date(1970, 1, 1);
  time.setHours(props.defaultHours || 0);
  time.setMinutes(props.defaultMinutes || 0);
  time.setSeconds(0);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        ampm={false}
        margin="normal"
        id={`time-picker-${props.source}`}
        label={props.label}
        initialFocusedDate={time}
        KeyboardButtonProps={{
          'aria-label': 'change time',
        }}
        onError={(error) => console.error(error)}
        value={value ? new Date(value) : time}
        {...inputProps}
      />
    </MuiPickersUtilsProvider>
  );
};

export default TimePickerInput;
