import Storage from '@aws-amplify/storage';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { makeStyles } from '@material-ui/core/styles';
import { debounce } from 'lodash';
import { MouseEvent, useCallback, useContext, useEffect, useState } from 'react';
import { DatagridProps, DataProviderContext, Record, useRefresh } from 'react-admin';

import { SortablePicture } from './SortablePicture';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    maxWidth: '800px',
    gridGap: '10px',
    padding: '20px',
    [theme.breakpoints.up('xs')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(5, 1fr)',
    },
  },
}));

const OrderedDatagrid = (props: DatagridProps) => {
  const dataProvider = useContext(DataProviderContext);
  const refresh = useRefresh();
  const [items, setItems] = useState<string[]>([]);
  const classes = useStyles();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // Save all pictures with new sorting
  const saveSorting = async (sortedItems: string[]) => {
    if (!props || !props.data) return;
    await Promise.all(
      sortedItems.map((item, index) => {
        if (!props.data || !props.resource) return;
        const record = props.data[item];
        // eslint-disable-next-line consistent-return
        return dataProvider.update(props.resource, {
          id: record.id,
          data: { id: record.id, priority: index + 1 },
          previousData: record,
        });
      })
    );
  };

  // Set sortable items with an array of ids
  useEffect(() => {
    if (props.ids) setItems(props.ids.map((id) => id.toString()));
    return () => setItems([]);
  }, [props.ids]);

  // Debounce priorities saving
  const debouncedSavePriorities = useCallback(debounce(saveSorting, 2000), [props.data]);

  // On drop event update sorting and save it
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (over?.id && active.id !== over.id) {
      setItems((oldItems) => {
        const oldIndex = oldItems.indexOf(active.id);
        const newIndex = oldItems.indexOf(over.id);
        const sortedItems = arrayMove(oldItems, oldIndex, newIndex);
        debouncedSavePriorities(sortedItems);
        return sortedItems;
      });
    }
  }

  // Get S3 signed url
  const getUrl = async (key: string) => (await Storage.get(key)) as string;

  // Remove picture on database and S3
  const handleRemove = (record: Record | undefined) => async () => {
    if (!record || !props.resource) return;
    setItems((oldItems) => {
      const removeIndex = oldItems.indexOf(`${record.id}`);
      return oldItems.filter((item, index) => index !== removeIndex);
    });
    await dataProvider.delete(props.resource, { id: record.id, previousData: record });
    Storage.remove(record.picture.key);
  };

  // Set picture to published
  const handleToggleFeatured =
    (record: Record | undefined) => async (event: MouseEvent<HTMLButtonElement>) => {
      event.nativeEvent.stopImmediatePropagation();
      if (!record || !props.resource) return;
      await dataProvider.update(props.resource, {
        id: record.id,
        data: { id: record.id, publishedAt: record.publishedAt ? null : new Date() },
        previousData: record,
      });
      refresh();
    };

  return (
    <>
      {items.length !== 0 && (
        <ul className={classes.container}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={items}>
              {items.map((id) => (
                <SortablePicture
                  key={id}
                  id={id}
                  record={props.data?.[id]}
                  getUrl={getUrl}
                  onRemove={handleRemove(props.data?.[id])}
                  onToogleFeatured={handleToggleFeatured(props.data?.[id])}
                />
              ))}
            </SortableContext>
          </DndContext>
        </ul>
      )}
    </>
  );
};

export default OrderedDatagrid;
