import { Button, CardActions, CircularProgress, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import { Field, Form } from 'react-final-form';

interface Props {
  submitChangePassword: (arg1: FormData) => void;
  user: User;
  loading?: boolean;
}

interface User {
  username: string;
  password: string;
}

interface FormData {
  username: string;
  password: string;
  newPassword: string;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'RaLoginForm' }
);

const Input = ({ meta: { touched, error }, input: inputProps, ...props }: any) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

export const ChangePasswordForm = (props: Props) => {
  const { submitChangePassword, user, loading } = props;
  const translate = useTranslate();
  const classes = useStyles(props);

  const validate = (values: FormData) => {
    const errors: {
      username: string | undefined;
      newPassword: string | undefined;
    } = { username: undefined, newPassword: undefined };

    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }
    if (!values.newPassword) {
      errors.newPassword = translate('ra.validation.required');
    }
    return errors;
  };

  return (
    <Form
      initialValues={{ password: user.password }}
      onSubmit={submitChangePassword}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                autoFocus
                id="username"
                name="username"
                component={Input}
                label={translate('ra.auth.username')}
                disabled
                defaultValue={user.username}
              />
            </div>
            <div className={classes.input}>
              <Field
                id="newPassword"
                name="newPassword"
                component={Input}
                label={translate('pos.newPassword')}
                type="password"
                disabled={loading}
              />
            </div>
          </div>
          <CardActions>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              className={classes.button}
            >
              {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
              {translate('pos.validate')}
            </Button>
          </CardActions>
        </form>
      )}
    />
  );
};
