import AddIcon from '@material-ui/icons/Add';
import { Button, ListToolbar } from 'react-admin';
import { CalendarToolbarProps } from 'types/calendar.types';

export const CalendarToolbar = ({
  isFleet = false,
  disabled,
  handleAddNewPeriod,
}: CalendarToolbarProps) => {
  return (
    <ListToolbar
      actions={
        isFleet ? (
          <div>
            <Button
              onClick={(e) => handleAddNewPeriod(e, undefined)}
              label="Ajouter une periode"
              style={{ marginRight: '20px' }}
              disabled={disabled}
            >
              <AddIcon />
            </Button>
          </div>
        ) : (
          <div>
            <Button
              onClick={(e) => handleAddNewPeriod(e, true)}
              label="Ajouter une disponibilité"
              style={{ marginRight: '20px' }}
              disabled={disabled}
            >
              <AddIcon />
            </Button>
            <Button
              onClick={(e) => handleAddNewPeriod(e, false)}
              label="Ajouter une indisponibilité"
              style={{ marginRight: '20px' }}
              disabled={disabled}
            >
              <AddIcon />
            </Button>
          </div>
        )
      }
    />
  );
};
