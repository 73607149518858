import Storage from '@aws-amplify/storage';
import { Card, CardContent, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: 200,
    height: 200,
    padding: 0,
    margin: 2,
    background: '#292828',
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  picture: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
});
const PictureField = (props: any) => {
  const classes = useStyles();
  const [url, setUrl] = useState<string | undefined>(undefined);
  // Get S3 signed url
  const getUrl = async (key: string) => (await Storage.get(key)) as string;
  useEffect(() => {
    async function fetchPicture() {
      if (props.ids.length > 0) {
        const [id] = props.ids;
        setUrl(await getUrl(props.data?.[id]?.picture.key));
      }
    }
    fetchPicture();
  }, [props.data]);

  if (!url) return <></>;

  return (
    <Card className={classes.container}>
      <CardContent className={classes.cardContent}>
        <img className={classes.picture} src={url} alt="Picture" />
      </CardContent>
    </Card>
  );
};

export default PictureField;
