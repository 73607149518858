import { makeStyles, Theme } from '@material-ui/core';
import { Styles } from '@material-ui/styles';
import classNames from 'classnames';
import { get } from 'lodash';
import React, { FC } from 'react';
import { ImageFieldProps } from 'react-admin';
import SVG from 'react-inlinesvg';

type Size = 'small' | 'large';

const styles: Styles<Theme, any> = (theme) => ({
  preview: {
    fill: `${theme.palette.type === 'dark' ? '#fff' : '#333232c4'} !important`,
  },
  small: {
    width: '1.5rem',
    height: '1.5rem',
  },
  large: {
    width: '128px',
    height: '128px',
  },
});

const useStyles = makeStyles(styles);

const InlineSvgField: FC<ImageFieldProps & { size?: Size }> = (props) => {
  const { source, record, size = 'large' } = props;
  const classes = useStyles(styles);

  if (!source) {
    return null;
  }

  const fileContent = get(record, source);
  if (!fileContent) {
    return null;
  }

  return <SVG src={String(fileContent)} className={classNames(classes.preview, classes[size])} />;
};

export default InlineSvgField;
