import { Divider } from '@material-ui/core';
import FalseIcon from '@material-ui/icons/Clear';
import TrueIcon from '@material-ui/icons/Done';
import SortableList from 'components/DragAndDrop/SortableList';
import { Fragment, ReactElement, useState } from 'react';
import {
  BulkActionProps,
  BulkDeleteButton,
  Datagrid,
  FunctionField,
  List,
  ListProps,
  ReferenceField,
  TextField,
  useTranslate,
} from 'react-admin';

import PercentNumberField from '../../CustomFields/PercentNumberField';
import BulkFeaturedButton from './BulkFeaturedButton';
import ParkOffersTabs from './ParkOffersTabs';

const ParkOffersBulkActionButtons = (props: BulkActionProps) => {
  const translate = useTranslate();
  return (
    <Fragment>
      <BulkFeaturedButton label={translate('pos.bulkActions.featured')} value={true} {...props} />
      <BulkFeaturedButton
        label={translate('pos.bulkActions.unfeatured')}
        value={false}
        {...props}
      />
      <BulkDeleteButton {...props} />
    </Fragment>
  );
};

export const AllParkOffersList = (props: ListProps) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      sort={{ field: 'priority', order: 'DESC' }}
      perPage={25}
      bulkActionButtons={<ParkOffersBulkActionButtons />}
    >
      <Datagrid rowClick="edit">
        <ReferenceField
          label={translate('resources.ParkOffer.fields.park.name')}
          source="parkId"
          reference="Park"
        >
          <TextField source="name" />
        </ReferenceField>
        <PercentNumberField source="percentage" />
        <FunctionField
          source="priority"
          render={(record: any) => (record.priority ? <TrueIcon /> : <FalseIcon />)}
        />
      </Datagrid>
    </List>
  );
};

export const FeaturedParkOfferslist = (props: ListProps) => (
  <SortableList
    orderedKey="priority"
    labelSource="park.name"
    filter={{ ...props.filter, featured: true }}
    {...props}
  >
    <ReferenceField source="parkId" reference="Park" label="" link={false}>
      <TextField source="name" />
    </ReferenceField>
  </SortableList>
);

export const ParkOffersList = (props: ListProps): ReactElement => {
  const [tab, setTab] = useState('all');
  return (
    <Fragment>
      <ParkOffersTabs tab={tab} setTab={setTab} />
      <Divider />
      {tab === 'all' ? <AllParkOffersList {...props} /> : <FeaturedParkOfferslist {...props} />}
    </Fragment>
  );
};
