import { ApolloQueryResult, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Auth from '@aws-amplify/auth';
import { IntrospectionField, IntrospectionSchema, IntrospectionType } from 'graphql';
import buildApolloClient, { buildQuery as buildQueryFactory } from 'ra-data-graphql-simple';
import { GET_LIST, GET_MANY, LegacyDataProvider } from 'react-admin';

import acceptParkApplicationReview from './parkApplication/acceptParkApplicationReview';
import rejectParkApplicationReview from './parkApplication/rejectParkApplicationReview';
import bookingStay from './shared/bookingStay';
import disconnectManyToMany from './shared/disconnectManyToMany';
import getAllAdminPoolGroups from './shared/getAllAdminPoolGroups';
import publish from './shared/publish';
import setToMainPropertyManager from './shared/setToMainPropertyManager';
import unpublish from './shared/unpublish';

export enum Actions {
  ALL_ADMIN_POOL_GROUPS = 'allAdminPoolGroups',
  DELETE = 'delete',
  DISCONNECT = 'disconnect',
  PUBLISH = 'publish',
  UNPUBLISH = 'unpublish',
  ACCEPT_APPLICATION_REVIEW = 'acceptParkApplicationReview',
  REJECT_APPLICATION_REVIEW = 'rejectParkApplicationReview',
  SET_TO_MAIN_PROPERTY_MANAGER = 'setToMainPropertyManager',
  BOOKING_STAY = 'booking',
}

type IntrospectionResource = IntrospectionType & {
  [key: string]: IntrospectionField;
};

interface IntrospectionResults {
  types: IntrospectionType[];
  queries: IntrospectionField[];
  resources: IntrospectionResource[];
  schema: IntrospectionSchema;
}

const resourceWithoutCount = (type: string, resource: string, params: any, buildQuery: any) => {
  const customQuery = buildQuery(type, resource, params);
  const raParseResponse = customQuery.parseResponse;
  customQuery.parseResponse = (result: ApolloQueryResult<any>) => {
    if (!result.data) return { data: [], total: 0 };
    const response = raParseResponse(result);
    return { data: response.data, total: response.data.length };
  };
  return customQuery;
};

const customBuildQuery = (introspectionResults: IntrospectionResults): LegacyDataProvider => {
  const buildQuery = buildQueryFactory(introspectionResults);
  return (type, resource, params) => {
    if ((type === GET_LIST || type === GET_MANY) && resource === 'SearchOffer') {
      return resourceWithoutCount(type, resource, params, buildQuery);
    }
    switch (type) {
      case Actions.ALL_ADMIN_POOL_GROUPS:
        return getAllAdminPoolGroups(type, resource);
      case Actions.PUBLISH:
        return publish(type, resource, params);
      case Actions.UNPUBLISH:
        return unpublish(type, resource, params);
      case Actions.DISCONNECT:
        return disconnectManyToMany(type, resource, params);
      case Actions.ACCEPT_APPLICATION_REVIEW:
        return acceptParkApplicationReview(type, resource, params);
      case Actions.REJECT_APPLICATION_REVIEW:
        return rejectParkApplicationReview(type, resource, params);
      case Actions.SET_TO_MAIN_PROPERTY_MANAGER:
        return setToMainPropertyManager(type, params);
      case Actions.BOOKING_STAY:
        return bookingStay(type, resource, params);
      default:
        return buildQuery(type, resource, params);
    }
  };
};

const getIntrospectionSchema = async () => {
  try {
    // Ask to react admin to fetch introspection schema from api with authorization token
    await Auth.currentAuthenticatedUser();
    return {};
  } catch {
    // Empty introspection schema for auth pages
    return {
      schema: {
        queryType: {
          name: 'Query',
        },
        mutationType: {
          name: 'Mutation',
        },
        subscriptionType: null,
        types: [],
        directives: [],
      },
    };
  }
};

export default async function prepareDataProvider(): Promise<LegacyDataProvider> {
  const introspectionSchema = await getIntrospectionSchema();

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL,
  });

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token
    try {
      const session = await Auth.currentSession();
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          Authorization: session.getIdToken().getJwtToken(),
        },
      };
    } catch (e) {
      console.error(e);
      return {};
    }
  });

  return buildApolloClient({
    clientOptions: {
      link: authLink.concat(httpLink),
    },
    introspection: {
      ...introspectionSchema,
      operationNames: {
        [Actions.ACCEPT_APPLICATION_REVIEW]: () => Actions.ACCEPT_APPLICATION_REVIEW,
        [Actions.REJECT_APPLICATION_REVIEW]: () => Actions.REJECT_APPLICATION_REVIEW,
      },
    },
    buildQuery: customBuildQuery,
  });
}
