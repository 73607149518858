import { Divider } from '@material-ui/core';
import FalseIcon from '@material-ui/icons/Clear';
import TrueIcon from '@material-ui/icons/Done';
import TranslatableValueField from 'components/CustomFields/TranslatableValueField';
import SortableList from 'components/DragAndDrop/SortableList';
import { Fragment, ReactElement, useState } from 'react';
import {
  BulkActionProps,
  BulkDeleteButton,
  Datagrid,
  Filter,
  FilterProps,
  FunctionField,
  List,
  ListProps,
  SearchInput,
  TextField,
  useTranslate,
} from 'react-admin';

import BulkFeaturedButton from './BulkFeaturedButton';
import LocationTabs from './LocationTabs';

const LocationBulkActionButtons = (props: BulkActionProps) => {
  const translate = useTranslate();
  return (
    <Fragment>
      <BulkFeaturedButton label={translate('pos.bulkActions.featured')} value={true} {...props} />
      <BulkFeaturedButton
        label={translate('pos.bulkActions.unfeatured')}
        value={false}
        {...props}
      />
      <BulkDeleteButton {...props} />
    </Fragment>
  );
};

const LocationFilter = (props: Omit<FilterProps, 'children'>) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

export const AllLocationList = (props: ListProps) => (
  <List
    {...props}
    perPage={25}
    bulkActionButtons={<LocationBulkActionButtons />}
    filters={<LocationFilter />}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TranslatableValueField translatePath="categories" source="category" />
      <FunctionField
        source="priority"
        render={(record: any) => (record.priority ? <TrueIcon /> : <FalseIcon />)}
      />
    </Datagrid>
  </List>
);

export const FeaturedLocationlist = (props: ListProps) => (
  <SortableList
    orderedKey="priority"
    labelSource="name"
    filter={{ ...props.filter, featured: true }}
    {...props}
  >
    <TextField source="name" label="" />
  </SortableList>
);

export const LocationList = (props: ListProps): ReactElement => {
  const [tab, setTab] = useState('all');
  return (
    <Fragment>
      <LocationTabs tab={tab} setTab={setTab} />
      <Divider />
      {tab === 'all' ? <AllLocationList {...props} /> : <FeaturedLocationlist {...props} />}
    </Fragment>
  );
};
