import { Alert, AlertTitle } from '@material-ui/lab';
import { get } from 'lodash';
import { FieldProps, useTranslate } from 'react-admin';

const AccommodationsStatusField = ({ record, source }: FieldProps) => {
  const translate = useTranslate();
  const accommodationsCount = source ? get(record, source) : null;

  if (!accommodationsCount) {
    return (
      <Alert severity="error">
        <AlertTitle>
          {translate(`resources.Park.statuses.accommodationsCount.withoutAccommodation.title`)}
        </AlertTitle>
        {translate(`resources.Park.statuses.accommodationsCount.withoutAccommodation.description`)}
      </Alert>
    );
  }
  return (
    <Alert severity="success">
      <AlertTitle>
        {translate(`resources.Park.statuses.accommodationsCount.withAccommodations.title`)}
      </AlertTitle>
      {translate(`resources.Park.statuses.accommodationsCount.withAccommodations.description`, {
        smart_count: accommodationsCount,
      })}
    </Alert>
  );
};
export default AccommodationsStatusField;
