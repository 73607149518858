import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { FC } from 'react';
import { useMutation, useNotify, useRedirect, useRefresh } from 'react-admin';

import { Actions } from '../../dataProvider';
import { Stay } from '../../types/stay.types';

const BookingButton: FC<{ record: Stay }> = ({ record }) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const refresh = useRefresh();

  const options = {
    onSuccess: ({ data }: any) => {
      notify('pos.stay.booking', 'success');
      redirectTo(`/Stay/${data.id}`);
      refresh();
    },
    onFailure: () => {
      notify('pos.stay.unBooking', 'warning');
    },
  };

  const [booking, { loading: acceptMutationLoading }] = useMutation(
    {
      type: Actions.BOOKING_STAY,
      resource: 'Stay',
      payload: {
        accommodationId: record.accommodationId,
        price: record.price,
        startAt: record.startAt,
        endAt: record.endAt,
      },
    },
    options
  );

  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      style={{ paddingLeft: '10px' }}
      onClick={booking}
      disabled={acceptMutationLoading}
    >
      Réserver le séjour
    </Button>
  );
};

BookingButton.propTypes = {
  record: PropTypes.any,
};

export default BookingButton;
