import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import { ReactElement, useState } from 'react';
import {
  ListProps,
  Loading,
  Record,
  useGetList,
  useMutation,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin';

import { SortableItem } from './SortableItem';

const useStyles = makeStyles(() => ({
  button: {
    marginTop: '2rem',
  },
  card: {
    marginTop: '2rem',
  },
}));

interface DragAndDropListProps extends ListProps {
  orderedKey: string;
  labelSource: string;
  cardHeader?: ReactElement | false;
  children: ReactElement | ReactElement[];
}

const SortableList = ({
  resource = '',
  orderedKey = 'priority',
  filter = {},
  children,
  cardHeader = false,
}: DragAndDropListProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [items, setItems] = useState<string[]>([]);
  const [disabledSaving, setDisableSaving] = useState(true);
  const [mutate] = useMutation();
  const refresh = useRefresh();
  const notify = useNotify();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const { loaded, loading } = useGetList(
    resource,
    { page: 1, perPage: 1000 },
    { field: orderedKey, order: 'ASC' },
    filter,
    {
      onSuccess: (result) => {
        setItems(result.data.map((record: Record) => String(record.id)));
      },
    }
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (over?.id && active.id !== over.id) {
      setItems((oldItems) => {
        const oldIndex = oldItems.indexOf(active.id);
        const newIndex = oldItems.indexOf(over.id);
        return arrayMove(oldItems, oldIndex, newIndex);
      });
      setDisableSaving(false);
    }
  }

  if (!loaded || loading) return <Loading />;

  const handleSaveSorting = () => {
    setDisableSaving(true);
    const bulkUpdate = items.map(
      (id: string, index: number) =>
        new Promise((resolve, reject) =>
          mutate(
            {
              type: 'update',
              resource,
              payload: {
                id: Number(id),
                data: { id: Number(id), [orderedKey]: index + 1 },
              },
            },
            {
              onSuccess: (): void => resolve(true),
              onFailure: (): void => reject(new Error(`Record ${id} can't be updated`)),
            }
          )
        )
    );
    Promise.all(bulkUpdate)
      .then(() => refresh())
      .catch((error) => notify(error));
  };

  if (items.length === 0 && !loading) return <p>{translate('ra.navigation.no_results')}</p>;

  return (
    <>
      <Card className={classes.card}>
        {cardHeader}
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
        >
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {items.map((id: string) => (
              <SortableItem key={id} id={id} resource={resource}>
                {children}
              </SortableItem>
            ))}
          </SortableContext>
        </DndContext>
      </Card>
      <Button
        className={classes.button}
        startIcon={<SaveIcon />}
        onClick={handleSaveSorting}
        variant="contained"
        color="primary"
        disabled={disabledSaving}
      >
        {translate('pos.save')}
      </Button>
    </>
  );
};

export default SortableList;
