import RemoveIcon from '@material-ui/icons/Remove';
import { Actions } from 'dataProvider';
import { Button, useMutation, useNotify, useRefresh, useTranslate } from 'react-admin';

interface DisconnectButtonProps {
  record?: { id: string | number };
  fromResource: string;
  resource?: string;
  resourceId: string | number;
}

const DisconnectButton = ({
  record = { id: 0 },
  fromResource: resource,
  resource: reference,
  resourceId,
}: DisconnectButtonProps) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const options = {
    onSuccess: () => {
      notify(`La ${reference} a été retiré avec succès`);
      refresh();
    },
    onFailure: () => {
      notify(`Erreur lors de la suppression de la ${reference}#${record.id}`, 'warning');
    },
  };

  const [disconnect, { loading: disconnectMutationLoading }] = useMutation(
    {
      type: Actions.DISCONNECT,
      resource,
      payload: { reference, referenceId: record.id, resourceId },
    },
    options
  );
  const handleClick = (event: any) => {
    event.stopPropagation();
    disconnect();
  };

  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      style={{ float: 'right' }}
      onClick={handleClick}
      disabled={disconnectMutationLoading}
      label={translate('ra.action.remove')}
    >
      <RemoveIcon />
    </Button>
  );
};

export default DisconnectButton;
