import { SectionTitle } from 'components/formUtils';
import { ReactElement } from 'react';
import { Datagrid, Loading, Record, ReferenceManyField } from 'react-admin';
import { Route } from 'react-router';

import ConnectButton from './ConnectButton';
import ConnectFormDialog from './ConnectFormDialog';
import DisconnectButton from './DisconnectButton';

interface ManyToManyReferenceInput {
  children: ReactElement | ReactElement[];
  resource: string;
  renderReferenceForm: (props: any) => ReactElement;
  reference: string;
  record?: Record;
  target: string;
  basePath?: string;
  label?: string | undefined;
}

const ManyToManyReferenceInput = ({
  children,
  resource,
  reference,
  renderReferenceForm,
  basePath,
  record,
  target,
  label = undefined,
}: ManyToManyReferenceInput) => {
  if (!record) return <Loading />;
  return (
    <>
      {label && <SectionTitle label={label} />}
      <ConnectButton basePath={`${basePath}/${record.id}/${reference}`} />
      <ReferenceManyField reference={reference} target={target}>
        <Datagrid>
          {children}
          <DisconnectButton fromResource={resource} resourceId={record.id} />
        </Datagrid>
      </ReferenceManyField>
      <Route path={`${basePath}/:resourceId/${reference}/connect`}>
        {({ match }) =>
          match?.isExact ? (
            <ConnectFormDialog
              record={record}
              renderReferenceForm={renderReferenceForm}
              redirectPath={`${basePath}/${record?.id}`}
            />
          ) : null
        }
      </Route>
    </>
  );
};

export default ManyToManyReferenceInput;
