import frenchMessages from 'ra-language-french';
import { TranslationMessages } from 'react-admin';

const customFrenchMessages: TranslationMessages = {
  ...frenchMessages,
  pos: {
    button: {
      archive: 'Archiver',
      unArchive: 'Désarchiver le séjour',
    },
    content: {
      archive: 'Êtes-vous sûr(e) de vouloir archiver cet élément ?',
      unArchive:
        "Êtes-vous sûr(e) de vouloir désarchiver cet élément pour pouvoir l'éditer à nouveau ?",
    },
    phone: 'Téléphone',
    search: 'Rechercher',
    configuration: 'Configuration',
    listConfiguration: 'Configuration de la liste',
    language: 'Langue',
    theme: {
      name: 'Theme',
      light: 'Clair',
      dark: 'Obscur',
    },
    totalSearchResult: '%{smart_count} résultat |||| %{smart_count} résultats',
    searchBy: 'Rechercher par',
    showPassword: 'Afficher le mot de passe',
    validation: {
      invalidPassword:
        'Veuillez saisir un mot de passe valide. Celui-ci doit contenir au minimum 8 caractères avec au moins un chiffre, une lettre majuscule et une lettre minuscule.',
      invalidDate: 'La date saisie est invalide',
    },
    validate: 'Valider',
    create: 'Créer',
    save: 'Enregistrer',
    editPassword: 'Modifier le mot de passe',
    editOfPassword: 'Modification du mot de passe',
    newPassword: 'Nouveau mot de passe',
    confirmPassword: 'Confirmez le mot de passe',
    addPeriod: 'Ajouter une période',
    periodSettingsTitle: 'Période du %{startAt} au %{endAt}',
    defaultSettings: 'Paramètres par defaut',
    rotationDays: 'Jours de rotation (Départ / Arrivée)',
    arrivalDays: "Jours d'arrivée",
    departureDays: 'Jours de départ',
    minimumDelay: 'Durée minimal avant le debut du séjour',
    minimumLength: "Durée minimal d'un séjour",
    defaultMinimumDelay: 'Durée minimal avant le debut du séjour',
    defaultMinimumLength: "Durée minimal d'un séjour",
    dateDiff: {
      diffInYears: '%{smart_count} an |||| %{smart_count} ans',
      diffInMonths: '%{smart_count} mois',
      diffInWeeks: '%{smart_count} semaine |||| %{smart_count} semaines',
      diffInDays: '%{smart_count} jour |||| %{smart_count} jours',
      diffInHours: '%{smart_count} hour |||| %{smart_count} hours',
      diffInMinutes: '%{smart_count} minute |||| %{smart_count} minutes',
    },
    dateUnity: {
      day: 'Jour',
      month: 'Mois',
      year: 'Année',
    },
    days: {
      monday: 'Lundi',
      tuesday: 'Mardi',
      wednesday: 'Mercredi',
      thursday: 'Jeudi',
      friday: 'Vendredi',
      saturday: 'Samedi',
      sunday: 'Dimanche',
    },
    calendar: {
      sections: {
        availabilities: 'Disponibilités',
        unavailabilities: 'Indisponibilités',
      },
    },
    modifyDate: 'Modifier les dates',
    from: 'Du',
    to: 'Au',
    dashboard: {
      monthly_revenue: 'CA à 30 jours',
      month_history: "Chiffre d'affaire sur 30 jours",
      new_orders: 'Nouvelles commandes',
      pending_reviews: 'Commentaires à modérer',
      all_reviews: 'Voir tous les commentaires',
      new_customers: 'Nouveaux clients',
      all_customers: 'Voir tous les clients',
      pending_orders: 'Commandes à traiter',
      order: {
        items: 'par %{customer_name}, un poster |||| par %{customer_name}, %{nb_items} posters',
      },
      welcome: {
        title: 'Bienvenue sur la démo e-commerce de react-admin',
        subtitle:
          "Ceci est le back-office d'un magasin de posters imaginaire. N'hésitez pas à explorer et à modifier les données. La démo s'exécute en local dans votre navigateur, et se remet à zéro chaque fois que vous rechargez la page.",
        ra_button: 'Site web de react-admin',
        demo_button: 'Code source de cette démo',
      },
    },
    menu: {
      settings: 'Paramètres',
      reviews: 'Modération',
    },
    tabs: {
      reviews: {
        pending: 'En attente',
        accepted: 'Acceptées',
        rejected: 'Rejetées',
      },
      locations: {
        all: 'Toutes les destinations',
        featured: 'Destinations à la une',
      },
      favoriteAccommodation: {
        base: 'Hébergements Favoris',
        sortable: 'Réorganiser la liste',
      },
      parkOffer: {
        all: 'Toutes les offres',
        featured: 'Offres à la une',
      },
      accommodationEquipments: {
        all: 'Tous les équipements',
        standard: 'Standard',
        kitchen: 'Cuisine',
        room: 'Chambre',
        outdoor: 'Extérieur',
      },
    },
    bulkActions: {
      featured: 'Mettre à la une',
      unfeatured: 'Mettre en retrait',
    },
    notifications: {
      datePickerOverlappingError: `La période que vous essayez de modifier (du %{changePeriodStart} au %{changePeriodEnd})
      est invalide. Celle-ci entre en conflit avec la période du %{periodStart} au %{periodEnd}.
      Votre modification n'a pas été prise en compte, veuillez entrer de nouveau une période valide.`,
      datePickerParkOpeningDaysError: `La période que vous essayez de modifier (du %{changePeriodStart} au %{changePeriodEnd})
      est invalide. Celle-ci n'est pas comprise dans les périodes d'ouverture du camping.
      Assurez-vous d'avoir correctement paramétré les périodes d'ouverture du camping et veuillez entrer de nouveau une période valide.`,
      auth: {
        userNotFoundException: 'Utilisateur inconnu',
        changePasswordRequired: 'Modification de mot de passe requise',
        notAuthorizedException: 'Identifiant ou mot de passe incorrect',
        invalidPasswordException: 'Mot de passe saisi non conforme',
        passwordNotLongEnough: 'Le mot de passe doit contenir au minimum 8 caractères',
        passwordMustHaveUppercaseCharacters:
          'Le mot de passe doit contenir au moins un lettre majuscule',
        passwordMustHaveNumericCharacters: 'Le mot de passe doit contenir au moins un chiffre',
        passwordMustHaveSymbolCharacters:
          'Le mot de passe doit contenir au moins un caractère non-alphanumérique',
      },
    },
    publishedStatues: {
      published: {
        title: 'Publié.',
        description: 'Publié depuis le %{date}',
        action: 'Dépublier',
        actionMessage: 'Publié avec succès',
        errorMessage: `ERREUR : L'élément ne peut pas être publié`,
        confirm: "Êtes vous sûr de vouloir publier l'élément suivant: \n %{name}",
        confirmTitle: 'Publié',
      },
      notPublished: {
        title: 'En attente de publication.',
        description: 'Vous devez publier pour le rendre visible.',
        action: 'Publier',
        actionMessage: 'Dépublié avec succès',
        errorMessage: "ERREUR L'élément ne peut pas être dépublié",
        confirm: `Êtes vous sûr de vouloir dépublier l'élément suivant: %{name}`,
        confirmTitle: 'Dépublié',
      },
    },
    stay: {
      booking: 'Séjour réservé avec succés',
      unBooking: 'La réservation du séjour à échouée',
    },
  },
  resources: {
    Reviews: {
      fields: {
        rating: 'Classement',
      },
    },
    SearchOffer: {
      name: 'Recherche',
      fields: {
        price: 'Prix',
        startAt: "Date d'arrivée",
        endAt: 'Date de départ',
        accommodation: {
          name: "Nom de l'hébergement",
          park: {
            name: 'Nom du camping',
            location: 'Destination',
          },
        },
      },
      filters: {
        minimumPerson: 'Nombre de personnes',
      },
    },
    Accommodation: {
      name: 'Hébergements',
      singular: 'Hébergement',
      fields: {
        vintage: 'Millésime',
        createdAt: 'Date de création',
        mark: 'Marque',
        model: 'Modèle',
        fleet: { id: 'Flotte' },
        deposit: 'Dépot de Caution',
        parking: 'Nombre de places de parking',
        name: 'Nom de la parcelle',
        pictures: 'Photos',
        owner: { id: 'Propriétaire' },
        ownerId: 'Propriétaire',
        park: { id: 'Camping' },
        accommodationType: { id: "Type d'hébergement" },
        maximumPerson: 'Nombre maximum de personnes',
        bedroom: 'Nombre de chambres',
        bathroom: 'Nombre de salles de bain',
        toilet: 'Nombre de WC',
        surface: 'Surface',
        disabledAccessible: 'Accès PMR',
        petAllowed: 'Animaux autorisés',
        partyAllowed: 'Fêtes autorisés',
        smokingAllowed: 'Fumeurs autorisés',
        terrace: "Cet hébergement dispose d'une terrasse",
        terraceSurface: 'Surface',
        terraceCovered: 'Surface couverte',
        terraceClosed: 'Surface fermée',
        maxDiscount: 'Pourcentage de remise accepté',
        accommodationEquipmentId: 'Choisissez un équipement',
        defaultPrice: 'Prix de la nuitée',
        title: 'Titre',
        titleOverrided: 'Titre Personnalisé',
        statuses: {
          stayPeriod: {
            free: {
              title: 'Libre.',
              description: 'Cet hébergement est actuellement libre.',
              link: 'Séjour',
            },
            busy: {
              title: 'Occupé.',
              description: 'Cet hébergement est actuellement occupé.',
            },
          },
          ownerAccount: {
            valid: {
              title: 'Le compte du proprietaire est valide.',
            },
            notValid: {
              title: 'Compte proprietaire non valide.',
              description: "Le compte du proprietaire n'a pas encore été validé.",
            },
          },
          offers: {
            withOffers: {
              title: 'Offre Catalogue.',
              description: '1 offre associé. |||| %{smart_count} offres associé.',
            },
            withoutOffer: {
              title: "Pas d'offre catalogue.",
              description: "Aucune offre n'est renseignée",
            },
          },
        },
      },
      filters: {
        park: 'Camping',
        owner: 'Propriétaire',
        maximumPerson: 'Nb max de personnes',
        createdAt: 'Date de création',
        maxDiscount: 'Remise max',
        vintage: 'Millésime',
      },
      bedroom: '%{smart_count} chambre |||| %{smart_count} chambres',
      addEquipment: 'Ajouter un équipement',
      sections: {
        picture: 'Photos',
        equipments: 'Équipements',
        accommodation: 'Logement',
        terrace: 'Terrasse',
        defaultValues: "Valeurs par defaut de l'hébergement",
      },
      tabs: {
        infos: 'Informations pratiques',
        equipment: 'Équipements',
        pictures: 'Photos',
        calendar: 'Calendrier',
      },
    },
    ParkApplication: {
      name: 'Campings',
      editTitle: 'Détails du parc',
      parkEditLink: 'Éditer le camping',
      fields: {
        name: 'Nom du camping',
        chain: 'Groupe',
        postcode: 'Code postal',
        email: 'Email',
        phone: 'Téléphone',
        createdAt: 'Date de candidature',
        interestTourOperator: 'Tour Operator',
        interestAccommodationManagement: 'Gestion locative',
        interestSeller: 'Vente mobil-home',
      },
    },
    Park: {
      name: 'Campings',
      singular: 'Camping',
      statuses: {
        openingDays: {
          open: {
            title: 'Ouvert',
            description: "Depuis le %{startAt} jusqu'au %{endAt}.",
          },
          openAt: {
            title: 'Fermé',
            description: 'Ouverture le : %{startAt}.',
          },
          noNewDate: {
            title: 'Fermé',
            description: 'Fermé depuis le : %{endAt}, aucune nouvelle date rensignée.',
          },
          noDate: {
            title: 'Aucune date',
            description: "Aucune date d'ouverture n'a été renseignée.",
          },
        },
        accommodationsCount: {
          withAccommodations: {
            title: 'Hébergments',
            description: '1 hébergement associé. |||| %{smart_count} hébergements associé.',
          },
          withoutAccommodation: {
            title: 'Hebergments',
            description: "Ce camping n'a pas d'hérgements associé.",
          },
        },
      },
      fields: {
        description: {
          fr: 'Description',
        },
        ownerId: 'Propriétaire',
        parkFacilityId: 'Choisissez un aménagement',
        locationId: 'Choisissez une destination',
        phone: 'Téléphone',
        pictures: 'Photos',
        name: 'Nom du camping',
        chain: 'Groupe',
        updatedAt: 'Dernière modification',
        publishedAt: 'Camping Publié',
        postcode: 'Code postal',
        city: 'Ville',
        addressLine1: 'Adresse',
        addressLine2: "Complément d'adresse",
        country: 'Pays',
        touristtax: 'Taxe de séjour mobil-home',
        touristtaxNakedPlot: 'Taxe de séjour emplacement nu',
        touristtaxRealEstate: 'Taxe de séjour immobilier',
        commissionPercent: 'Taux de commission',
        disabledAccessible: 'Accès PMR',
        petAllowed: 'Animaux autorisés',
        partyAllowed: 'Fêtes autorisés',
        smokingAllowed: 'Fumeurs autorisés',
        fleet: {
          list: 'Liste des Flottes',
          addFleet: 'Créer une flotte',
        },
      },
      filters: {
        location: 'Destination',
        theme: 'Thème',
      },
      sections: {
        taxes: 'Taxes et commissions',
        location: 'Localisation',
        rules: 'Réglement intérieur',
        arrivalTimeRange: "Créneau d'arrivée",
        departureTimeRange: 'Créneau de depart',
        openingDays: "Périodes d'ouverture",
        themes: 'Thèmes',
        pictures: 'Photos',
        facilities: 'Aménagements',
        address: 'Adresse',
        infos: 'Informations pratiques',
      },
      tabs: {
        infos: 'Informations pratiques',
        fleet: "Flottes d'hébergements",
      },
    },
    Country: {
      name: 'Pays',
      fields: {
        name: 'Nom du pays',
        code: 'Code du pays',
        acceptsIban: 'IBAN acceptés',
      },
    },
    Ranking: {
      name: 'Gammes',
      fields: {
        translatable: {
          fr: 'Nom Français',
          en: 'Nom Anglais',
        },
      },
    },
    Theme: {
      name: 'Thèmes',
      fields: {
        name: 'Nom',
        localizedName: 'Nom',
        searchable: 'Disponible à la recherche',
      },
    },
    AccommodationEquipment: {
      name: 'Équipements',
      subTitle:
        'Vous pouvez classer ces équipements par ordre de priorité grâce à un simple « Drag & Drop »',
      fields: {
        name: 'Nom',
        localizedName: 'Nom',
        priority: 'Priorité',
        searchable: 'Disponible à la recherche',
        category: 'Catégorie',
      },
      categories: {
        standard: 'Standard',
        kitchen: 'Cuisine',
        room: 'Chambre',
        outdoor: 'Extérieur',
      },
    },
    Location: {
      name: 'Destinations',
      singular: 'Destination',
      fields: {
        localizedName: 'Nom',
        picture: 'Photos',
        lat: 'Latitude',
        lng: 'Longitude',
        name: 'Nom',
        searchRadiusKilometers: 'Rayon de recherche en Km',
        geoJson: 'Fichier JSON geoJson',
        priority: 'À la une',
        featured: 'Mettre à la une',
        category: 'Catégorie',
      },
      categories: {
        city: 'Ville',
        country: 'Pays',
        region: 'Région',
        departement: 'Département',
        custom: 'Zone touristique',
      },
      notifications: {
        bulkFeatured: 'Destinations mises à la une',
        bulkFeaturedError: "Erreur lors de l'enregistrement des destinations",
      },
    },
    ParkOffer: {
      name: 'Offres',
      fields: {
        percentage: 'Remise',
        priorityNb: 'Ordre de priorité',
        priority: 'À la une',
        featured: 'Mettre à la une',
        park: {
          name: 'Nom du camping',
        },
      },
      notifications: {
        bulkFeatured: 'Offres mises à la une',
        bulkFeaturedError: "Erreur lors de l'enregistrement des offres",
      },
    },
    Stay: {
      name: 'Séjours',
      fields: {
        people: {
          editPerson: 'Modifier un vacancier',
          addPerson: 'Ajouter un vacancier',
          list: 'Liste des vacanciers',
          age: 'Age du vacancier',
          fullName: 'Nom du vacancier',
        },
        tenant: {
          name: 'Nom du locataire',
        },
        startAt: 'Début du séjour',
        endAt: 'Fin du séjour',
        createdAt: 'Date de réservation',
        user: {
          person: {
            name: 'Utilisateur',
          },
        },
        accommodation: {
          name: 'Hébergement',
          park: {
            name: 'Nom du camping',
          },
        },
        park: {
          name: 'Camping',
        },
        period: {
          start: "Date d'arrivée",
          end: 'Date de départ',
        },
        commissionPercent: 'Pourcentage',
      },
      filters: {
        archivedAt: 'Séjours archivés',
        staysSelect: {
          title: 'Status',
          futurAndCurrent: 'À venir ou en cours',
          futur: 'À venir',
          past: 'Passés',
          current: 'En cours',
        },
        park: 'Camping',
        accommodation: 'Hébergement',
        createdAt: 'Date de création',
        startAt: 'Date de début',
      },
      statuses: {
        OPTION: {
          title: 'Option',
          description: '',
        },
        WAITING: {
          title: 'En attente',
          description: '',
        },
        PARTIAL_PAYMENT: {
          title: 'Paiement partiel',
          description: '',
        },
        PLANNED_PAYMENT: {
          title: 'Paiement plannifié',
          description: '',
        },
        PAYED: {
          title: 'Payé',
          description: '',
        },
        CANCELLED: {
          title: 'Annulé',
          description: '',
        },
      },
      tabs: {
        infos: 'Informations pratiques',
        dates: 'Dates',
        commissions: 'Commissions',
      },
      sections: {
        accommodation: 'Hébergement',
        users: 'Vacanciers',
        tenant: 'Séjour reservé par :',
        baseCommissions: 'Commission de base',
      },
    },
    Person: {
      name: 'Vacanciers',
      fields: {
        firstName: 'Prénom',
        lastName: 'Nom',
        birthdate: 'Date de naissance',
        postcode: 'Code postal',
        city: 'Ville',
        addressLine1: 'Adresse',
        addressLine2: "Complément d'adresse",
        country: 'Pays',
      },
    },
    User: {
      name: 'Vacanciers',
      fields: {
        fullname: 'Nom du vacancier',
        createdAt: 'Date de création',
        newPassword: 'Nouveau mot de passe',
        person: {
          firstName: 'Prénom',
          lastName: 'Nom',
          email: 'Email',
          phone: 'Téléphone',
          birthdate: 'Date de naissance',
          city: 'Ville',
          country: 'Pays',
          addressLine1: 'Adresse',
          addressLine2: "Complément d'adresse",
          postcode: 'Code postal',
        },
      },
      filters: {
        createdAt: 'Date de création',
        stay: {
          park: 'Camping',
          accommodation: 'Hébergement',
          startAt: 'Date de début',
        },
        staySection: 'Recherche par séjour',
      },
      sections: {
        informations: 'Informations pratiques',
      },
      emptyTexts: {
        phone: 'Aucun numéro de téléphone renseigné',
        email: 'Aucun email renseigné',
        city: 'Aucune ville renseignée',
        country: 'Aucun pays renseigné',
        addressLine1: 'Aucune adresse renseignée',
        addressLine2: "Aucun complément d'adresse renseigné",
        postcode: 'Aucun code postal renseigné',
      },
      cognitoStatus: {
        UNCONFIRMED: 'Non confirmé',
        CONFIRMED: 'Confirmé',
        ARCHIVED: 'Archivé',
        COMPROMISED: 'Compromis',
        UNKNOWN: 'Inconnu',
        RESET_REQUIRED: 'Réinitialisation requise',
        FORCE_CHANGE_PASSWORD: 'Changement de mot de passe requis',
        EXTERNAL_PROVIDER: 'Social connect',
      },
    },
    Owner: {
      actions: {
        setToMainPropertyManager: {
          buttonText: 'Gestionnaire principal',
          success: 'Gestionnaire principal modifié avec succès',
          fail: 'Erreur lors de la modification du gestionnaire principal',
        },
      },
      name: 'Propriétaires',
      fields: {
        ownerType: 'Type de propriétaire',
        company: {
          name: 'Nom',
          addressLine1: 'Adresse',
          addressLine2: "Complément d'adresse",
          postcode: 'Code postal',
          city: 'Ville',
          country: 'Pays',
          siren: 'Numéro SIREN',
        },
        person: {
          firstName: 'Prénom',
          lastName: 'Nom',
          birthdate: 'Date de naissance',
          postcode: 'Code postal',
          city: 'Ville',
          addressLine1: 'Adresse',
          addressLine2: "Complément d'adresse",
          country: 'Pays',
        },
        mainPropertyManager: {
          person: {
            firstName: 'Prénom',
            lastName: 'Nom',
          },
          preferredUsername: "Nom d'utilisateur",
          email: 'Email',
        },
        denomination: 'Dénomination',
      },
      sections: {
        createCompany: "Création d'une entreprise",
        createPrivate: "Création d'un propriétaire particulier",
        companyInformations: "Informations de l'entreprise",
        privateInformations: 'Informations du propriétaire particulier',
        propertyManagers: 'Gestionnaires',
        mainPropertyManager: 'Gestionnaire principal',
        ownerType: 'Type de propriétaire',
      },
      ownerTypes: {
        parkOwner: 'Propriétaire de camping',
        accommodationOwner: "Propriétaire d'hébergement",
      },
      category: 'Catégorie',
      categories: {
        company: 'Professionnel',
        private: 'Particulier',
      },
    },
    Company: {
      name: 'Entreprise',
      fields: {
        name: 'Nom',
        addressLine1: 'Adresse',
        addressLine2: "Complément d'adresse",
        postcode: 'Code postal',
        city: 'Ville',
        country: 'Pays',
        siren: 'Numéro SIREN',
      },
    },
    PropertyManager: {
      name: 'Gestionaires',
      fields: {
        preferredUsername: 'Identifiant',
        fullName: 'Nom',
        main: 'Gestionnaire principal',
        person: {
          firstName: 'Prénom',
          lastName: 'Nom',
          birthdate: 'Date de naissance',
          postcode: 'Code postal',
          city: 'Ville',
          addressLine1: 'Adresse',
          addressLine2: "Complément d'adresse",
          country: 'Pays',
        },
      },
      sections: {
        propertyManagers: 'Gestionnaires',
      },
    },
    Admin: {
      groups: 'Groupes',
      poolGroups: {
        SuperAdmin: 'SuperAdministrateur',
        Admin_commercial: 'Commercial',
        Admin_customerservice: 'Service Client',
        Admin_developper: 'Developpeur',
        PropertyManager: 'Gestionnaire Externe',
        PropertyManager_park: 'Gestionnaire de camping',
        PropertyManager_accommodation: "Gestionnaire d'hébergement",
      },
      name: 'Administrateurs',
      fields: {
        name: "Nom de l'utilisateur",
        email: 'Email',
        preferredUsername: 'Identifiant',
        username: "Nom d'utilisateur",
        phone: 'Téléphone',
        group: 'Groupe',
        picture: 'Avatar',
      },
    },
    AccommodationType: {
      name: "Types d'hébergements",
      fields: {
        description: 'Description',
        picture: 'Photos',
        name: 'Nom',
        localizedName: 'Nom ',
      },
    },
    FavoriteAccommodation: {
      name: 'Hébergements Favoris',
      fields: {
        accommodation: {
          name: 'Hébergement',
          park: {
            name: 'Nom du camping',
          },
          accommodationType: {
            name: {
              fr: "Type d'hébergement",
            },
          },
        },
        name: {
          fr: 'Nom Français',
          en: 'Nom Anglais',
        },
        priority: 'Ordre de prorité',
      },
    },
    File: {
      name: 'Fichiers',
      fields: {
        storage: 'Fichier',
        createdAt: 'Date de création',
      },
    },
    ParkFacility: {
      name: 'Aménagements',
      fields: {
        localizedName: 'Nom',
        name: 'Nom',
        svg: 'Icône',
      },
      icons: {
        swimingPool: 'Piscine',
        wifi: 'Wifi',
      },
    },
    Fleet: {
      name: "Flotte d'hébergements",
      fields: {
        name: 'Nom de la flotte',
        startAt: 'Date de début',
        endAt: 'Date de fin',
        defaultPrice: 'Prix de la nuitée',
        rotationDays: 'Jours de rotation (Départ / Arrivée)',
        defaultMinimumDelay: 'Durée minimal avant le debut du séjour',
        defaultMinimumLength: "Durée minimal d'un séjour",
      },
      addAccommodation: 'Ajouter un hébergement',
      tabs: {
        infos: 'Informations pratiques',
        availabilities: 'Disponibilités',
      },
      sections: {
        defaultValues: 'Valeurs par défaut de la flotte',
      },
    },

    customers: {
      name: 'Client |||| Clients',
      fields: {
        address: 'Rue',
        birthday: 'Anniversaire',
        city: 'Ville',
        stateAbbr: 'Etat',
        commands: 'Commandes',
        first_name: 'Prénom',
        first_seen: 'Première visite',
        groups: 'Segments',
        has_newsletter: 'Abonné à la newsletter',
        has_ordered: 'A commandé',
        last_name: 'Nom',
        last_seen: 'Vu le',
        last_seen_gte: 'Vu depuis',
        latest_purchase: 'Dernier achat',
        name: 'Nom',
        total_spent: 'Dépenses',
        zipcode: 'Code postal',
        password: 'Mot de passe',
        confirm_password: 'Confirmez le mot de passe',
      },
      filters: {
        last_visited: 'Dernière visite',
        today: "Aujourd'hui",
        this_week: 'Cette semaine',
        last_week: 'La semaine dernière',
        this_month: 'Ce mois-ci',
        last_month: 'Le mois dernier',
        earlier: 'Plus tôt',
        has_ordered: 'A déjà commandé',
        has_newsletter: 'Abonné newsletter',
        group: 'Segment',
      },
      fieldGroups: {
        identity: 'Identité',
        address: 'Adresse',
        stats: 'Statistiques',
        history: 'Historique',
        password: 'Mot de passe',
        change_password: 'Changer le mot de passe',
      },
      page: {
        delete: 'Supprimer le client',
      },
      errors: {
        password_mismatch: 'La confirmation du mot de passe est différent du mot de passe.',
      },
    },
    commands: {
      name: 'Commande |||| Commandes',
      amount: '1 commande |||| %{smart_count} commandes',
      title: 'Commande n°%{reference}',
      fields: {
        basket: {
          delivery: 'Frais de livraison',
          reference: 'Référence',
          quantity: 'Quantité',
          sum: 'Sous-total',
          tax_rate: 'TVA',
          taxes: 'TVA',
          total: 'Total',
          unit_price: 'P.U.',
        },
        address: 'Adresse',
        customer_id: 'Client',
        date_gte: 'Emises depuis',
        date_lte: 'Emises avant',
        nb_items: 'Nb articles',
        reference: 'Référence',
        returned: 'Annulée',
        status: 'Etat',
        total_gte: 'Montant minimum',
      },
      section: {
        order: 'Commande',
        customer: 'Client',
        shipping_address: 'Adresse de livraison',
        items: 'Articles',
        total: 'Total',
      },
    },
    invoices: {
      name: 'Facture |||| Factures',
      fields: {
        id: 'Numéro',
        date: 'Date de facture',
        customer_id: 'Client',
        command_id: 'Commande',
        date_gte: 'Emises depuis',
        date_lte: 'Emises avant',
        address: 'Adresse',
        total_ex_taxes: 'Montant HT',
        delivery_fees: 'Frais de livraison',
        taxes: 'TVA',
      },
    },
    InvoiceLine: {
      fields: {
        wording: 'Libéllé',
        allTaxesIncludedPrice: 'Montant TTC',
        commissionAmount: 'Montant TTC',
      },
    },
    products: {
      name: 'Poster |||| Posters',
      fields: {
        category_id: 'Catégorie',
        height_gte: 'Hauteur mini',
        height_lte: 'Hauteur maxi',
        height: 'Hauteur',
        image: 'Photo',
        price: 'Prix',
        reference: 'Référence',
        sales: 'Ventes',
        stock_lte: 'Stock faible',
        stock: 'Stock',
        thumbnail: 'Aperçu',
        width_gte: 'Largeur mini',
        width_lte: 'Margeur maxi',
        width: 'Largeur',
      },
      tabs: {
        image: 'Image',
        details: 'Détails',
        description: 'Description',
        reviews: 'Commentaires',
      },
      filters: {
        categories: 'Catégories',
        stock: 'Stock',
        no_stock: 'En rupture',
        low_stock: '1 - 9 unités',
        average_stock: '10 - 49 unités',
        enough_stock: '50 unités et plus',
        sales: 'Ventes',
        best_sellers: 'Meilleures ventes',
        average_sellers: 'Moyennes',
        low_sellers: 'Peu vendu',
        never_sold: 'Jamais vendu',
      },
    },
    categories: {
      name: 'Catégorie |||| Catégories',
      fields: {
        name: 'Nom',
        products: 'Produits',
      },
    },
    reviews: {
      name: 'Modération |||| Commentaires',
      amount: '1 commentaire |||| %{smart_count} commentaires',
      relative_to_poster: 'Commentaire sur',
      detail: 'Détail du commentaire',
      fields: {
        customer_id: 'Client',
        command_id: 'Commande',
        product_id: 'Produit',
        date_gte: 'Publié depuis',
        date_lte: 'Publié avant',
        date: 'Date',
        comment: 'Texte',
        status: 'Statut',
        rating: 'Classement',
      },
      action: {
        accept: 'Accepter',
        reject: 'Rejeter',
      },
      notification: {
        approved_success: 'Commentaire approuvé',
        approved_error: 'Erreur: Commentaire non approuvé',
        rejected_success: 'Commentaire rejeté',
        rejected_error: 'Erreur: Commentaire non rejeté',
      },
    },
  },
};

export default customFrenchMessages;
