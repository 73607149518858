import { Visibility, VisibilityOff } from '@material-ui/icons';
import {
  BulkActionProps,
  Button,
  useNotify,
  useRefresh,
  useTranslate,
  useUnselectAll,
  useUpdateMany,
} from 'react-admin';

interface BulkFeaturedButtonProps extends BulkActionProps {
  value: boolean;
  label: string;
}

const BulkFeatureButton = ({
  resource = 'Location',
  selectedIds = [],
  label,
  value,
}: BulkFeaturedButtonProps) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const translate = useTranslate();
  const [updateMany, { loading }] = useUpdateMany(
    resource,
    selectedIds,
    { priority: value ? 1000 : null },
    {
      onSuccess: () => {
        refresh();
        notify(translate('resources.Location.notifications.bulkfFeatured'));
        unselectAll(resource);
      },
      onFailure: (error) => {
        console.error(error);
        notify(translate('resources.Location.notifications.bulkfFeaturedError'), 'warning');
      },
    }
  );

  return (
    <Button label={label} disabled={loading} onClick={updateMany}>
      {value ? <Visibility /> : <VisibilityOff />}
    </Button>
  );
};

export default BulkFeatureButton;
