import { Reducer } from 'redux';

import { CHANGE_THEME, changeTheme } from '../components/pages/configuration/actions';
import { ThemeName } from '../types/layout.types';

type State = ThemeName;
type Action = ReturnType<typeof changeTheme> | { type: 'OTHER_ACTION'; payload?: any };

const initialState: State = localStorage.getItem('theme') === 'dark' ? 'dark' : 'light';

const themeReducer: Reducer<State, Action> = (previousState = initialState, action) => {
  if (action.type === CHANGE_THEME) {
    localStorage.setItem('theme', action.payload);
    return action.payload;
  }
  return previousState;
};

export default themeReducer;
