import FloatNumberInput from 'components/CustomInput/FloatNumberInput';
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  CreateProps,
  Record,
  RecordMap,
  ReferenceInput,
  required,
  SimpleForm,
  useTranslate,
} from 'react-admin';

export const ParkOffersCreate = (props: CreateProps) => {
  const translate = useTranslate();
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput
          label={translate('resources.ParkOffer.fields.park.name')}
          source="parkId"
          reference="Park"
          filter={{ withoutFavorite: true }}
        >
          <AutocompleteInput
            validate={[required()]}
            resettable={true}
            optionText={(record: RecordMap<Record>) => `${record.name} - ${record.postcode}`}
          />
        </ReferenceInput>
        <FloatNumberInput
          source="percentage"
          label={translate('resources.ParkOffer.fields.percentage')}
          endAdornment="%"
        />
        <BooleanInput source="featured" />
      </SimpleForm>
    </Create>
  );
};
