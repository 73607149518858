import { FormLabel } from '@material-ui/core';
import isMobile from 'ismobilejs';
import { useEffect, useState } from 'react';
import { InputProps, useInput } from 'react-admin';

import RotationDaysForm from './RotationDaysForm';
import getDefaultRotationDays from './utils/getDefaultRotationDays';

export const DefaultRotationDaysSelection = ({ source, req, label, ...props }: InputProps) => {
  const {
    input: { value, onChange },
  } = useInput({ source });

  const userAgent = req?.headers['user-agent'];
  const mobile = isMobile(userAgent).phone;

  const [rotationDays, setRotationDays] = useState(value || getDefaultRotationDays());
  useEffect(() => {
    if (value) setRotationDays(value);
  }, [value]);
  const handleChangeRotationDays = (day: string) => () => {
    onChange({
      ...rotationDays,
      [day]: !rotationDays[day],
    });
    setRotationDays({ ...rotationDays, [day]: !rotationDays[day] });
  };

  return (
    <div className={props.className}>
      <FormLabel style={{ marginBottom: '10px' }}>{label}</FormLabel>
      <RotationDaysForm
        onChangeRotationDays={handleChangeRotationDays}
        rotationDays={rotationDays}
        style={{ display: 'flex', justifyContent: 'center' }}
        row={!mobile}
      />
    </div>
  );
};
