import {
  AutocompleteInput,
  Create,
  CreateProps,
  Record,
  RecordMap,
  ReferenceInput,
  required,
  SimpleForm,
  useTranslate,
} from 'react-admin';

export const FavoriteAccommodationCreate = (props: CreateProps) => {
  const translate = useTranslate();
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput
          label={translate('resources.FavoriteAccommodation.fields.accommodation.name')}
          source="accommodationId"
          reference="Accommodation"
          filter={{ withoutFavorite: true }}
        >
          <AutocompleteInput
            validate={[required()]}
            resettable={true}
            optionText={(record: RecordMap<Record>) => `${record.name} - ${record.park.name}`}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
