import { makeStyles } from '@material-ui/core/styles';
import { translationLocales } from 'i18n';
import React from 'react';
import {
  BooleanInput,
  Edit,
  EditProps,
  required,
  SimpleForm,
  TextInput,
  TranslatableInputs,
} from 'react-admin';

import { styles } from './index';

const useStyles = makeStyles(styles);

export const ThemeEdit = (props: EditProps) => {
  const classes = useStyles(styles);
  return (
    <Edit {...props}>
      <SimpleForm>
        <TranslatableInputs locales={translationLocales}>
          <TextInput
            source="localizedName"
            validate={[required()]}
            formClassName={classes.textInput}
          />
        </TranslatableInputs>
        <BooleanInput source="searchable" />
      </SimpleForm>
    </Edit>
  );
};
