import get from 'lodash/get';
import { Values, ValuesWithResource } from 'types/customizableDatagrid.types';

const STORAGE_KEY = 'raColumnsConfig';

const getRootValue = (): ValuesWithResource | undefined => {
  try {
    return JSON.parse(window.localStorage.getItem(STORAGE_KEY) || '');
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const setRootValue = (values: ValuesWithResource) => {
  try {
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(values));
  } catch (e) {
    console.error(e);
  }
};

const LocalStorage = {
  get(key: string) {
    return get(getRootValue(), key);
  },
  set(key: string, values: Values) {
    const oldValue = getRootValue();
    setRootValue({
      ...oldValue,
      [key]: values,
    });
  },
};

export default LocalStorage;
