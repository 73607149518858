import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { CircularProgress, makeStyles } from '@material-ui/core';
import CalendarPeriodForm from 'components/Calendar/CalendarPeriodForm';
import { CalendarToolbar } from 'components/Calendar/CalendarToolbar';
import styles from 'components/Calendar/style';
import { getFleetColor } from 'components/Calendar/utils/colors';
import getDefaultRotationDays from 'components/Calendar/utils/getDefaultRotationDays';
import { getPeriodsObject } from 'components/Calendar/utils/getPeriods';
import isDisableDay from 'components/Calendar/utils/isDisableDay';
import {
  overlappingValidation,
  parkOpeningDaysValidation,
} from 'components/Calendar/utils/isValidPeriod';
import { format } from 'date-fns';
import * as locales from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { InputProps, useGetOne, useInput, useNotify } from 'react-admin';
import { DateRange, RangeFocus } from 'react-date-range';
import { Period, PeriodsWithKey } from 'types/calendar.types';

const useStyles = makeStyles(styles);

const FleetCalendar = ({ source, record }: InputProps) => {
  const classes = useStyles(styles);
  const notify = useNotify();
  const { parkId } = record;
  const defaultPrice = record.defaultPrice || 100;
  const defaultArrivalDays = record.defaultArrivalDays || getDefaultRotationDays();
  const defaultDepartureDays = record.defaultDepartureDays || getDefaultRotationDays();
  const defaultMinimumDelay = record.defaultMinimumDelay || 1;
  const defaultMinimumLength = record.defaultMinimumLength || 1;
  const {
    input: { value, onChange },
  } = useInput({ source });
  const { data, loaded: getParkLoaded } = useGetOne('Park', parkId);
  const [loading, setLoading] = useState<boolean>(true);
  const [periods, setPeriods] = useState<PeriodsWithKey>({});
  const [focusedRange, setFocusedRange] = useState<RangeFocus | undefined>([0, 0]);
  const [monthsToDisplay, setMonthsToDisplay] = useState<number>(1);
  const parkOpeningPeriods = data?.openingDays;

  useEffect(() => {
    if (loading && getParkLoaded && value) {
      setLoading(false);
      initPeriods();
    }
  }, [getParkLoaded, value]);

  useEffect(() => {
    const resizeElement = document.querySelector('.edit-page') as HTMLElement;
    function resize() {
      const width = resizeElement.offsetWidth - 180;
      const months = width ? Math.floor(width / 250) : 1;
      if (monthsToDisplay && months) setMonthsToDisplay(months);
    }
    if (ResizeObserver) new ResizeObserver(resize).observe(resizeElement);
    resize();
  }, []);

  const initPeriods = () => {
    const periodObject = getPeriodsObject(
      value,
      defaultArrivalDays,
      defaultDepartureDays,
      defaultMinimumDelay,
      defaultMinimumLength,
      defaultPrice
    );
    Object.values(periodObject).forEach((period: Period) => {
      const notifyError = parkOpeningDaysValidation(period, parkOpeningPeriods);
      if (notifyError) notify(...notifyError);
    });
    setPeriods(periodObject);
  };

  const handleFocusedRange = (newFocusedRange: RangeFocus) => {
    return newFocusedRange[1] === 1
      ? setFocusedRange(newFocusedRange)
      : setFocusedRange([focusedRange?.[0] || 0, 0]);
  };

  const handleChangeFormData = (savedPeriods: PeriodsWithKey) => {
    return onChange(
      Object.values(savedPeriods).map(
        ({
          startDate,
          endDate,
          price,
          arrivalDays,
          departureDays,
          minimumLength,
          minimumDelay,
        }: Period) => ({
          startAt: format(startDate, 'yyyy-MM-dd'),
          endAt: format(endDate, 'yyyy-MM-dd'),
          price,
          arrivalDays,
          departureDays,
          minimumLength,
          minimumDelay,
        })
      )
    );
  };

  // Modification de startAt et endAt d'une période
  const handleChangeDatePeriod = (item: PeriodsWithKey) => {
    const itemKey: string = Object.keys(item)[0];
    const period: Period = item[itemKey];
    const otherPeriods: PeriodsWithKey = { ...periods };
    delete otherPeriods[period.key];
    const notifyError = overlappingValidation(otherPeriods, period);
    if (notifyError) {
      notify(...notifyError);
    } else {
      // Ajout de la nouvelle période aux périodes définies
      if (period.key === 'newPeriod') {
        period.key = `selection${Number(Object.keys(periods).length) - 1}`;
      }
      updatePeriods(period);
    }
  };

  // Ajout d'une nouvelle période, la clé newPeriod permet de ne pas être considérée comme une période définie
  const handleAddNewPeriod = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const newIndex = Number(Object.keys(periods).length);
    updatePeriods();
    setFocusedRange([newIndex, 0]);
  };

  const updatePeriods = (
    {
      startDate,
      endDate,
      key,
      price,
      arrivalDays,
      departureDays,
      minimumDelay,
      minimumLength,
      isAvailable,
    } = {
      startDate: new Date(),
      endDate: new Date(),
      key: 'newPeriod',
      price: defaultPrice,
      arrivalDays: defaultArrivalDays,
      departureDays: defaultDepartureDays,
      minimumDelay: defaultMinimumDelay,
      minimumLength: defaultMinimumLength,
      isAvailable: true,
    }
  ) => {
    const notNewPeriod = key !== 'newPeriod';
    const updatedPeriods = {
      ...periods,
      [key]: {
        key,
        startDate,
        endDate,
        price,
        arrivalDays,
        departureDays,
        minimumDelay,
        minimumLength,
        isAvailable,
        ...getFleetColor(Number(key.split('selection')[1])),
      },
    };
    // Suppression de la période temporaire lors de l'ajout d'une période
    if (notNewPeriod && updatedPeriods.newPeriod) delete updatedPeriods.newPeriod;
    setPeriods(updatedPeriods);
    if (notNewPeriod) handleChangeFormData(updatedPeriods);
  };

  // Suppression d'une période
  const deletePeriod = (e: React.MouseEvent<HTMLButtonElement>, period: Period) => {
    e.preventDefault();
    const lastKey = Object.keys(periods).pop();
    delete periods[period.key];
    setPeriods(periods);
    handleChangeFormData(periods);
    if (period.key === lastKey) setFocusedRange([0, 0]);
  };

  if (loading) {
    return (
      <div className={classes.spinnerDiv}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <CalendarToolbar
        isFleet
        disabled={'newPeriod' in periods}
        handleAddNewPeriod={handleAddNewPeriod}
      />
      <div className={classes.calendar}>
        <div className={classes.periods}>
          {Object.values(periods).map((period, index) => (
            <CalendarPeriodForm
              key={period.key}
              index={index}
              period={period}
              focusedRange={focusedRange}
              defaultArrivalDays={defaultArrivalDays}
              defaultDepartureDays={defaultDepartureDays}
              defaultMinimumDelay={defaultMinimumDelay}
              defaultMinimumLength={defaultMinimumLength}
              setFocusedRange={setFocusedRange}
              deletePeriod={deletePeriod}
              updatePeriods={updatePeriods}
            />
          ))}
        </div>
        <DateRange
          disabledDay={(date: Date) => isDisableDay(date, parkOpeningPeriods)}
          showDateDisplay={false}
          months={monthsToDisplay}
          className={classes.dateRangePicker}
          locale={locales.fr}
          onChange={(item: any) => handleChangeDatePeriod(item)}
          ranges={Object.values(periods)}
          focusedRange={focusedRange}
          onRangeFocusChange={handleFocusedRange}
          dateDisplayFormat={'dd MM yyyy'}
          fixedHeight={true}
          showPreview={!!focusedRange}
          dragSelectionEnabled={!!focusedRange}
          direction="horizontal"
        />
      </div>
    </>
  );
};

export default FleetCalendar;
