import { Tab, Tabs } from '@material-ui/core';
import * as React from 'react';
import { useCallback } from 'react';
import { FilterProps, useGetList, useTranslate } from 'react-admin';

interface Props {
  filterValues: any;
  setFilters: (arg0: any, arg1: any) => void;
  displayedFilters: any;
}

const useGetTotals = (filterValues: FilterProps) => {
  const { total: totalPending } = useGetList(
    'ParkApplication',
    { perPage: 10, page: 1 },
    { field: 'id', order: 'ASC' },
    { ...filterValues, status: 'pending' }
  );
  const { total: totalAccepted } = useGetList(
    'ParkApplication',
    { perPage: 10, page: 1 },
    { field: 'id', order: 'ASC' },
    { ...filterValues, status: 'accepted' }
  );
  const { total: totalRejected } = useGetList(
    'ParkApplication',
    { perPage: 10, page: 1 },
    { field: 'id', order: 'ASC' },
    { ...filterValues, status: 'rejected' }
  );
  return {
    pending: totalPending,
    accepted: totalAccepted,
    rejected: totalRejected,
  };
};

const ReviewTabs = ({ filterValues, setFilters, displayedFilters }: Props) => {
  const translate = useTranslate();
  const totals = useGetTotals(filterValues) as any;

  const handleChange = useCallback(
    (_event: React.ChangeEvent<Record<string, never>>, value: any) => {
      setFilters({ ...filterValues, status: value }, displayedFilters);
    },
    [displayedFilters, filterValues, setFilters]
  );

  const tabs = [
    { id: 'pending', name: translate('pos.tabs.reviews.pending') },
    { id: 'accepted', name: translate('pos.tabs.reviews.accepted') },
    { id: 'rejected', name: translate('pos.tabs.reviews.rejected') },
  ];
  return (
    <Tabs
      variant="fullWidth"
      centered
      value={filterValues.status}
      indicatorColor="primary"
      onChange={handleChange}
    >
      {tabs.map((choice) => (
        <Tab
          key={choice.id}
          label={totals[choice.id] ? `${choice.name} (${totals[choice.id]})` : choice.name}
          value={choice.id}
        />
      ))}
    </Tabs>
  );
};

export default ReviewTabs;
