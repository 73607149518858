import { makeStyles, TextField, Theme } from '@material-ui/core';
import { Styles } from '@material-ui/styles';
import { ChangeEventHandler, KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { InputProps, Labeled, useInput, useTranslate } from 'react-admin';

const styles: Styles<Theme, any> = () => ({
  birthdateForm: {
    width: '226px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  birthdateInputs: {
    maxWidth: '30%',

    '& .MuiFilledInput-root': {
      height: '48px',
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  error: {
    color: '#f44336',
    margin: 0,
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
  },
});

const useStyles = makeStyles(styles);

interface BirthdateInputProps extends InputProps {
  variant?: 'filled' | 'standard' | 'outlined' | undefined;
}

const BirthdateInput = ({ variant = 'standard', ...props }: BirthdateInputProps): JSX.Element => {
  const {
    input: { value, onChange },
    meta: { touched, error },
    isRequired,
  } = useInput(props);
  const translate = useTranslate();
  const classes = useStyles(styles);

  const [day, setDay] = useState(value ? new Date(value).getDate() : null);
  const [month, setMonth] = useState(value ? new Date(value).getMonth() + 1 : null);
  const [year, setYear] = useState(value ? new Date(value).getFullYear() : null);
  const [dateError, setDateError] = useState<string | null>(null);

  const dayRef = useRef<HTMLInputElement | null>(null);
  const monthRef = useRef<HTMLInputElement | null>(null);
  const yearRef = useRef<HTMLInputElement | null>(null);

  const handleDayChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newDay = e.currentTarget.value;
    setDay(Number(newDay));
    if (monthRef.current && newDay.length === 2) {
      monthRef.current.focus();
      monthRef.current.select();
    }
  };
  const handleMonthChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newMonth = e.currentTarget.value;
    setMonth(Number(newMonth));
    if (yearRef.current && newMonth.length === 2) {
      yearRef.current.focus();
      yearRef.current.select();
    }
  };
  const handleMonthKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (dayRef.current && e.key === 'Backspace' && e.currentTarget.value.length === 0) {
      e.preventDefault();
      dayRef.current.focus();
    }
  };
  const handleYearChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setYear(Number(e.currentTarget.value));
  const handleYearKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (monthRef.current && e.key === 'Backspace' && e.currentTarget.value.length === 0) {
      e.preventDefault();
      monthRef.current.focus();
    }
  };

  const makeDate = (): Date | null => {
    if (!year || !month || !day) {
      return null;
    }
    const testDate = new Date(`${year}-${month}-${day}`);
    if (!Number.isNaN(testDate.getTime())) {
      setDateError(null);
      return testDate;
    }
    setDateError('pos.validation.invalidDate');
    return null;
  };
  const updateDate = () => onChange(makeDate());

  useEffect(updateDate, [day, month, year]);

  const hasError = !!dateError || !!(touched && error);

  return (
    <>
      <Labeled label={props.label}>
        <div className={classes.birthdateForm}>
          <TextField
            onClick={() => dayRef.current?.select()}
            variant={variant}
            className={classes.birthdateInputs}
            placeholder={translate('pos.dateUnity.day')}
            value={day}
            onChange={handleDayChange}
            type="text"
            required={isRequired}
            error={hasError}
            inputRef={dayRef}
            inputProps={{
              minlength: 1,
              maxlength: 2,
            }}
          />
          <TextField
            onClick={() => monthRef.current?.select()}
            variant={variant}
            className={classes.birthdateInputs}
            placeholder={translate('pos.dateUnity.month')}
            value={month}
            onChange={handleMonthChange}
            type="text"
            required={isRequired}
            error={hasError}
            inputRef={monthRef}
            inputProps={{
              minlength: 1,
              maxlength: 2,
              onKeyDown: handleMonthKeyDown,
            }}
          />
          <TextField
            onClick={() => yearRef.current?.select()}
            variant={variant}
            className={classes.birthdateInputs}
            placeholder={translate('pos.dateUnity.year')}
            value={year}
            onChange={handleYearChange}
            type="text"
            required={isRequired}
            error={hasError}
            inputRef={yearRef}
            inputProps={{
              minlength: 4,
              maxlength: 4,
              onKeyDown: handleYearKeyDown,
            }}
          />
        </div>
      </Labeled>
      {touched && <p className={classes.error}>{translate(error)}</p>}
      {dateError && <p className={classes.error}>{translate(dateError)}</p>}
    </>
  );
};

export default BirthdateInput;
