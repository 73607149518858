import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { makeStyles, TextField, Theme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Styles } from '@material-ui/styles';
import { areIntervalsOverlapping, format } from 'date-fns';
import * as locales from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { Button, InputProps, ListToolbar, useInput, useNotify } from 'react-admin';
import { DateRange, RangeFocus } from 'react-date-range';

export interface Period {
  index: number;
  startDate: Date;
  endDate: Date;
  key: string;
  color?: string;
  colorIndex: number;
}

export interface PeriodsWithKey {
  [x: string]: Period;
}

export interface InputPeriod {
  startAt: string;
  endAt: string;
}

export interface InputPeriods {
  [key: string]: InputPeriod;
}

const styles: Styles<Theme, any> = () => ({
  calendar: {
    display: 'flex',
    flexDirection: 'column',
  },
  dateRangePicker: {
    '& .rdrMonthAndYearWrapper select': {
      fontSize: '20px',
    },
    '& .rdrMonths': {
      alignItems: 'center',
      justifyContent: 'space-evenly',
      flexDirection: 'row',
      '& .rdrMonth': {
        flexDirection: 'row',
        width: '250px',
      },
    },
  },
  periods: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',
  },
  periodInfos: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    margin: '5px 0',
  },
  dateTextField: {
    margin: '0 5px',

    '& .MuiInputBase-root': {
      height: '30px',

      '& .MuiInputBase-inputHiddenLabel': {
        textAlign: 'center',
        cursor: 'pointer',
      },
    },
  },
  notchedOutline: {},
  cssFocused: {},
  ...colors.reduce(
    (acc, color, index) => ({
      ...acc,
      [`color_${index}`]: {
        '&$cssFocused $notchedOutline': {
          borderColor: `${color} !important`,
        },
      },
    }),
    {}
  ),
});

const useStyles = makeStyles(styles);

const colors = ['#4FD398', '#4294FF', '#fa4747', '#009aa8'];

const getColor = (index: number) => {
  const colorIndex = index % colors.length;
  return { color: colors[colorIndex], colorIndex };
};

const convertArrayToObject = (inputValue: InputPeriod[]) => {
  const initialValue = {};
  return inputValue.reduce((periods: InputPeriods, period: InputPeriod, index: number) => {
    return {
      ...periods,
      [`selection${index}`]: {
        key: `selection${index}`,
        // Définition des heures afin d'éviter des probèmes de collision des périodes
        startDate: new Date(`${period.startAt}T00:00:00`),
        endDate: new Date(`${period.endAt}T00:00:00`),
        index,
        ...getColor(index),
      },
    };
  }, initialValue);
};

const ParkCalendar = ({ source }: InputProps) => {
  const classes = useStyles(styles);
  const notify = useNotify();
  const {
    input: { value, onChange },
  } = useInput({ source });

  const [periods, setPeriods] = useState<PeriodsWithKey>(convertArrayToObject(value));
  const [focusedRange, setFocusedRange] = useState<RangeFocus>([0, 0]);
  const [monthsToDisplay, setMonthsToDisplay] = useState<number>(1);

  useEffect(() => {
    const resizeElement = document.querySelector('.edit-page') as HTMLElement;
    function resize() {
      const width = resizeElement.offsetWidth - 180;
      const months = width ? Math.floor(width / 250) : 1;
      if (monthsToDisplay && months) setMonthsToDisplay(months);
    }
    if (ResizeObserver) new ResizeObserver(resize).observe(resizeElement);
    resize();
  }, []);

  const handleChangeFormData = (savedPeriods: PeriodsWithKey) => {
    return onChange(
      Object.values(savedPeriods).map((p: Period) => ({
        startAt: format(p.startDate, 'yyyy-MM-dd'),
        endAt: format(p.endDate, 'yyyy-MM-dd'),
      }))
    );
  };

  // Verification de collisions entre les periodes
  const getValidationError = (checkedPeriods: PeriodsWithKey, changePeriod: Period) => {
    let error = false;
    const arrayCheckedPeriods = Object.values(checkedPeriods);
    arrayCheckedPeriods.forEach((period) => {
      if (
        // Renvoie true si changePeriod entre en collision avec l'une des périodes de checkedPeriods
        areIntervalsOverlapping(
          { start: changePeriod.startDate, end: changePeriod?.endDate },
          { start: period.startDate, end: period.endDate },
          { inclusive: true }
        )
      ) {
        notify(
          'pos.notifications.datePickerOverlappingError',
          'error',
          {
            changePeriodStart: format(changePeriod.startDate, 'dd/MM/yyyy'),
            changePeriodEnd: format(changePeriod.endDate, 'dd/MM/yyyy'),
            periodStart: format(period.startDate, 'dd/MM/yyyy'),
            periodEnd: format(period.endDate, 'dd/MM/yyyy'),
          },
          false,
          100000
        );
        error = true;
      }
      return null;
    });
    return error;
  };

  // Modification de startAt et endAt d'une période
  const handleChangeDatePeriod = (item: PeriodsWithKey) => {
    const period = item[Object.keys(item)[0]];
    const otherPeriods = { ...periods };
    delete otherPeriods[period.key];
    const validationError = getValidationError(otherPeriods, period);
    if (validationError) return null;
    // Ajout de nouvelle période aux périodes définies
    const newPeriods =
      period.key === 'newPeriod'
        ? {
            ...otherPeriods,
            [`selection${Object.keys(periods).length - 1}`]: {
              ...period,
              key: `selection${Object.keys(periods).length - 1}`,
            },
          }
        : { ...periods, ...item };
    setPeriods(newPeriods);
    const savedPeriods = { ...newPeriods };
    if (savedPeriods.newPeriod) delete savedPeriods.newPeriod;
    return handleChangeFormData(savedPeriods);
  };

  // Ajout d'une nouvelle période, la clé newPeriod permet de ne pas être considérée comme une période définie
  const handleAddNewPeriod = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setPeriods({
      ...periods,
      newPeriod: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'newPeriod',
        ...getColor(Object.keys(periods).length),
        index: Object.keys(periods).length,
      },
    });
    setFocusedRange([Object.keys(periods).length, 0]);
  };

  // Suppression d'une période
  const deletePeriod = (e: React.MouseEvent<HTMLButtonElement>, period: Period) => {
    e.preventDefault();
    delete periods[period.key];
    setPeriods(periods);
    handleChangeFormData(periods);
    return setFocusedRange([Object.keys(periods).length - 1, 0]);
  };

  return (
    <>
      <ListToolbar
        actions={
          <div>
            <Button
              onClick={(e) => handleAddNewPeriod(e)}
              label="pos.addPeriod"
              style={{ marginRight: '20px' }}
              disabled={'newPeriod' in periods}
            >
              <AddIcon />
            </Button>
          </div>
        }
      />
      <div className={classes.calendar}>
        <div className={classes.periods}>
          {Object.values(periods).map((period) => (
            <div className={classes.periodInfos} key={period.key}>
              <TextField
                className={classes.dateTextField}
                variant="outlined"
                required
                hiddenLabel={true}
                value={format(period.startDate, 'dd MMM yyyy')}
                onClick={() => setFocusedRange([period.index, 0])}
                focused={period.index === focusedRange[0] && focusedRange[1] === 0}
                InputProps={{
                  readOnly: true,
                  classes: {
                    root: classes[`color_${period.colorIndex}`],
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
              <TextField
                className={classes.dateTextField}
                variant="outlined"
                required
                hiddenLabel={true}
                value={format(period.endDate, 'dd MMM yyyy')}
                onClick={() => setFocusedRange([period.index, 1])}
                focused={period.index === focusedRange[0] && focusedRange[1] === 1}
                InputProps={{
                  readOnly: true,
                  classes: {
                    root: classes[`color_${period.colorIndex}`],
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
              <Button
                color="primary"
                size="small"
                style={{ height: '30px' }}
                onClick={(e) => deletePeriod(e, period)}
              >
                <DeleteIcon />
              </Button>
            </div>
          ))}
        </div>
        <DateRange
          showDateDisplay={false}
          months={monthsToDisplay}
          className={classes.dateRangePicker}
          locale={locales.fr}
          onChange={(item: any) => handleChangeDatePeriod(item)}
          ranges={Object.values(periods)}
          focusedRange={focusedRange}
          onRangeFocusChange={(newFocusedRange) => setFocusedRange(newFocusedRange)}
          dateDisplayFormat={'dd MM yyyy'}
          fixedHeight={true}
          direction="horizontal"
        />
      </div>
    </>
  );
};

export default ParkCalendar;
