import { InputAdornment } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import { getMaxDiscountChoices, getVintageChoices } from 'components/Utils/getSelectChoices';
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  CreateProps,
  NumberInput,
  Record,
  RecordMap,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin';

import { SectionTitle, Separator } from '../../formUtils';

export const styles: Styles<Theme, any> = (theme) => ({
  inlineInfo: {
    [theme.breakpoints.up('md')]: { display: 'inline-block', marginRight: '15px' },
  },
  petAllowed: { [theme.breakpoints.up('md')]: { display: 'inline-block' } },
  maxDiscount: { [theme.breakpoints.up('md')]: { display: 'inline-block' } },
  terrace: {
    [theme.breakpoints.up('sm')]: { width: '355px' },
  },
});

const useStyles = makeStyles(styles);

export const AccommodationCreate = (props: CreateProps) => {
  const translate = useTranslate();
  const classes = useStyles(styles);
  return (
    <Create {...props}>
      <SimpleForm>
        <SectionTitle label={translate('resources.Accommodation.sections.infos')} />
        <TextInput source="name" validate={[required()]} formClassName={classes.inlineInfo} />
        <ReferenceInput source="park.id" reference="Park" formClassName={classes.inlineInfo}>
          <AutocompleteInput
            validate={[required()]}
            resettable={true}
            optionText={(record: RecordMap<Record>) => `${record.name} ${record.postcode}`}
          />
        </ReferenceInput>
        <ReferenceInput
          source="accommodationType.id"
          reference="AccommodationType"
          formClassName={classes.inlineInfo}
        >
          <AutocompleteInput
            validate={[required()]}
            resettable={true}
            optionText={(record: RecordMap<Record>) => `${record.name}`}
          />
        </ReferenceInput>
        <SelectInput
          source="vintage"
          choices={getVintageChoices(50)}
          translateChoice={false}
          formClassName={classes.inlineInfo}
        />
        <Separator />
        <SectionTitle label={translate('resources.Accommodation.sections.accommodation')} />
        <NumberInput
          source="maximumPerson"
          validate={[required()]}
          formClassName={classes.inlineInfo}
        />
        <NumberInput source="bedroom" validate={[required()]} formClassName={classes.inlineInfo} />
        <NumberInput source="bathroom" validate={[required()]} formClassName={classes.inlineInfo} />
        <NumberInput source="toilet" validate={[required()]} formClassName={classes.inlineInfo} />
        <NumberInput
          source="surface"
          validate={[required()]}
          formClassName={classes.inlineInfo}
          InputProps={{
            startAdornment: <InputAdornment position="start">m²</InputAdornment>,
          }}
        />
        <BooleanInput
          source="disabledAccessible"
          formClassName={classes.inlineInfo}
          defaultValue={false}
        />
        <BooleanInput source="petAllowed" formClassName={classes.petAllowed} defaultValue={false} />
        <BooleanInput source="terrace" formClassName={classes.terrace} defaultValue={false} />
        <SectionTitle label={translate('resources.Accommodation.fields.maxDiscount')} />
        <SelectInput
          source="maxDiscount"
          choices={getMaxDiscountChoices()}
          validate={[required()]}
          formClassName={classes.maxDiscount}
        />
      </SimpleForm>
    </Create>
  );
};
