import { Actions } from 'dataProvider';
import { useQuery, useTranslate } from 'react-admin';

interface Group {
  id: string;
  name: string;
}

const getPoolGroups = (): Group[] => {
  const translate = useTranslate();
  const { data } = useQuery({
    type: Actions.ALL_ADMIN_POOL_GROUPS,
    resource: 'Admin',
    payload: {},
  });

  if (!data) return [];
  return data
    .filter((group: string) => group.includes('Admin'))
    .map((group: string) => ({
      id: group,
      name: translate(`resources.Admin.poolGroups.${group.replace('-', '')}`),
    }));
};

export default getPoolGroups;
