import Button from '@material-ui/core/Button';
import ThumbDown from '@material-ui/icons/ThumbDown';
import PropTypes from 'prop-types';
import * as React from 'react';
import { FC } from 'react';
import { useMutation, useNotify, useRedirect, useRefresh } from 'react-admin';

import { Actions } from '../../dataProvider';
import { ParkApplication } from '../../types/parkApplication.types';

const RejectButton: FC<{ record: ParkApplication }> = ({ record }) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const refresh = useRefresh();
  const options = {
    onSuccess: () => {
      notify('Candidature rejetée avec succès');
      redirectTo('/ParkApplication');
      refresh();
    },
    onFailure: () => {
      notify('Erreur lors du rejet de la candidature', 'warning');
    },
  };

  const [reject, { loading: rejectMutationLoading }] = useMutation(
    {
      type: Actions.REJECT_APPLICATION_REVIEW,
      resource: 'ParkApplication',
      payload: { id: record.id },
    },
    options
  );

  return record && !record.reviewedAt ? (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      style={{ margin: '0.5em' }}
      onClick={reject}
      disabled={rejectMutationLoading}
    >
      <ThumbDown color="primary" style={{ paddingRight: '0.5em', color: 'red' }} />
      Rejeter
    </Button>
  ) : (
    <span />
  );
};

RejectButton.propTypes = {
  record: PropTypes.any,
};

export default RejectButton;
