import { AmplifyImageInput } from 'components/CustomInput/AmplifyImageInput';
import { translationLocales } from 'i18n';
import {
  BooleanInput,
  Create,
  CreateProps,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  TranslatableInputs,
  useTranslate,
} from 'react-admin';

import { getCategoryChoices } from './index';

export const LocationCreate = (props: CreateProps) => {
  const translate = useTranslate();
  const categoryChoices = getCategoryChoices(translate);
  return (
    <Create {...props}>
      <SimpleForm>
        <TranslatableInputs locales={translationLocales}>
          <TextInput source="localizedName" validate={[required()]} />
        </TranslatableInputs>
        <SelectInput source="category" choices={categoryChoices} validate={[required()]} />
        <TextInput source="lat" validate={[required()]} />
        <TextInput source="lng" validate={[required()]} />
        <AmplifyImageInput source="picture" accept="image/*" />
        <BooleanInput source="featured" />
      </SimpleForm>
    </Create>
  );
};
