import { FC } from 'react';
import { FieldProps } from 'react-admin';

import { ParkApplication } from '../../types/parkApplication.types';

const AddressField: FC<FieldProps<ParkApplication>> = ({ record }) =>
  record ? (
    <span>
      {record.line1}, {record.postcode} {record.city}
    </span>
  ) : null;

AddressField.defaultProps = {
  label: 'Adresse',
  addLabel: true,
};

export default AddressField;
