import polyglotI18nProvider from 'ra-i18n-polyglot';

import englishMessages from './en';
import frenchMessages from './fr';

const storedLocale = localStorage.getItem('locale');

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale !== storedLocale) localStorage.setItem('locale', locale);
  if (locale === 'en') return englishMessages;

  // Always fallback on french
  return frenchMessages;
}, storedLocale || 'fr');

export const translationLocales = ['fr', 'en'];

export default i18nProvider;
