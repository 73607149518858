import getPoolGroups from 'components/Utils/getPoolGroups';
import {
  Create,
  CreateProps,
  required,
  SelectArrayInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

export const AdminCreate = (props: CreateProps) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={[required()]} />
        <TextInput source="email" validate={[required()]} />
        <SelectArrayInput source="groups" choices={getPoolGroups()} />
        <TextInput source="picture" />
      </SimpleForm>
    </Create>
  );
};
