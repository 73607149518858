import { makeStyles, Theme } from '@material-ui/core';
import { Styles } from '@material-ui/styles';
import BirthdateInput from 'components/CustomInput/BirthdateInput';
import PhoneNumberInput from 'components/CustomInput/PhoneNumberInput';
import { Separator } from 'components/formUtils';
import {
  DeleteButton,
  Edit,
  EditProps,
  Record,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin';

import { PasswordInputModal } from '../../CustomInput/PasswordInputModal';

const styles: Styles<Theme, any> = (theme) => ({
  inlineInfo: {
    [theme.breakpoints.up('md')]: { display: 'inline-block', marginRight: '15px' },
  },
  toolbar: {
    justifyContent: 'space-between',
  },
});

const useStyles = makeStyles(styles);

const CustomToolbar = (props: ToolbarProps) => {
  const classes = useStyles(styles);

  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton disabled={props.pristine} />
      <DeleteButton mutationMode="pessimistic" />
    </Toolbar>
  );
};

export const UserEdit = (props: EditProps) => {
  const classes = useStyles(styles);
  const translate = useTranslate();
  return (
    <Edit {...props}>
      <SimpleForm
        initialValues={(record: Record) => ({ username: record.username })}
        toolbar={<CustomToolbar />}
      >
        <TextInput
          source="person.firstName"
          validate={required()}
          formClassName={classes.inlineInfo}
        />
        <TextInput
          source="person.lastName"
          validate={required()}
          formClassName={classes.inlineInfo}
        />
        <Separator />
        <TextInput source="person.email" formClassName={classes.inlineInfo} />
        <PhoneNumberInput source="person.phone" formClassName={classes.inlineInfo} />
        <Separator />
        <TextInput source="person.addressLine1" formClassName={classes.inlineInfo} />
        <TextInput source="person.addressLine2" formClassName={classes.inlineInfo} />
        <TextInput source="person.city" formClassName={classes.inlineInfo} />
        <TextInput source="person.postcode" formClassName={classes.inlineInfo} />
        <TextInput source="person.country" formClassName={classes.inlineInfo} />
        <Separator />
        <BirthdateInput
          source="person.birthdate"
          label={translate('resources.User.fields.person.birthdate')}
        />
        <PasswordInputModal source="newPassword" />
      </SimpleForm>
    </Edit>
  );
};
