import PublishedStatusField from 'components/CustomFields/PublishedStatusField';
import { Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin';

import { SectionTitle, Separator } from '../../formUtils/index';
import OwnerAccountStatusField from './fields/OwnerAccountStatusField';
import ParkOfferStatusField from './fields/ParkOfferStatusField';
import StayPeriodStatusField from './fields/StayPeriodStatusField';

export const AccommodationShow = (props: ShowProps) => (
  <Show {...props}>
    <SimpleShowLayout>
      <SectionTitle label="Informations pratiques" />
      <PublishedStatusField source="publishedAt" />
      <OwnerAccountStatusField />
      <ParkOfferStatusField />
      <StayPeriodStatusField source="currentStay" />
      <TextField source="name" />
      <TextField source="surface" />
      <TextField source="mark" />
      <TextField source="model" />
      <TextField source="maximumPerson" />
      <TextField source="bedroom" />
      <TextField source="bathroom" />
      <TextField source="toilet" />
      <TextField source="deposit" />
      <TextField source="parking" />
      <TextField source="terrace" />
      <TextField source="terraceSurface" />
      <TextField source="terraceCovered" />
      <TextField source="terraceClosed" />
      <Separator />
    </SimpleShowLayout>
  </Show>
);
