import get from 'lodash/get';
import { FC } from 'react';
import { FieldProps } from 'react-admin';

const PercentNumberField: FC<FieldProps> = ({ record, source }) => {
  const percentage = get(record, source || '');
  if (!percentage || typeof percentage !== 'number') return null;
  return <span>{percentage}%</span>;
};

export default PercentNumberField;
