import { eachYearOfInterval, getYear, subYears } from 'date-fns';

export const getVintageChoices = (limit: number) => {
  const eachYears = eachYearOfInterval({ start: subYears(new Date(), limit), end: new Date() });
  return eachYears.reverse().map((day) => {
    const year = getYear(day);
    return { id: year, name: year };
  });
};

export const getMaxDiscountChoices = () => [
  { id: 0, name: '0%' },
  { id: 10, name: '10%' },
  { id: 15, name: '15%' },
];
