import { Storage } from '@aws-amplify/storage';
import { useEffect } from 'react';
import { useInput, useRefresh } from 'react-admin';
import { v4 as uuid } from 'uuid';

type OnDropAccepted = (files: File[], event: any) => void;
type OnRemove = (file: { key: string }) => void;

type Input = {
  source: string;
  resource?: string | undefined;
  inputProps?: any;
  multiple?: boolean;
  onDropAcceptedCallback?: OnDropAccepted;
  storageOptions?: any;
  path?: string;
};

type Output = {
  onDropAccepted: OnDropAccepted;
  onRemove: OnRemove;
};

export function useStorageInput({
  source,
  resource,
  multiple = false,
  path = '',
  storageOptions = {},
  onDropAcceptedCallback,
}: Input): Output {
  const { input } = useInput({ source });
  const refresh = useRefresh();

  useEffect(() => {
    if (Array.isArray(input.value) && input.value.length === 0) {
      input.onChange(undefined);
    }
  }, [input]);

  async function onDropAccepted(files: File[], event: any) {
    try {
      if (!resource) return;
      const values = await Promise.all(
        files.map(async (file) => {
          const fileId = uuid().replace(/-/g, '');
          const result = await Storage.put(`${path}/${fileId}_${file.name}`, file, storageOptions);

          return {
            bucket: process.env.REACT_APP_PICTURE_STORAGE_BUCKET,
            region: process.env.REACT_APP_REGION,
            key: result.key,
          };
        })
      );

      // Await for resizing s3 event maybe add SNS topic to improve it
      await new Promise((resolve) => setTimeout(resolve, values.length * 2000));
      refresh();

      if (!multiple) {
        input.onChange(values[0]);
        return;
      }

      if (input.value) {
        input.onChange([...input.value, ...values]);
      } else {
        input.onChange(values);
      }
    } catch (e) {
      console.error(e);
      input.onChange(undefined);
    }

    if (onDropAcceptedCallback) {
      onDropAcceptedCallback(files, event);
    }
  }

  const onRemove = (file: { key: string }) => {
    Storage.remove(file.key);
  };

  return { onDropAccepted, onRemove };
}
