import { Tab, Tabs } from '@material-ui/core';
import { useCallback } from 'react';
import { useTranslate } from 'react-admin';

import { getCategoryChoices } from './index';

interface Props {
  tab: string;
  setTab: (tab: string) => void;
}

const AccommodationEquipmentTabs = ({ tab, setTab }: Props) => {
  const translate = useTranslate();
  const categoryChoices = getCategoryChoices(translate);
  const handleChange = useCallback(
    (_event: React.ChangeEvent<Record<string, never>>, value: string) => setTab(value),
    [setTab]
  );
  return (
    <Tabs variant="fullWidth" centered value={tab} indicatorColor="primary" onChange={handleChange}>
      {categoryChoices.map((choice) => (
        <Tab key={choice.id} label={choice.name} value={choice.id} />
      ))}
    </Tabs>
  );
};

export default AccommodationEquipmentTabs;
