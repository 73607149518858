import { Card as MuiCard, CardContent, InputAdornment } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PinDropIcon from '@material-ui/icons/PinDrop';
import DateRangeInput from 'components/CustomInput/DateRangeInput';
import IncrementalNumberInput from 'components/CustomInput/IncrementalNumberInput';
import { getMaxDiscountChoices, getVintageChoices } from 'components/Utils/getSelectChoices';
import {
  AutocompleteInput,
  Filter,
  Record,
  RecordMap,
  ReferenceInput,
  SearchInput,
  SelectInput,
  useTranslate,
} from 'react-admin';

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const AccommodationAsideFilter = () => {
  const translate = useTranslate();
  return (
    <Card>
      <CardContent>
        <Filter>
          <SearchInput source="q" alwaysOn />
          <ReferenceInput
            label={translate('resources.Accommodation.filters.park')}
            source="parkId"
            reference="Park"
            alwaysOn
          >
            <AutocompleteInput
              resettable={true}
              optionText={(record: RecordMap<Record>) =>
                record.id ? `${record.name} (${record.postcode})` : ''
              }
              options={{
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <PinDropIcon color="disabled" />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </ReferenceInput>
          <ReferenceInput
            source="ownerId"
            reference="Owner"
            label={translate('resources.Accommodation.filters.owner')}
            alwaysOn
          >
            <AutocompleteInput
              resettable={true}
              optionText={(record: RecordMap<Record>) => {
                if (!record.id) return '';
                if (record.company) return `${record?.company?.name}`;
                return `${record.person.firstName} ${record.person.lastName}`;
              }}
            />
          </ReferenceInput>
          <IncrementalNumberInput
            source="maximumPerson"
            maxValue={16}
            label={translate('resources.Accommodation.filters.maximumPerson')}
            alwaysOn
          />
          <DateRangeInput
            source="createdAt"
            buttonLabel={translate('resources.Accommodation.filters.createdAt')}
            toPast
            alwaysOn
          />
          <SelectInput
            source="maxDiscount"
            label={translate('resources.Accommodation.filters.maxDiscount')}
            choices={getMaxDiscountChoices()}
            alwaysOn
          />
          <SelectInput
            source="vintage"
            label={translate('resources.Accommodation.filters.vintage')}
            choices={getVintageChoices(50)}
            translateChoice={false}
            alwaysOn
          />
        </Filter>
      </CardContent>
    </Card>
  );
};

export default AccommodationAsideFilter;
