import FloatNumberInput from 'components/CustomInput/FloatNumberInput';
import { Edit, EditProps, required, SimpleForm, useTranslate } from 'react-admin';

export const ParkOffersEdit = (props: EditProps) => {
  const translate = useTranslate();
  return (
    <Edit {...props}>
      <SimpleForm>
        <FloatNumberInput
          validate={[required()]}
          source="percentage"
          label={translate('resources.ParkOffer.fields.percentage')}
          endAdornment="%"
        />
      </SimpleForm>
    </Edit>
  );
};
