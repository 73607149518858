import { makeStyles, Theme } from '@material-ui/core';
import { Styles } from '@material-ui/styles';
import { ChangeEvent } from 'react';
import { RotationDays, SettingsSelectionProps } from 'types/calendar.types';

import SettingsForm from '../SettingsForm';

const styles: Styles<Theme, any> = () => ({
  selectDaysContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  defaultDaysButton: {
    marginRight: '20px',
    fontSize: '14px',
  },
});

const useStyles = makeStyles(styles);

export const SettingsSelection = ({
  arrivalDays,
  departureDays,
  minimumDelay,
  minimumLength,
  setPeriodSettings,
}: SettingsSelectionProps) => {
  const classes = useStyles(styles);

  const handleChangeArrivalDays = (day: keyof RotationDays) => () => {
    setPeriodSettings({
      arrivalDays: { ...arrivalDays, [day]: !arrivalDays[day] },
      departureDays,
      minimumDelay,
      minimumLength,
    });
  };

  const handleChangeDepartureDays = (day: keyof RotationDays) => () => {
    setPeriodSettings({
      arrivalDays,
      departureDays: { ...departureDays, [day]: !departureDays[day] },
      minimumDelay,
      minimumLength,
    });
  };

  const handleChangeMinimumDelay = (event: ChangeEvent<HTMLInputElement>) => {
    setPeriodSettings({
      arrivalDays,
      departureDays,
      minimumDelay: Number(event.target.value),
      minimumLength,
    });
  };

  const handleChangeMinimumLength = (event: ChangeEvent<HTMLInputElement>) => {
    setPeriodSettings({
      arrivalDays,
      departureDays,
      minimumDelay,
      minimumLength: Number(event.target.value),
    });
  };

  return (
    <div className={classes.selectDaysContainer}>
      <SettingsForm
        arrivalDays={arrivalDays}
        departureDays={departureDays}
        minimumDelay={minimumDelay}
        minimumLength={minimumLength}
        onChangeArrivalDays={handleChangeArrivalDays}
        onChangeDepartureDays={handleChangeDepartureDays}
        onChangeMinimumDelay={handleChangeMinimumDelay}
        onChangeMinimumLength={handleChangeMinimumLength}
      />
    </div>
  );
};
