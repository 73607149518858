import { Card as MuiCard, CardContent, makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DateRangeInput from 'components/CustomInput/DateRangeInput';
import IncrementalNumberInput from 'components/CustomInput/IncrementalNumberInput';
import { SectionTitle } from 'components/formUtils';
import {
  AutocompleteInput,
  Filter,
  ReferenceInput,
  useListContext,
  useTranslate,
} from 'react-admin';

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const useStyles = makeStyles({
  title: {
    textAlign: 'center',
  },
});

const useFilterStyles = makeStyles({
  form: {
    paddingTop: '2rem',
  },
});

const SearchOfferAsideFilter = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const filterClasses = useFilterStyles();
  const { total, filterValues } = useListContext();

  const getTotalResult = () => {
    return (
      <SectionTitle
        className={classes.title}
        label={translate('pos.totalSearchResult', total || 0)}
      />
    );
  };

  return (
    <Card>
      <CardContent>
        {getTotalResult()}
        <Filter classes={filterClasses}>
          <DateRangeInput alwaysOn source="dateRange" />
          <ReferenceInput
            label={translate('resources.Park.singular')}
            source="parkId"
            reference="Park"
            alwaysOn
          >
            <AutocompleteInput
              resettable={true}
              optionText={(record: any) => (record.id ? `${record.name} - ${record.postcode}` : '')}
            />
          </ReferenceInput>
          <ReferenceInput
            label={translate('resources.Location.singular')}
            source="locationId"
            reference="Location"
            alwaysOn
            disabled={!!filterValues.parkId}
          >
            <AutocompleteInput
              resettable={true}
              optionText={(record: any) => (record.id ? `${record.name}` : '')}
            />
          </ReferenceInput>
          <IncrementalNumberInput
            alwaysOn
            source="minimumPerson"
            label={translate('resources.SearchOffer.filters.minimumPerson')}
            minValue={1}
            maxValue={30}
          />
        </Filter>
      </CardContent>
    </Card>
  );
};

export default SearchOfferAsideFilter;
