import { IconButton, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useEffect, useState } from 'react';
import { InputProps, useInput } from 'react-admin';

interface IncrementalNumberInputProps extends InputProps {
  minValue?: number;
  maxValue?: number;
  label: string;
}

const styles = () => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  incrementalContainer: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  labelContainer: {
    width: '50%',
  },
  label: {
    fontSize: '0.8rem',
    fontFamily: 'Roboto, sans-serif',
  },
});

const useStyles = makeStyles(styles);

const IncrementalNumberInput = ({
  minValue = 0,
  maxValue = 100,
  label,
  ...props
}: IncrementalNumberInputProps) => {
  const classes = useStyles(styles);
  const {
    input: { onChange, ...inputProps },
  } = useInput(props);
  const [value, setValue] = useState(inputProps.value || minValue);
  const [isInitializedValue, setIsInitializedValue] = useState<boolean>(false);

  useEffect(() => {
    if (!isInitializedValue) {
      if (value === minValue) return;
      setIsInitializedValue(true);
    }
    onChange(value);
  }, [value]);

  return (
    <div {...inputProps} className={classes.container}>
      <div className={classes.labelContainer}>
        <p className={classes.label}>{label}</p>
      </div>
      <div className={classes.incrementalContainer}>
        <IconButton
          disabled={value <= minValue}
          onClick={() => setValue(value - 1)}
          aria-label="decrement"
        >
          <RemoveIcon />
        </IconButton>
        <p>{value}</p>
        <IconButton
          disabled={value >= maxValue}
          onClick={() => setValue(value + 1)}
          aria-label="increment"
        >
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default IncrementalNumberInput;
