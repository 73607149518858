import { makeStyles } from '@material-ui/core';
import PictureField from 'components/CustomFields/PictureField';
import StarRatingField from 'components/CustomFields/StarRatingField';
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  ListProps,
  Loading,
  ReferenceField,
  ReferenceManyField,
  SimpleShowLayout,
  TextField,
  useListContext,
  useShowController,
  useTranslate,
} from 'react-admin';

import BookingButton from '../../Buttons/BookingButton';
import SearchAsideFilter from './SearchOfferAsideFilter';

const useStyles = makeStyles({
  emptyDataContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      fontWeight: 'bold',
      fontSize: '1.5rem',
    },
  },
  accommodationShow: {
    width: '45%',
    marginRight: 0,
  },
  parkShow: {
    paddingTop: 16,
    width: '45%',
    marginRight: 0,
  },
  showOfferContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  bookingButton: {
    paddingLeft: '10px',
  },
});

const SearchOfferExpand = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const accommodationController = useShowController({
    id: props.record.accommodationId,
    record: { id: props.record.accommodationId },
    basePath: `/Accommodation/${props.record.accommodationId}`,
    resource: 'Accommodation',
  });
  const parkController = useShowController({
    id: props.record.parkId,
    record: { id: props.record.parkId },
    basePath: `/Park/${props.record.parkId}`,
    resource: 'Park',
  });

  return (
    <div>
      <BookingButton record={props.record}></BookingButton>
      <div className={classes.showOfferContainer}>
        {/* Accommodation show fields */}
        <SimpleShowLayout {...accommodationController} className={classes.accommodationShow}>
          <h2>{translate('resources.Accommodation.singular')}</h2>
          <ReferenceManyField
            reference="AccommodationPicture"
            target="accommodationId"
            fullWidth
            label=""
          >
            <PictureField />
          </ReferenceManyField>
          <TextField source="name" />
          <TextField source="maximumPerson" />
          <TextField source="surface" />
          <TextField source="bedroom" />
          <TextField source="bathroom" />
          <TextField source="toilet" />
          <TextField source="parking" />
          <BooleanField source="terrace" />
        </SimpleShowLayout>
        {/* Park show fields */}
        <SimpleShowLayout {...parkController} className={classes.parkShow}>
          <h2>{translate('resources.Park.singular')}</h2>
          <ReferenceManyField reference="ParkPicture" target="parkId" fullWidth label="">
            <PictureField />
          </ReferenceManyField>
          <TextField source="name" />
          <StarRatingField source="ranking" />
          <TextField source="city" />
        </SimpleShowLayout>
      </div>
    </div>
  );
};

const SearchOfferDatagrid = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const { total, loading } = useListContext();

  if (loading) return <Loading />;

  if (!total) {
    return (
      <div className={classes.emptyDataContainer}>
        <p>Aucun résultat...</p>
      </div>
    );
  }

  return (
    <Datagrid expand={<SearchOfferExpand />}>
      <ReferenceField
        label={translate('resources.SearchOffer.fields.accommodation.name')}
        reference="Accommodation"
        source="accommodationId"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label={translate('resources.SearchOffer.fields.accommodation.park.name')}
        reference="Park"
        source="parkId"
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField source="startAt" />
      <DateField source="endAt" />
      <TextField source="price" />
    </Datagrid>
  );
};

export const SearchOfferList = (props: ListProps) => {
  return (
    <List
      {...props}
      empty={false}
      aside={<SearchAsideFilter />}
      bulkActionButtons={false}
      pagination={false}
      filterDefaultValues={{ minimumPerson: 1 }}
      exporter={false}
    >
      <SearchOfferDatagrid />
    </List>
  );
};
