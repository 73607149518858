import Button from '@material-ui/core/Button';
import ThumbUp from '@material-ui/icons/ThumbUp';
import PropTypes from 'prop-types';
import * as React from 'react';
import { FC } from 'react';
import { useMutation, useNotify, useRedirect, useRefresh } from 'react-admin';

import { Actions } from '../../dataProvider';
import { ParkApplication } from '../../types/parkApplication.types';

const AcceptButton: FC<{ record: ParkApplication }> = ({ record }) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const refresh = useRefresh();
  const options = {
    onSuccess: () => {
      notify('Candidature acceptée avec succès');
      redirectTo('/ParkApplication');
      refresh();
    },
    onFailure: () => {
      notify("Erreur lors de l'acceptation de la candidature", 'warning');
    },
  };

  const [accept, { loading: acceptMutationLoading }] = useMutation(
    {
      type: Actions.ACCEPT_APPLICATION_REVIEW,
      resource: 'ParkApplication',
      payload: { id: record.id },
    },
    options
  );

  return record && !record.reviewedAt ? (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      style={{ margin: '0.5em' }}
      onClick={accept}
      disabled={acceptMutationLoading}
    >
      <ThumbUp color="primary" style={{ paddingRight: '0.5em', color: 'green' }} />
      Accepter
    </Button>
  ) : (
    <span />
  );
};

AcceptButton.propTypes = {
  record: PropTypes.any,
};

export default AcceptButton;
