import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import StarsIcon from '@material-ui/icons/Stars';
import CheckIconField from 'components/CustomFields/CheckIconField';
import FullNameField from 'components/CustomFields/FullNameField';
import BirthdateInput from 'components/CustomInput/BirthdateInput';
import PhoneNumberInput from 'components/CustomInput/PhoneNumberInput';
import { SectionTitle, Separator } from 'components/formUtils';
import { Actions } from 'dataProvider';
import { useEffect } from 'react';
import {
  CreateButton,
  Datagrid,
  DeleteButton,
  Edit,
  EditProps,
  FunctionField,
  Identifier,
  InputProps,
  ListBase,
  ListToolbar,
  ReferenceField,
  ReferenceManyField,
  required,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput as RaTextInput,
  Toolbar,
  ToolbarProps,
  useEditController,
  useMutation,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { useDispatch } from 'react-redux';

import { defineOwnerId } from './actions';

interface SetToMainButtonProps {
  record?: { id: number; main: boolean };
}

const useStyles = makeStyles(() => ({
  spinnerDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  inlineInfos: {
    marginRight: '15px',
  },
}));

const CustomToolbar = (props: ToolbarProps) => {
  const classes = useStyles();

  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton disabled={props.pristine} />
      <DeleteButton mutationMode="pessimistic" />
    </Toolbar>
  );
};

export const OwnerEdit = (props: EditProps) => {
  const { record, loading } = useEditController(props);
  const translate = useTranslate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();

  useEffect(() => {
    dispatch(defineOwnerId(props.id || ''));
  }, [props.id]);

  if (loading) {
    return (
      <div className={classes.spinnerDiv}>
        <CircularProgress size="6rem" />
      </div>
    );
  }

  const TextInput = ({ source, ...rest }: InputProps) => {
    return (
      <RaTextInput
        {...rest}
        source={source}
        className={classes.inlineInfos}
        label={translate(`resources.Owner.fields.${source}`)}
      />
    );
  };

  const getFormToDisplay = () => {
    if (record?.company) {
      return (
        <>
          <SectionTitle label={translate('resources.Owner.sections.companyInformations')} />
          <FunctionField
            render={(owner: any) => translate(`resources.Owner.ownerTypes.${owner.ownerType}`)}
          />
          <Separator />
          <TextInput source="company.name" validate={[required()]} />
          <TextInput source="company.addressLine1" validate={[required()]} />
          <TextInput source="company.addressLine2" />
          <TextInput source="company.postcode" validate={[required()]} />
          <TextInput source="company.city" validate={[required()]} />
          <TextInput source="company.country" validate={[required()]} />
          <TextInput source="company.siren" validate={[required()]} />
          <PhoneNumberInput source="company.phone" className={classes.inlineInfos} />
        </>
      );
    }
    return (
      <>
        <SectionTitle label={translate('resources.Owner.sections.privateInformations')} />
        <FunctionField
          render={(owner: any) => translate(`resources.Owner.ownerTypes.${owner.ownerType}`)}
        />
        <Separator />
        <TextInput source="person.firstName" validate={[required()]} />
        <TextInput source="person.lastName" validate={[required()]} />
        <TextInput source="person.addressLine1" validate={[required()]} />
        <TextInput source="person.addressLine2" />
        <TextInput source="person.postcode" validate={[required()]} />
        <TextInput source="person.city" validate={[required()]} />
        <TextInput source="person.country" validate={[required()]} />
        <PhoneNumberInput source="person.phone" className={classes.inlineInfos} />
        <Separator />
        <BirthdateInput
          label={translate('resources.Person.fields.birthdate')}
          source="person.birthdate"
        />
      </>
    );
  };

  const SetToMainButton = ({ record: propertyManager }: SetToMainButtonProps) => {
    const refresh = useRefresh();
    const options = {
      onSuccess: () => {
        notify('resources.Owner.actions.setToMainPropertyManager.success');
        refresh();
      },
      onFailure: () => {
        notify('resources.Owner.actions.setToMainPropertyManager.fail', 'warning');
      },
    };

    const [setToMainPropertyManager, { loading: mutationLoading }] = useMutation(
      {
        type: Actions.SET_TO_MAIN_PROPERTY_MANAGER,
        resource: 'PropertyManager',
        payload: { id: propertyManager?.id, ownerId: record?.id },
      },
      options
    );
    const handleClick = (event: any) => {
      event.stopPropagation();
      setToMainPropertyManager();
    };

    if (propertyManager?.main) return null;

    return (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        style={{ float: 'right' }}
        onClick={handleClick}
        disabled={mutationLoading}
        startIcon={<StarsIcon />}
      >
        {translate('resources.Owner.actions.setToMainPropertyManager.buttonText')}
      </Button>
    );
  };

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        {getFormToDisplay()}
        <Separator />
        <SectionTitle label={translate('resources.Owner.sections.propertyManagers')} />
        <ListBase>
          <ListToolbar
            actions={
              <div>
                <CreateButton
                  record={{ id: props?.id as Identifier }}
                  basePath="/PropertyManager"
                  label="ra.action.create"
                />
              </div>
            }
          />
        </ListBase>
        <ReferenceManyField reference="PropertyManager" target="ownerId" fullWidth label="">
          <Datagrid rowClick="edit">
            <ReferenceField
              reference="Person"
              source="person.id"
              link={false}
              label="resources.PropertyManager.fields.fullName"
              sortable={false}
            >
              <FullNameField />
            </ReferenceField>
            <TextField source="email" sortable={false} />
            <CheckIconField source="main" sortable={false} />
            <SetToMainButton />
          </Datagrid>
        </ReferenceManyField>
      </SimpleForm>
    </Edit>
  );
};
