import { makeStyles } from '@material-ui/core';
import { SectionTitle } from 'components/formUtils';
import { ReactElement } from 'react';
import {
  Datagrid,
  DeleteButton,
  EditButton,
  Loading,
  ReferenceManyField,
  ReferenceManyFieldProps,
  useRefresh,
} from 'react-admin';
import { Route } from 'react-router';

import CreateButton from './CreateButton';
import CreateFormDialog from './CreateFormDialog';
import EditFormDialog from './EditFormDialog';

export const useDialogStyles = makeStyles({
  root: {
    minWidth: 400,
    maxWidth: 600,
    '& .MuiPaper-root': {
      border: 'none',
      margin: '0 auto',
    },
  },
});

interface CreateReferenceInput extends ReferenceManyFieldProps {
  editable?: boolean;
  deletable?: boolean;
  editDialogLabel?: string;
  children: ReactElement;
  resource: string;
  listLabel: string;
  createDialogLabel: string;
  renderReferenceForm: () => ReactElement;
  label?: string | undefined;
  basePath?: string;
}

const CreateReferenceInput = ({
  editable = false,
  deletable = false,
  children,
  resource,
  reference,
  label = undefined,
  renderReferenceForm,
  basePath,
  record,
  target,
}: CreateReferenceInput) => {
  const refresh = useRefresh();
  if (!record) return <Loading />;

  const modalProps = {
    resource,
    renderReferenceForm,
    redirectPath: `${basePath}/${record?.id}`,
    refresh,
    target,
  };
  const resourceBasePath = `${basePath}/${record.id}`;
  return (
    <>
      {label && <SectionTitle label={label} />}
      <CreateButton basePath={`${resourceBasePath}/${reference}`} />
      <ReferenceManyField reference={reference} target={target}>
        <Datagrid>
          {children.props.children ? children.props.children : children}
          {editable && <EditButton basePath={`${resourceBasePath}/edit${reference}`} />}
          {deletable && <DeleteButton label="" basePath={resourceBasePath} />}
        </Datagrid>
      </ReferenceManyField>
      <Route path={`${basePath}/:resourceId/${reference}/create`}>
        {({ match }) => (match?.isExact ? <CreateFormDialog {...modalProps} /> : null)}
      </Route>
      <Route path={`${basePath}/:resourceId/edit${reference}/:referenceId`}>
        {({ match }) =>
          match?.isExact ? <EditFormDialog id={match.params.referenceId} {...modalProps} /> : null
        }
      </Route>
    </>
  );
};

export default CreateReferenceInput;
