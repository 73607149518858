import { areIntervalsOverlapping } from 'date-fns';
import { ParkOpeningPeriod, ParkOpeningPeriods } from 'types/calendar.types';

// Verification si le jour entre bien dans l'une des périodes d'ouverture du camping
const isDisableDay = (date: Date, parkOpeningPeriods: ParkOpeningPeriods) => {
  let isDisable = true;
  if (!parkOpeningPeriods) return false;
  if (parkOpeningPeriods.length < 1) return true;
  parkOpeningPeriods.map((period: ParkOpeningPeriod) => {
    if (
      areIntervalsOverlapping(
        {
          start: new Date(`${period.startAt}T00:00:00`),
          end: new Date(`${period.endAt}T00:00:00`),
        },
        { start: date, end: date },
        { inclusive: true }
      )
    )
      isDisable = false;
    return null;
  });
  return isDisable;
};

export default isDisableDay;
