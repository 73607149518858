import { Alert, AlertTitle } from '@material-ui/lab';
import { format, isBefore, isWithinInterval } from 'date-fns';
import isAfter from 'date-fns/isAfter';
import { get } from 'lodash';
import { FieldProps, useTranslate } from 'react-admin';

interface OpeningDay {
  startAt: Date;
  endAt: Date;
}

interface Period {
  startAt: Date;
  endAt: Date;
}

interface OpeningDaysAlertProps {
  period?: Period;
}

const NoDateAlert = () => {
  const translate = useTranslate();
  return (
    <Alert severity="error">
      <AlertTitle>{translate(`resources.Park.statuses.openingDays.noDate.title`)}</AlertTitle>
      {translate(`resources.Park.statuses.openingDays.noDate.description`)}
    </Alert>
  );
};

const CLosedSinceAlert = ({ period }: OpeningDaysAlertProps) => {
  const translate = useTranslate();

  if (!period) return <NoDateAlert />;
  return (
    <Alert severity="error">
      <AlertTitle>{translate(`resources.Park.statuses.openingDays.noNewDate.title`)}</AlertTitle>
      {translate(`resources.Park.statuses.openingDays.noNewDate.description`, {
        endAt: format(period.endAt, 'dd/MM/yyyy'),
      })}
    </Alert>
  );
};

const OpenedSinceAlert = ({ period }: OpeningDaysAlertProps) => {
  const translate = useTranslate();
  if (!period) return <NoDateAlert />;
  return (
    <Alert severity="success">
      <AlertTitle>{translate(`resources.Park.statuses.openingDays.open.title`)}</AlertTitle>
      {translate(`resources.Park.statuses.openingDays.open.description`, {
        startAt: format(period.startAt, 'dd/MM/yyyy'),
        endAt: format(period.endAt, 'dd/MM/yyyy'),
      })}
    </Alert>
  );
};

const OpenedAtAlert = ({ period }: OpeningDaysAlertProps) => {
  const translate = useTranslate();
  if (!period) return <NoDateAlert />;
  return (
    <Alert severity="error">
      <AlertTitle>{translate(`resources.Park.statuses.openingDays.openAt.title`)}</AlertTitle>
      {translate(`resources.Park.statuses.openingDays.openAt.description`, {
        startAt: format(period.startAt, 'dd/MM/yyyy'),
      })}
    </Alert>
  );
};

export const OpeningDaysStatusField = ({ record, source }: FieldProps) => {
  const openingDays: OpeningDay[] = source ? get(record, source) : null;
  const today = new Date(Date.now());
  const renderStatus = () => {
    if (openingDays.length === 0) return <NoDateAlert />;
    const periods = openingDays.map((period) => ({
      startAt: new Date(period.startAt),
      endAt: new Date(period.endAt),
    }));
    const sortedPeriods = periods.sort((firstDate: any, secondDate: any) =>
      firstDate.startAt > secondDate.startAt ? 1 : -1
    );

    const lowerPeriods: Period[] = sortedPeriods.filter((period) => isBefore(period.endAt, today));
    const upperPeriods: Period[] = sortedPeriods.filter((period) => isAfter(period.endAt, today));
    if (upperPeriods.length === 0) {
      return <CLosedSinceAlert period={lowerPeriods.pop()} />;
    }
    const nextPeriod = upperPeriods[0];
    const isOpenedNow = isWithinInterval(today, {
      start: nextPeriod.startAt,
      end: nextPeriod.endAt,
    });
    if (isOpenedNow) return <OpenedSinceAlert period={nextPeriod} />;
    return <OpenedAtAlert period={nextPeriod} />;
  };

  return <div style={{ margin: '10px 0' }}>{renderStatus()}</div>;
};
