import { TextField } from '@material-ui/core';
import { ChangeEvent } from 'react';
import { InputProps, useInput } from 'react-admin';
import NumberFormat from 'react-number-format';

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  unsigned: boolean;
}

export function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      decimalScale={0}
      isNumericString
      allowNegative={!props.unsigned}
      allowLeadingZeros={false}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
}

interface IntergerInputProps extends InputProps {
  unsigned?: boolean;
}

const IntegerInput = ({ unsigned = true, ...props }: IntergerInputProps) => {
  const { label, className } = props;
  const {
    input: { onChange, value, ...inputProps },
    meta: { touched, error },
    isRequired,
  } = useInput(props);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(parseInt(event.target.value, 10));
  };

  return (
    <TextField
      {...inputProps}
      value={value || 0}
      variant="filled"
      className={className}
      label={label}
      error={!!(touched && error)}
      onChange={handleChange}
      helperText={touched && error}
      required={isRequired}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
        inputProps: {
          unsigned,
        },
      }}
    />
  );
};

export default IntegerInput;
