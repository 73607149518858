import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import { Record } from 'react-admin';

import { StayStatus } from '.';

const rowStyle = (record: Record) => {
  const style = {
    borderLeftColor: yellow[500],
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
  };
  if (!record) return style;
  if (
    record.status === StayStatus.Waiting ||
    record.status === StayStatus.PlannedPayment ||
    record.status === StayStatus.PartialPayment
  ) {
    return { ...style, borderLeftColor: orange[500] };
  }
  if (record.status === StayStatus.Payed) {
    return { ...style, borderLeftColor: green[500] };
  }
  if (record.status === StayStatus.Option) {
    return { ...style, borderLeftColor: blue[500] };
  }
  if (record.status === StayStatus.Cancelled) {
    return { ...style, borderLeftColor: red[500] };
  }
  return style;
};

export default rowStyle;
