import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import { translationLocales } from 'i18n';
import React from 'react';
import {
  BooleanInput,
  Create,
  CreateProps,
  required,
  SimpleForm,
  TextInput,
  TranslatableInputs,
} from 'react-admin';

export const styles: Styles<Theme, any> = (theme) => ({
  textInput: { [theme.breakpoints.up('md')]: { width: '40%', display: 'inline-block' } },
});

const useStyles = makeStyles(styles);

export const ThemeCreate = (props: CreateProps) => {
  const classes = useStyles(styles);
  return (
    <Create {...props}>
      <SimpleForm>
        <TranslatableInputs locales={translationLocales}>
          <TextInput
            source="localizedName"
            validate={[required()]}
            formClassName={classes.textInput}
          />
        </TranslatableInputs>
        <BooleanInput source="searchable" defaultValue={false} />
      </SimpleForm>
    </Create>
  );
};
