import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { filter, get, isEmpty } from 'lodash';
import { Children, cloneElement, ReactElement, ReactNode, useState } from 'react';
import { Datagrid } from 'react-admin';
import {
  Column,
  ColumnsSelection,
  CustomizableDatagridProps,
} from 'types/customizableDatagrid.types';

import LocalStorage from './LocalStorage';
import SelectionDialog from './SelectionDialog';

const useStyles = makeStyles({
  button: {
    top: '5px',
    right: '5px',
    zIndex: 1000,
    position: 'absolute',
  },
});

const arrayToSelection = (values: string[]) =>
  values.reduce((selection: ColumnsSelection, columnName: string) => {
    selection[columnName] = true;
    return selection;
  }, {});

const CustomizableDatagrid = ({
  defaultColumns,
  resource,
  children,
  storage,
  ...props
}: CustomizableDatagridProps) => {
  const classes = useStyles();
  const [modalOpened, setModalOpened] = useState(false);
  const getColumnNames = (): string[] => {
    return filter(Children.map(children, (field) => get(field, ['props', 'source'])));
  };

  const getInitialSelection = () => {
    const previousSelection = storage.get(resource);

    // if we have a previously stored value, let's return it
    if (!isEmpty(previousSelection)) {
      return previousSelection;
    }

    // if defaultColumns are set let's return them
    if (!isEmpty(defaultColumns)) {
      return arrayToSelection(defaultColumns);
    }

    // otherwise we fallback on the default behaviour : display all columns
    return arrayToSelection(getColumnNames());
  };

  const [selection, setSelection] = useState(getInitialSelection());

  const handleOpen = () => setModalOpened(true);
  const handleClose = () => setModalOpened(false);

  const getColumnLabels = () => {
    return filter(
      Children.map(
        children,
        (field) =>
          field && {
            source: get(field, ['props', 'source']),
            label: get(field, ['props', 'label']),
          }
      ),
      (item) => item && item.source
    ) as Column[];
  };

  // updates the storage with the internal state value
  const updateStorage = (newSelection: ColumnsSelection) => {
    storage.set(resource, newSelection);
  };

  const toggleColumn = (columnName: string) => {
    const previousSelection = selection;
    const newSelection = {
      ...previousSelection,
      [columnName]: !previousSelection[columnName],
    };
    setSelection(newSelection);
    updateStorage(newSelection);
  };

  const renderChild = (child: ReactNode) => {
    const source = get(child, ['props', 'source']);
    // Render only children in selection or children without source (like edit or show button)
    if (!source || selection[source]) return cloneElement(child as ReactElement<any>, {});
    return null;
  };

  return (
    <>
      <IconButton size="small" className={classes.button} onClick={handleOpen}>
        {modalOpened ? (
          <MenuOpenIcon color="secondary" fontSize="small" />
        ) : (
          <MenuIcon color="secondary" fontSize="small" />
        )}
      </IconButton>
      {modalOpened && (
        <SelectionDialog
          resource={resource}
          selection={selection}
          columns={getColumnLabels()}
          onColumnClicked={toggleColumn}
          onClose={handleClose}
        />
      )}
      <Datagrid {...props}>{Children.map(children, renderChild)}</Datagrid>
    </>
  );
};

CustomizableDatagrid.defaultProps = {
  defaultColumns: [],
  storage: LocalStorage,
};

export default CustomizableDatagrid;
