import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { FieldProps } from 'react-admin';

const useStyles = makeStyles({
  root: {
    opacity: 0.87,
    whiteSpace: 'nowrap',
    display: 'flex',
  },
  largeCheck: {
    color: '#2AE700',
    width: 20,
    height: 20,
  },
  smallCheck: {
    color: '#2AE700',
    width: 15,
    height: 15,
  },
  largeCross: {
    color: '#E70000',
    width: 20,
    height: 20,
  },
  smallCross: {
    color: '#E70000',
    width: 15,
    height: 15,
  },
});

interface OwnProps {
  size?: 'large' | 'small';
  source?: string;
}

const CheckIconField = ({ record, size = 'large', source }: FieldProps & OwnProps) => {
  const classes = useStyles();

  return record && source ? (
    <span className={classes.root}>
      {record && record[source] === true ? (
        <CheckIcon
          key={source}
          className={size === 'large' ? classes.largeCheck : classes.smallCheck}
        />
      ) : (
        <ClearIcon
          key={source}
          className={size === 'large' ? classes.largeCross : classes.smallCross}
        />
      )}
    </span>
  ) : null;
};

export default CheckIconField;
