import { areIntervalsOverlapping, format } from 'date-fns';
import { NotificationType } from 'react-admin';
import { ParkOpeningPeriod, Period, PeriodsWithKey } from 'types/calendar.types';

type NotifyErrorArray =
  | [string, NotificationType | undefined, any, boolean | undefined, number | undefined]
  | undefined;

// Verification de collisions entre les periodes
export const overlappingValidation = (checkedPeriods: PeriodsWithKey, changePeriod: Period) => {
  let notifyError: NotifyErrorArray;
  const arrayCheckedPeriods = Object.values(checkedPeriods);
  arrayCheckedPeriods
    .filter((period) => period.isReadOnly !== true)
    .forEach((period) => {
      if (
        // Renvoie true si changePeriod entre en collision avec l'une des périodes de checkedPeriods
        areIntervalsOverlapping(
          { start: changePeriod.startDate, end: changePeriod?.endDate },
          { start: period.startDate, end: period.endDate }
        )
      ) {
        notifyError = [
          'pos.notifications.datePickerOverlappingError',
          'error',
          {
            changePeriodStart: format(changePeriod.startDate, 'dd/MM/yyyy'),
            changePeriodEnd: format(changePeriod.endDate, 'dd/MM/yyyy'),
            periodStart: format(period.startDate, 'dd/MM/yyyy'),
            periodEnd: format(period.endDate, 'dd/MM/yyyy'),
          },
          false,
          100000,
        ];
      }
    });
  return notifyError;
};

// Verification de périodes conformes aux periodes d'ouverture du park
export const parkOpeningDaysValidation = (
  changePeriod: Period,
  parkOpeningPeriods: ParkOpeningPeriod[]
) => {
  let notifyError: NotifyErrorArray;
  const changePeriodStartTime = new Date(changePeriod.startDate).getTime();
  const changePeriodEndTime = new Date(changePeriod.endDate).getTime();
  const isValid = parkOpeningPeriods.some((period: ParkOpeningPeriod) => {
    const parkStartTime = new Date(`${period.startAt}T00:00:00`).getTime();
    const parkEndTime = new Date(`${period.endAt}T00:00:00`).getTime();
    return (
      changePeriodStartTime >= parkStartTime &&
      changePeriodStartTime <= parkEndTime &&
      changePeriodEndTime >= parkStartTime &&
      changePeriodEndTime <= parkEndTime
    );
  });
  if (!isValid) {
    notifyError = [
      'pos.notifications.datePickerParkOpeningDaysError',
      'error',
      {
        changePeriodStart: format(changePeriod.startDate, 'dd/MM/yyyy'),
        changePeriodEnd: format(changePeriod.endDate, 'dd/MM/yyyy'),
      },
      false,
      100000,
    ];
  }
  return notifyError;
};
