import { makeStyles, TextField } from '@material-ui/core';
import { NumberFormatCustom } from 'components/CustomInput/IntegerInput';
import { Labeled, useTranslate } from 'react-admin';
import { SettingsFormProps } from 'types/calendar.types';

import RotationDaysForm from './RotationDaysForm';

const styles = {
  minimumValueContainer: {
    width: '100%',
    margin: '15px 0',
  },
  rotationDaysFormContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
};
const useStyles = makeStyles(styles);

const SettingsForm = ({
  arrivalDays,
  departureDays,
  minimumDelay,
  minimumLength,
  onChangeArrivalDays,
  onChangeDepartureDays,
  onChangeMinimumDelay,
  onChangeMinimumLength,
}: SettingsFormProps) => {
  const translate = useTranslate();
  const classes = useStyles(styles);
  return (
    <>
      <div className={classes.rotationDaysFormContainer}>
        <Labeled label={translate('pos.arrivalDays')}>
          <RotationDaysForm rotationDays={arrivalDays} onChangeRotationDays={onChangeArrivalDays} />
        </Labeled>
        <Labeled label={translate('pos.departureDays')}>
          <RotationDaysForm
            rotationDays={departureDays}
            onChangeRotationDays={onChangeDepartureDays}
          />
        </Labeled>
      </div>
      <TextField
        variant="filled"
        className={classes.minimumValueContainer}
        label={translate('pos.minimumDelay')}
        value={minimumDelay}
        onChange={onChangeMinimumDelay}
        InputProps={{
          inputComponent: NumberFormatCustom as any,
          inputProps: {
            unsigned: true,
          },
        }}
      />
      <TextField
        variant="filled"
        className={classes.minimumValueContainer}
        label={translate('pos.minimumLength')}
        value={minimumLength}
        onChange={onChangeMinimumLength}
        InputProps={{
          inputComponent: NumberFormatCustom as any,
          inputProps: {
            unsigned: true,
          },
        }}
      />
    </>
  );
};
export default SettingsForm;
