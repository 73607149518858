import { AmplifyImageInput } from 'components/CustomInput/AmplifyImageInput';
import { translationLocales } from 'i18n';
import { Edit, EditProps, required, SimpleForm, TextInput, TranslatableInputs } from 'react-admin';

export const AccommodationTypeEdit = (props: EditProps) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TranslatableInputs locales={translationLocales}>
          <TextInput source="localizedName" validate={[required()]} />
          <TextInput source="description" validate={[required()]} multiline />
        </TranslatableInputs>
        <AmplifyImageInput source="picture" accept="image/*" />
      </SimpleForm>
    </Edit>
  );
};
