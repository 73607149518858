import { FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { RotationDaysFormProps } from 'types/calendar.types';

const RotationDaysForm = ({
  rotationDays,
  onChangeRotationDays,
  style,
  row = false,
}: RotationDaysFormProps) => {
  const translate = useTranslate();

  return (
    <FormGroup row={row} style={style}>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={rotationDays.monday}
            onChange={onChangeRotationDays('monday')}
            name="Monday"
          />
        }
        label={translate('pos.days.monday')}
        labelPlacement={row ? 'top' : 'start'}
      />
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={rotationDays.tuesday}
            onChange={onChangeRotationDays('tuesday')}
            name="Tuesday"
          />
        }
        label={translate('pos.days.tuesday')}
        labelPlacement={row ? 'top' : 'start'}
      />
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={rotationDays.wednesday}
            onChange={onChangeRotationDays('wednesday')}
            name="Wednesday"
          />
        }
        label={translate('pos.days.wednesday')}
        labelPlacement={row ? 'top' : 'start'}
      />
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={rotationDays.thursday}
            onChange={onChangeRotationDays('thursday')}
            name="Thursday"
          />
        }
        label={translate('pos.days.thursday')}
        labelPlacement={row ? 'top' : 'start'}
      />
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={rotationDays.friday}
            onChange={onChangeRotationDays('friday')}
            name="Friday"
          />
        }
        label={translate('pos.days.friday')}
        labelPlacement={row ? 'top' : 'start'}
      />
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={rotationDays.saturday}
            onChange={onChangeRotationDays('saturday')}
            name="Saturday"
          />
        }
        label={translate('pos.days.saturday')}
        labelPlacement={row ? 'top' : 'start'}
      />
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={rotationDays.sunday}
            onChange={onChangeRotationDays('sunday')}
            name="Sunday"
          />
        }
        label={translate('pos.days.sunday')}
        labelPlacement={row ? 'top' : 'start'}
      />
    </FormGroup>
  );
};

export default RotationDaysForm;
