import { Divider } from '@material-ui/core';
import { Fragment, ReactElement, useState } from 'react';
import { Datagrid, List, ListProps, ReferenceField, TextField, useTranslate } from 'react-admin';

import SortableList from '../../DragAndDrop/SortableList';
import FavoriteAccommodationTabs from './FavoriteAccommodationTabs';

export const FavoriteAccommodationBaseList = (props: ListProps) => {
  const translate = useTranslate();
  return (
    <List {...props} sort={{ field: 'priority', order: 'ASC' }} perPage={25}>
      <Datagrid>
        <ReferenceField
          label={translate('resources.Accommodation.fields.name')}
          source="accommodation.id"
          reference="Accommodation"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label={translate('resources.FavoriteAccommodation.fields.accommodation.park.name')}
          source="accommodation.park.id"
          reference="Park"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export const FavoriteAccommodationSortableList = (props: ListProps) => {
  const translate = useTranslate();
  return (
    <SortableList orderedKey="priority" labelSource="accommodation.name" {...props}>
      <ReferenceField
        label={translate('resources.Accommodation.fields.name')}
        source="accommodation.id"
        reference="Accommodation"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label={translate('resources.FavoriteAccommodation.fields.accommodation.park.name')}
        source="accommodation.park.id"
        reference="Park"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
    </SortableList>
  );
};

export const FavoriteAccommodationList = (props: ListProps): ReactElement => {
  const [tab, setTab] = useState('base');
  return (
    <Fragment>
      <FavoriteAccommodationTabs tab={tab} setTab={setTab} />
      <Divider />
      {tab === 'base' ? (
        <FavoriteAccommodationBaseList {...props} />
      ) : (
        <FavoriteAccommodationSortableList {...props} />
      )}
    </Fragment>
  );
};
