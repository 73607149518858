import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import { FC } from 'react';

export const styles: Styles<Theme, any> = (theme) => ({
  logo: {
    [theme.breakpoints.down('sm')]: { display: 'none' },
  },
});

const useStyles = makeStyles(styles);

const Logo: FC = (): JSX.Element => {
  const classes = useStyles(styles);
  return <img src="/images/logoWithText.svg" className={classes.logo} alt="Izicamp" width="130" />;
};

export default Logo;
