import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';

export default function publish(
  type: string,
  resource: string,
  { resourceId }: Record<string, any>
) {
  const mutationName = `${type}${resource}`;
  return {
    query: gql`mutation ${mutationName}($resourceId: ID!) {
      ${mutationName}(resourceId: $resourceId) {
        id
      }
    }`,
    variables: { resourceId },
    parseResponse: ({ data }: ApolloQueryResult<any>) => {
      if (data[mutationName]) {
        return { data: { id: resourceId } };
      }
      throw new Error(`Can't publish from ${resource}#${resourceId}`);
    },
  };
}
