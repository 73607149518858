import { makeStyles } from '@material-ui/core/styles';
import InlineSvgInput from 'components/CustomInput/InlineSvgInput';
import { translationLocales } from 'i18n';
import {
  Edit,
  EditProps,
  required,
  SimpleForm,
  TextInput,
  TranslatableInputs,
  useTranslate,
} from 'react-admin';

import { styles } from './index';

const useStyles = makeStyles(styles);

export const ParkFacilityEdit = (props: EditProps) => {
  const classes = useStyles(styles);
  const translate = useTranslate();
  return (
    <Edit {...props}>
      <SimpleForm>
        <TranslatableInputs locales={translationLocales}>
          <TextInput
            source="localizedName"
            validate={[required()]}
            formClassName={classes.textInput}
          />
        </TranslatableInputs>
        <InlineSvgInput
          source="svg"
          label={translate('resources.ParkFacility.fields.svg')}
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
};
