import { Alert, AlertTitle } from '@material-ui/lab';
import { get } from 'lodash';
import { FieldProps, useTranslate } from 'react-admin';

const ParkOfferStatusField = ({ record, source }: FieldProps) => {
  const translate = useTranslate();
  const countOffer = source ? get(record, source) : 0;

  return (
    <Alert severity={countOffer > 0 ? 'success' : 'error'}>
      <AlertTitle>
        {translate(
          `resources.Accommodation.fields.statuses.offers.${
            countOffer > 0 ? 'withOffers' : 'withoutOffer'
          }.title`
        )}
      </AlertTitle>
      {translate(
        `resources.Accommodation.fields.statuses.offers.${
          countOffer > 0 ? 'withOffers' : 'withoutOffer'
        }.description`,
        {
          smart_count: countOffer,
        }
      )}
    </Alert>
  );
};
export default ParkOfferStatusField;
