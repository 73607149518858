import { get, toLower } from 'lodash';
import { FieldProps, useTranslate } from 'react-admin';

interface TranslatableValueFieldProps extends FieldProps {
  translatePath: string;
}

const TranslatableValueField = ({
  translatePath,
  resource,
  record,
  source,
}: TranslatableValueFieldProps) => {
  const translate = useTranslate();
  const valueToTranslate = source ? get(record, source) : '';
  const value = (
    <span>{translate(`resources.${resource}.${translatePath}.${toLower(valueToTranslate)}`)}</span>
  );
  return value;
};

TranslatableValueField.defaultProps = {
  addLabel: true,
};

export default TranslatableValueField;
