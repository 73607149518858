import CustomDateField from 'components/CustomFields/CustomDateField';
import FullNameField from 'components/CustomFields/FullNameField';
import { Datagrid, DateField, List, ListProps, TextField } from 'react-admin';

import UserAsideFilter from './UserAsideFilter';

export const UserList = (props: ListProps) => (
  <List {...props} aside={<UserAsideFilter />} bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <FullNameField source="person" sortable={false} />
      <TextField source="person.email" />
      <CustomDateField source="person.birthdate" format="dd/MM/yyyy" withDiff />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
);
