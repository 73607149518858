import { Dialog } from '@material-ui/core';
import { ReactElement } from 'react';
import { Create, SimpleForm, useRecordContext, useRedirect } from 'react-admin';

import { useDialogStyles } from '.';

interface CreateFormDialogProps {
  redirectPath: string;
  renderReferenceForm: () => ReactElement;
  resource: string;
  target: string;
  refresh: () => void;
}

const CreateFormDialog = ({
  resource,
  redirectPath,
  renderReferenceForm,
  target,
  refresh,
}: CreateFormDialogProps) => {
  const classes = useDialogStyles();
  const redirect = useRedirect();
  const record = useRecordContext();
  const handleClose = () => {
    redirect(redirectPath);
    refresh();
  };

  return (
    <Dialog open onClose={handleClose}>
      <Create
        record={{ [target]: record.id }}
        resource={resource}
        basePath={`/${resource}`}
        onSuccess={handleClose}
        className={classes.root}
      >
        <SimpleForm>{renderReferenceForm()}</SimpleForm>
      </Create>
    </Dialog>
  );
};

export default CreateFormDialog;
