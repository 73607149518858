import { makeStyles } from '@material-ui/core/styles';
import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomDateField from 'components/CustomFields/CustomDateField';
import FullNameField from 'components/CustomFields/FullNameField';
import { SectionTitle } from 'components/formUtils';
import {
  AutocompleteInput,
  DateInput,
  DeleteButton,
  Edit,
  EditProps,
  Record,
  RecordMap,
  ReferenceInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useEditController,
  useTranslate,
} from 'react-admin';

import BirthdateInput from '../../CustomInput/BirthdateInput';
import CreateReferenceInput from '../../CustomInput/CreateReferenceInput';

const useStyles = makeStyles((theme) => ({
  inlineInfo: {
    width: '256px',
    [theme.breakpoints.up('lg')]: { display: 'inline-block', marginRight: '20px', width: '30%' },
  },
  toolbar: {
    justifyContent: 'space-between',
  },
}));

const CustomToolbar = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();

  const { status } = props.record;

  const deleteOptions = {
    label: status !== 'OPTION' ? translate('pos.button.archive') : translate('ra.action.delete'),
    title: status !== 'OPTION' ? translate('pos.button.archive') : translate('ra.action.delete'),
    content:
      status !== 'OPTION'
        ? translate('pos.content.archive')
        : translate('ra.message.delete_content'),
    icon: status !== 'OPTION' ? <ArchiveIcon /> : <DeleteIcon />,
  };

  if (props.record?.archivedAt) {
    deleteOptions.label = translate('pos.button.unArchive');
    deleteOptions.title = translate('pos.button.unArchive');
    deleteOptions.content = translate('pos.content.unArchive');
  }

  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton disabled={props.pristine} />
      <DeleteButton
        mutationMode="pessimistic"
        label={deleteOptions.label}
        icon={deleteOptions.icon}
        confirmTitle={deleteOptions.title}
        confirmContent={deleteOptions.content}
      />
    </Toolbar>
  );
};

export const StayEdit = (props: EditProps) => {
  const classes = useStyles();
  const translate = useTranslate();

  const { record: stayRecord } = useEditController(props);

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <SectionTitle label={translate('resources.Stay.sections.accommodation')} />
        <ReferenceInput
          label={translate('resources.Stay.fields.accommodation.name')}
          source="accommodation.id"
          reference="Accommodation"
          formClassName={classes.inlineInfo}
        >
          <AutocompleteInput
            validate={[required()]}
            resettable={true}
            disabled={stayRecord?.archivedAt}
            optionText={(record: RecordMap<Record>) => {
              return `${record.name} - ${record.park.name} ${record.park.postcode}`;
            }}
          />
        </ReferenceInput>
        <DateInput source="startAt" formClassName={classes.inlineInfo} />
        <DateInput source="endAt" formClassName={classes.inlineInfo} />
        <SectionTitle label={translate('resources.Stay.sections.tenant')} />
        <ReferenceInput
          label={translate('resources.Stay.fields.tenant.name')}
          source="tenant.id"
          reference="User"
          formClassName={classes.inlineInfo}
        >
          <AutocompleteInput
            validate={[required()]}
            resettable={true}
            disabled={stayRecord?.archivedAt}
            optionText={(record: RecordMap<Record>) => {
              return `${record.person.firstName} ${record.person.lastName}`;
            }}
          />
        </ReferenceInput>
        <SectionTitle label={translate('resources.Stay.sections.users')} />
        <CreateReferenceInput
          editable
          deletable
          fullWidth
          editDialogLabel={translate('resources.Stay.fields.people.editPerson')}
          sort={{ field: 'birthdate', order: 'ASC' }}
          resource="Person"
          reference="Person"
          target="stayId"
          listLabel={translate('resources.Stay.fields.people.list')}
          createDialogLabel={translate('resources.Stay.fields.people.addPerson')}
          renderReferenceForm={() => (
            <>
              <TextInput
                source="firstName"
                label={translate('resources.Person.fields.firstName')}
                validate={[required()]}
                fullWidth
              />
              <TextInput
                source="lastName"
                label={translate('resources.Person.fields.lastName')}
                validate={[required()]}
                fullWidth
              />
              <BirthdateInput
                source="birthdate"
                label={translate('resources.Person.fields.birthdate')}
                validate={required()}
                fullWidth
              />
            </>
          )}
        >
          <>
            <FullNameField label={translate('resources.Stay.fields.people.fullName')} />
            <CustomDateField source="birthdate" format="dd/MM/yyyy" withDiff />
          </>
        </CreateReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
