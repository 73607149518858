import { makeStyles } from '@material-ui/core';
import PhoneNumberInput from 'components/CustomInput/PhoneNumberInput';
import { Create, CreateProps, required, SimpleForm, TextInput } from 'react-admin';
import { useSelector } from 'react-redux';
import { PropertyManagerCreateDataForm } from 'types/propertyManager';

const useStyles = makeStyles((theme) => ({
  inlineInput: {
    [theme.breakpoints.up('md')]: { display: 'inline-block', marginRight: '15px' },
  },
}));

export const PropertyManagerCreate = (props: CreateProps) => {
  const classes = useStyles();
  const ownerId = parseInt(
    useSelector((state: PropertyManagerCreateDataForm) => state.propertyManagerScope.ownerId),
    10
  );

  if (!ownerId) return null;

  return (
    <Create {...props}>
      <SimpleForm
        redirect={`/Owner/${ownerId}`}
        initialValues={{ groups: ['PropertyManager'], ownerId }}
      >
        <TextInput
          source="person.lastName"
          validate={required()}
          formClassName={classes.inlineInput}
        />
        <TextInput
          source="person.firstName"
          validate={required()}
          formClassName={classes.inlineInput}
        />
        <TextInput source="email" validate={[required()]} formClassName={classes.inlineInput} />
        <PhoneNumberInput source="person.phone" formClassName={classes.inlineInput} />
      </SimpleForm>
    </Create>
  );
};
