import { translationLocales } from 'i18n';
import {
  BooleanInput,
  Edit,
  EditProps,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  TranslatableInputs,
  useTranslate,
} from 'react-admin';

import { getCategoryChoices } from './index';

export const AccommodationEquipmentEdit = (props: EditProps) => {
  const translate = useTranslate();
  const categoryChoices = getCategoryChoices(translate);
  return (
    <Edit {...props}>
      <SimpleForm>
        <TranslatableInputs locales={translationLocales}>
          <TextInput source="localizedName" validate={[required()]} />
        </TranslatableInputs>
        <BooleanInput source="searchable" />
        <SelectInput source="category" choices={categoryChoices} validate={[required()]} />
      </SimpleForm>
    </Edit>
  );
};
