import { makeStyles } from '@material-ui/core/styles';
import CustomDateField from 'components/CustomFields/CustomDateField';
import FullNameField from 'components/CustomFields/FullNameField';
import {
  Datagrid,
  DateField,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import { SectionTitle } from '../../formUtils/index';
import { PublishedAtStatusField } from './fields/PublishedAtStatusField';

const useStyles = makeStyles(() => ({
  totalInvoice: {
    float: 'right',
  },
}));

export const StaysShow = (props: ShowProps) => {
  const classes = useStyles();

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <SectionTitle label="Informations pratiques" />
        <PublishedAtStatusField source="status" />
        <ReferenceField label="Locataire" source="tenant.person.id" reference="Person">
          <FullNameField />
        </ReferenceField>
        <ReferenceManyField fullWidth label="Participants" reference="Person" target="stayId">
          <Datagrid>
            <FullNameField source="person" />
            <CustomDateField source="birthdate" format="dd/MM/yyyy" withDiff />
          </Datagrid>
        </ReferenceManyField>
        <ReferenceField label="Camping" source="accommodation.park.id" reference="Park" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Hébergement"
          source="accommodation.id"
          reference="Accommodation"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField source="startAt" />
        <DateField source="endAt" />
        <SimpleShowLayout>
          <ReferenceManyField
            fullWidth
            label="FACTURE VACANCIER"
            reference="InvoiceLine"
            target="stayId"
          >
            <Datagrid>
              <TextField source="wording" />
              <NumberField
                source="allTaxesIncludedPrice"
                options={{
                  style: 'currency',
                  currency: 'EUR',
                }}
              />
            </Datagrid>
          </ReferenceManyField>
          <NumberField
            source="price"
            textAlign="right"
            options={{
              style: 'currency',
              currency: 'EUR',
            }}
            className={classes.totalInvoice}
          />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <ReferenceManyField
            fullWidth
            label="COMMISSION IZICAMP"
            reference="InvoiceLine"
            target="stayId"
            filter={{ isCommission: true }}
          >
            <Datagrid>
              <TextField source="wording" />
              <NumberField
                source="commissionAmount"
                options={{
                  style: 'currency',
                  currency: 'EUR',
                }}
              />
            </Datagrid>
          </ReferenceManyField>
        </SimpleShowLayout>
      </SimpleShowLayout>
    </Show>
  );
};
