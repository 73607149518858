import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { CircularProgress, makeStyles } from '@material-ui/core';
import CalendarPeriodForm from 'components/Calendar/CalendarPeriodForm';
import { CalendarToolbar } from 'components/Calendar/CalendarToolbar';
import styles from 'components/Calendar/style';
import { getAccommodationColor } from 'components/Calendar/utils/colors';
import getDefaultRotationDays from 'components/Calendar/utils/getDefaultRotationDays';
import getFocusableRange from 'components/Calendar/utils/getFocusableRange';
import {
  getAvailablePeriodsObject,
  getUnavailablePeriodsObject,
} from 'components/Calendar/utils/getPeriods';
import isDisableDay from 'components/Calendar/utils/isDisableDay';
import {
  overlappingValidation,
  parkOpeningDaysValidation,
} from 'components/Calendar/utils/isValidPeriod';
import { SectionTitle } from 'components/formUtils';
import { format } from 'date-fns';
import * as locales from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { InputProps, useGetOne, useInput, useNotify, useTranslate } from 'react-admin';
import { DateRange, RangeFocus } from 'react-date-range';
import { Period, PeriodsWithKey } from 'types/calendar.types';

const useStyles = makeStyles(styles);

const AccommodationCalendar = ({ source, record }: InputProps) => {
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(styles);
  const { parkId } = record;
  const defaultPrice = record.defaultPrice || 100;
  const defaultArrivalDays = record.defaultArrivalDays || getDefaultRotationDays();
  const defaultDepartureDays = record.defaultDepartureDays || getDefaultRotationDays();
  const defaultMinimumDelay = record.defaultMinimumDelay || 1;
  const defaultMinimumLength = record.defaultMinimumLength || 1;
  const { data, loaded: getParkLoaded } = useGetOne('Park', parkId);
  const [loading, setLoading] = useState<boolean>(true);
  const {
    input: { value: fleetPeriodsValue },
  } = useInput({ source });
  const {
    input: { value: periodsValue, onChange },
  } = useInput({ source: 'periods' });
  const [availablePeriods, setAvailablePeriods] = useState<PeriodsWithKey>({});
  const [unavailablePeriods, setUnavailablePeriods] = useState<PeriodsWithKey>({});
  const allPeriods = [...Object.values(availablePeriods), ...Object.values(unavailablePeriods)];
  const [focusedRange, setFocusedRange] = useState<RangeFocus | undefined>(undefined);
  const [monthsToDisplay, setMonthsToDisplay] = useState<number>(1);
  const parkOpeningPeriods = data?.openingDays;

  useEffect(() => {
    if (loading && getParkLoaded && fleetPeriodsValue && periodsValue) {
      setLoading(false);
      initPeriods();
    }
  }, [getParkLoaded, fleetPeriodsValue, periodsValue]);

  useEffect(() => {
    const resizeElement = document.querySelector('.edit-page') as HTMLElement;
    function resize() {
      const width = resizeElement.offsetWidth - 180;
      const months = width ? Math.floor(width / 250) : 1;
      if (monthsToDisplay && months) setMonthsToDisplay(months);
    }
    if (ResizeObserver) new ResizeObserver(resize).observe(resizeElement);
    resize();
  }, []);

  const initPeriods = () => {
    Object.values({ ...availablePeriods, ...unavailablePeriods }).forEach((period) => {
      const notifyError = parkOpeningDaysValidation(period, parkOpeningPeriods);
      if (notifyError) notify(...notifyError);
    });
    const unavailablePeriodsObject = getUnavailablePeriodsObject(periodsValue);
    const availablePeriodsObject = getAvailablePeriodsObject(
      [
        ...fleetPeriodsValue.map((period: Period) => ({ ...period, isReadOnly: true })),
        ...periodsValue,
      ],
      defaultArrivalDays,
      defaultDepartureDays,
      defaultMinimumDelay,
      defaultMinimumLength,
      defaultPrice
    );
    setAvailablePeriods(availablePeriodsObject);
    setUnavailablePeriods(unavailablePeriodsObject);
    setFocusedRange(
      getFocusableRange([
        ...Object.values(availablePeriodsObject),
        ...Object.values(unavailablePeriodsObject),
      ])
    );
  };

  const handleFocusedRange = (newFocusedRange: RangeFocus) => {
    return newFocusedRange[1] === 1
      ? setFocusedRange(newFocusedRange)
      : setFocusedRange([focusedRange?.[0] || 0, 0]);
  };

  const handleChangeFormData = (savedPeriods: Period[]) => {
    return onChange(
      savedPeriods
        .filter((period) => !period.isReadOnly)
        .map(
          ({
            startDate,
            endDate,
            price,
            arrivalDays,
            departureDays,
            isAvailable,
            minimumDelay,
            minimumLength,
          }: Period) => ({
            startAt: format(startDate, 'yyyy-MM-dd'),
            endAt: format(endDate, 'yyyy-MM-dd'),
            price,
            arrivalDays,
            departureDays,
            available: isAvailable,
            minimumDelay,
            minimumLength,
          })
        )
    );
  };

  // Modification de startAt et endAt d'une période
  const handleChangeDatePeriod = (item: PeriodsWithKey) => {
    const itemKey: string = Object.keys(item)[0];
    const period: Period = item[itemKey];
    const periods = period.isAvailable ? availablePeriods : unavailablePeriods;
    const otherPeriods: PeriodsWithKey = { ...periods };
    delete otherPeriods[period.key];
    const notifyError = overlappingValidation(otherPeriods, period);
    if (notifyError) {
      notify(...notifyError);
    } else {
      // Ajout de la nouvelle période aux périodes définies
      if (period.key === 'newPeriod') {
        period.key = `selection${Number(allPeriods.length) - 1}`;
      }
      updatePeriods(period);
    }
  };

  // Ajout d'une nouvelle période, la clé newPeriod permet de ne pas être considérée comme une période définie
  const handleAddNewPeriod = (e: React.MouseEvent<HTMLButtonElement>, isAvailable?: boolean) => {
    e.preventDefault();
    const periods = isAvailable ? Object.keys(availablePeriods) : allPeriods;
    const newIndex = periods.length;
    if (isAvailable !== undefined) {
      updatePeriods({
        startDate: new Date(),
        endDate: new Date(),
        key: 'newPeriod',
        price: defaultPrice,
        arrivalDays: defaultArrivalDays,
        departureDays: defaultDepartureDays,
        minimumDelay: defaultMinimumDelay,
        minimumLength: defaultMinimumLength,
        isAvailable,
        ...getAccommodationColor(false, isAvailable),
      });
    }
    setFocusedRange([newIndex, 0]);
  };

  const updatePeriods = ({
    startDate,
    endDate,
    key,
    price,
    arrivalDays,
    departureDays,
    minimumDelay,
    minimumLength,
    isAvailable,
  }: Period) => {
    const notNewPeriod = key !== 'newPeriod';
    const updatedPeriods = {
      ...(isAvailable ? availablePeriods : unavailablePeriods),
      [key]: {
        key,
        startDate,
        endDate,
        price,
        arrivalDays,
        departureDays,
        minimumDelay,
        minimumLength,
        isAvailable,
        ...getAccommodationColor(false, isAvailable),
      },
    };
    // Suppression de la période temporaire lors de l'ajout d'une période
    if (notNewPeriod && updatedPeriods.newPeriod) {
      delete updatedPeriods.newPeriod;
    }
    if (isAvailable) {
      setAvailablePeriods(updatedPeriods);
    } else {
      setUnavailablePeriods(updatedPeriods);
    }
    if (notNewPeriod) {
      const savedPeriods = [
        ...Object.values(updatedPeriods),
        ...Object.values(isAvailable ? unavailablePeriods : availablePeriods),
      ];
      handleChangeFormData(savedPeriods);
    }
  };

  // Suppression d'une période
  const deletePeriod = (e: React.MouseEvent<HTMLButtonElement>, period: Period) => {
    e.preventDefault();
    const periods = period.isAvailable ? availablePeriods : unavailablePeriods;
    const lastKey = Object.keys(periods).pop();
    delete periods[period.key];
    if (period.isAvailable) {
      setAvailablePeriods(periods);
    } else {
      setUnavailablePeriods(periods);
    }
    const savedPeriods = [
      ...Object.values(periods),
      ...Object.values(period.isAvailable ? unavailablePeriods : availablePeriods),
    ];
    handleChangeFormData(savedPeriods);
    if (period.key === lastKey) setFocusedRange(getFocusableRange(allPeriods));
  };

  if (loading) {
    return (
      <div className={classes.spinnerDiv}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <CalendarToolbar
        disabled={'newPeriod' in availablePeriods || 'newPeriod' in availablePeriods}
        handleAddNewPeriod={handleAddNewPeriod}
      />
      <div className={classes.calendar}>
        <div className={classes.periods}>
          <SectionTitle label={translate('pos.calendar.sections.availabilities')} />
          {Object.values(availablePeriods).map((period, index) => (
            <CalendarPeriodForm
              key={period.key}
              index={index}
              period={period}
              focusedRange={focusedRange}
              defaultArrivalDays={defaultArrivalDays}
              defaultDepartureDays={defaultDepartureDays}
              defaultMinimumDelay={defaultMinimumDelay}
              defaultMinimumLength={defaultMinimumLength}
              setFocusedRange={setFocusedRange}
              deletePeriod={deletePeriod}
              updatePeriods={updatePeriods}
            />
          ))}
        </div>
        <div className={classes.periods}>
          <SectionTitle label={translate('pos.calendar.sections.unavailabilities')} />
          {Object.values(unavailablePeriods).map((period, index) => (
            <CalendarPeriodForm
              key={period.key}
              index={Object.keys(availablePeriods).length + index}
              period={period}
              focusedRange={focusedRange}
              defaultArrivalDays={defaultArrivalDays}
              defaultDepartureDays={defaultDepartureDays}
              defaultMinimumDelay={defaultMinimumDelay}
              defaultMinimumLength={defaultMinimumLength}
              setFocusedRange={setFocusedRange}
              deletePeriod={deletePeriod}
              updatePeriods={updatePeriods}
            />
          ))}
        </div>
        <DateRange
          disabledDay={(date: Date) => isDisableDay(date, parkOpeningPeriods)}
          showDateDisplay={false}
          months={monthsToDisplay}
          className={classes.dateRangePicker}
          locale={locales.fr}
          onChange={(item: any) => handleChangeDatePeriod(item)}
          ranges={allPeriods}
          focusedRange={focusedRange}
          onRangeFocusChange={handleFocusedRange}
          dateDisplayFormat={'dd MM yyyy'}
          fixedHeight={true}
          showPreview={!!focusedRange}
          dragSelectionEnabled={!!focusedRange}
          direction="horizontal"
        />
      </div>
    </>
  );
};

export default AccommodationCalendar;
