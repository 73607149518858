import { IconButton, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { PeriodSettingsModalHeaderProps } from 'types/calendar.types';

const PeriodSettingsModalHeader = (props: PeriodSettingsModalHeaderProps) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="subtitle1" color="textSecondary">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          style={{
            position: 'absolute',
            right: 5,
            top: 5,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export default PeriodSettingsModalHeader;
