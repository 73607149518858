import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';

export default function acceptParkApplicationReview(
  type: string,
  resource: string,
  params: Record<string, any>
) {
  return {
    query: gql`mutation ${type}($id: ID!) {
      ${type}(id: $id) {
        id
      }
    }`,
    variables: { id: params.id },
    parseResponse: ({ data }: ApolloQueryResult<any>) => {
      if (data[type]) {
        return { data: { id: params.id } };
      }
      throw new Error(`Review not sent`);
    },
  };
}
