import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import { InputProps, PasswordInput as RaPasswordInput, regex, useTranslate } from 'react-admin';

export const styles: Styles<Theme, any> = () => ({
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const useStyles = makeStyles(styles);

export const PasswordInput = (props: InputProps) => {
  const classes = useStyles(styles);
  const translate = useTranslate();
  const validatePassword = regex(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z\d@$!%*?&]{8,}$/,
    translate('pos.validation.invalidPassword')
  );

  return (
    <>
      <div className={classes.dialogContent}>
        <RaPasswordInput
          {...props}
          initiallyVisible
          label={translate('pos.newPassword')}
          validate={validatePassword}
        />
      </div>
    </>
  );
};
