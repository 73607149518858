import { Datagrid, List, ListProps, TextField } from 'react-admin';

export const AccommodationTypeList = (props: ListProps) => (
  <List {...props} bulkActionButtons={false} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="description.fr" sortable={false} />
    </Datagrid>
  </List>
);
