import { Card as MuiCard, CardContent, InputAdornment } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import HotelIcon from '@material-ui/icons/Hotel';
import PinDropIcon from '@material-ui/icons/PinDrop';
import DateRangeInput from 'components/CustomInput/DateRangeInput';
import { SectionTitle, Separator } from 'components/formUtils';
import {
  AutocompleteInput,
  Filter,
  FilterLiveSearch,
  Record,
  RecordMap,
  ReferenceInput,
  useTranslate,
} from 'react-admin';

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const useStyles = makeStyles({
  filterTitle: {
    textAlign: 'start',
    fontSize: '0.75rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: 2.66,
    letterSpacing: '0.08333em',
    textTransform: 'uppercase',
  },
  sectionTitle: {
    textAlign: 'center',
    fontSize: '1.1rem',
  },
  dateRangefilter: {
    minHeight: '4.2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const UserAsideFilter = ({ filterValues }: any) => {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <FilterLiveSearch source="q" />
        <SectionTitle
          label={translate('resources.User.filters.createdAt')}
          className={classes.filterTitle}
        />
        <Filter
          classes={{
            form: classes.dateRangefilter,
          }}
        >
          <DateRangeInput source="createdAt" toPast alwaysOn />
        </Filter>
        <Separator />
        <SectionTitle
          label={translate('resources.User.filters.staySection')}
          className={classes.sectionTitle}
        />
        <SectionTitle
          label={translate('resources.User.filters.stay.startAt')}
          className={classes.filterTitle}
        />
        <Filter
          classes={{
            form: classes.dateRangefilter,
          }}
        >
          <DateRangeInput source="stay.startDate" alwaysOn />
        </Filter>
        <Filter>
          <ReferenceInput
            label={translate('resources.User.filters.stay.park')}
            source="stay.parkId"
            reference="Park"
            alwaysOn
          >
            <AutocompleteInput
              resettable={true}
              optionText={(record: RecordMap<Record>) =>
                record.id ? `${record.name} (${record.postcode})` : ''
              }
              options={{
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <PinDropIcon color="disabled" />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </ReferenceInput>
          <ReferenceInput
            label={translate('resources.User.filters.stay.accommodation')}
            source="stay.accommodationId"
            reference="Accommodation"
            disabled={!filterValues.stay?.parkId}
            filter={{ parkId: filterValues.stay?.parkId }}
            alwaysOn
          >
            <AutocompleteInput
              resettable={true}
              optionText={(record: RecordMap<Record>) => {
                return record.id ? `${record.name}` : '';
              }}
              options={{
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <HotelIcon color="disabled" />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </ReferenceInput>
        </Filter>
      </CardContent>
    </Card>
  );
};

export default UserAsideFilter;
