import { Tab, Tabs } from '@material-ui/core';
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslate } from 'react-admin';

interface Props {
  tab: string;
  setTab: (tab: string) => void;
}

const ParkOffersTabs = ({ tab, setTab }: Props) => {
  const translate = useTranslate();
  const handleChange = useCallback(
    (_event: React.ChangeEvent<Record<string, never>>, value: string) => setTab(value),
    [setTab]
  );
  const tabs = [
    { id: 'all', name: translate('pos.tabs.parkOffer.all') },
    { id: 'featured', name: translate('pos.tabs.parkOffer.featured') },
  ];
  return (
    <Tabs variant="fullWidth" centered value={tab} indicatorColor="primary" onChange={handleChange}>
      {tabs.map((choice) => (
        <Tab key={choice.id} label={choice.name} value={choice.id} />
      ))}
    </Tabs>
  );
};

export default ParkOffersTabs;
