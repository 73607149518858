export const fleetColors = [
  '#4FD398',
  '#009aa8',
  '#d125a9',
  '#3F487B',
  '#ffcf3f',
  '#B221FF',
  '#098a3a',
  '#ff8001',
];

export const accommodationColors = {
  available: '#4294FF',
  unavailable: '#fa4747',
  readOnly: '#d6d6d6',
};

export const getFleetColor = (index: number) => {
  const colorIndex = index % fleetColors.length;
  return { color: fleetColors[colorIndex], colorClassName: `color_${colorIndex}` };
};

export const getAccommodationColor = (isReadOnly: boolean, isAvailable?: boolean) => {
  if (isReadOnly) return { color: accommodationColors.readOnly, colorClassName: 'readOnly' };
  if (isAvailable === false)
    return { color: accommodationColors.unavailable, colorClassName: 'unavailable' };
  return { color: accommodationColors.available, colorClassName: 'available' };
};
