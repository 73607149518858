import { Button } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Actions } from 'dataProvider';
import { format } from 'date-fns';
import { get } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MutationOptions } from 'ra-core/esm/dataProvider/useMutation';
import { useState } from 'react';
import {
  Confirm,
  FieldProps,
  useMutation,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { ModalSeverity } from 'types/materialui.types';

enum PublishedAtStatus {
  PUBLISHED = 'published',
  NOT_PUBLISHED = 'notPublished',
}

const PublishedStatusField = ({ record, resource, source }: FieldProps) => {
  const publishedAt = source ? get(record, source) : undefined;
  const publishedDate = new Date(publishedAt);
  const translate = useTranslate();
  const baseTranslate = `pos.publishedStatues.${
    publishedAt ? PublishedAtStatus.PUBLISHED : PublishedAtStatus.NOT_PUBLISHED
  }`;

  const severity: ModalSeverity = publishedAt ? ModalSeverity.Success : ModalSeverity.Error;

  const notify = useNotify();
  const redirectTo = useRedirect();
  const refresh = useRefresh();
  const [isConfirmPublishOpen, setConfirmPublishOpen] = useState(false);
  const [isConfirmUnpublishOpen, setConfirmUnpublishOpen] = useState(false);

  const getOptions = (status: string): MutationOptions => ({
    onSuccess: () => {
      notify(translate(`pos.publishedStatues.${status}.actionMessage`), 'success');
      redirectTo(`/${resource}/${record?.id}/show`);
      refresh();
    },
    onFailure: () => {
      notify(translate(`pos.publishedStatues.${status}.errorMessage`), 'warning');
    },
  });

  const [publish, { loading: publishMutationLoading }] = useMutation(
    {
      type: Actions.PUBLISH,
      resource,
      payload: { resourceId: record?.id },
    },
    getOptions(PublishedAtStatus.PUBLISHED)
  );

  const [unpublish, { loading: unpublishMutationLoading }] = useMutation(
    {
      type: Actions.UNPUBLISH,
      resource,
      payload: { resourceId: record?.id },
    },
    getOptions(PublishedAtStatus.NOT_PUBLISHED)
  );
  const handlePublishConfirmation = () => setConfirmPublishOpen(true);
  const handleClosePublishConfirmation = () => setConfirmPublishOpen(false);

  const handlePublish = () => {
    publish();
    setConfirmPublishOpen(false);
  };

  const renderPublishButton = () => (
    <>
      <Button
        color="inherit"
        size="small"
        onClick={handlePublishConfirmation}
        disabled={publishMutationLoading}
      >
        {translate(`${baseTranslate}.action`)}
      </Button>
      <Confirm
        isOpen={isConfirmPublishOpen}
        loading={publishMutationLoading}
        title={translate(`pos.publishedStatues.published.confirmTitle`)}
        content={translate(`pos.publishedStatues.published.confirm`, { name: record?.name })}
        onConfirm={handlePublish}
        onClose={handleClosePublishConfirmation}
      />
    </>
  );

  const handleUnpublishConfirmation = () => setConfirmUnpublishOpen(true);
  const handleCloseUnpublishConfirmation = () => setConfirmUnpublishOpen(false);

  const handleUnpublish = () => {
    unpublish();
    setConfirmUnpublishOpen(false);
  };

  const renderUnpublishButton = () => (
    <>
      <Button
        color="inherit"
        size="small"
        onClick={handleUnpublishConfirmation}
        disabled={publishMutationLoading}
      >
        {translate(`${baseTranslate}.action`)}
      </Button>
      <Confirm
        isOpen={isConfirmUnpublishOpen}
        loading={unpublishMutationLoading}
        title={translate(`pos.publishedStatues.notPublished.confirmTitle`)}
        content={translate(`pos.publishedStatues.notPublished.confirm`, { name: record?.name })}
        onConfirm={handleUnpublish}
        onClose={handleCloseUnpublishConfirmation}
      />
    </>
  );

  return (
    <>
      <Alert
        severity={severity}
        action={publishedAt ? renderUnpublishButton() : renderPublishButton()}
      >
        <AlertTitle>{translate(`${baseTranslate}.title`)}</AlertTitle>
        {translate(
          `${baseTranslate}.description`,
          publishedAt ? { date: format(publishedDate, 'dd/MM/yyyy') } : null
        )}
      </Alert>
    </>
  );
};

export default PublishedStatusField;
