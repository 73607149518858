import { makeStyles } from '@material-ui/core';
import { PasswordInputModal } from 'components/CustomInput/PasswordInputModal';
import { Separator } from 'components/formUtils';
import getPoolGroups from 'components/Utils/getPoolGroups';
import {
  DeleteButton,
  Edit,
  EditProps,
  Record,
  required,
  SaveButton,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
} from 'react-admin';

const useStyles = makeStyles(() => ({
  toolbar: {
    justifyContent: 'space-between',
  },
}));

const CustomToolbar = (props: ToolbarProps) => {
  const classes = useStyles();

  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton disabled={props.pristine} />
      <DeleteButton mutationMode="pessimistic" />
    </Toolbar>
  );
};

export const AdminEdit = (props: EditProps) => {
  return (
    <Edit {...props}>
      <SimpleForm
        toolbar={<CustomToolbar />}
        initialValues={(record: Record) => ({ username: record.username })}
      >
        <TextInput source="name" validate={[required()]} />
        <TextInput source="email" validate={[required()]} />
        <SelectArrayInput source="groups" choices={getPoolGroups()} />
        <TextInput source="picture" />
        <Separator />
        <PasswordInputModal source="password" />
      </SimpleForm>
    </Edit>
  );
};
