import { Button, makeStyles, Theme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Styles } from '@material-ui/styles';
import { useState } from 'react';
import { InputProps, useTranslate } from 'react-admin';

import { PasswordInput } from './PasswordInput';

export const styles: Styles<Theme, any> = () => ({
  openModalbutton: {
    marginBottom: '2rem',
    marginTop: '2rem',
  },
});

const useStyles = makeStyles(styles);

interface DialogTitleProps {
  children: React.ReactNode;
  onClose: () => void;
}

interface PasswordModalProps extends InputProps {
  buttonSize?: 'small' | 'medium' | 'large';
}

const DialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="subtitle1" color="textSecondary">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          style={{
            position: 'absolute',
            right: 5,
            top: 5,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export const PasswordInputModal = ({ buttonSize = 'medium', ...props }: PasswordModalProps) => {
  const classes = useStyles(styles);
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const handleCloseDialog = () => setOpen(false);
  const handleOpenDialog = () => setOpen(true);

  return (
    <>
      <Button
        className={classes.openModalbutton}
        variant="outlined"
        color="primary"
        size={buttonSize}
        onClick={handleOpenDialog}
      >
        {translate('pos.editPassword')}
      </Button>
      <Dialog fullWidth maxWidth="xs" open={open} onClose={handleCloseDialog}>
        <DialogTitle onClose={handleCloseDialog}>{translate('pos.editOfPassword')}</DialogTitle>
        <MuiDialogContent>
          <PasswordInput {...props} />
          <Button onClick={handleCloseDialog}>{translate('pos.validate')}</Button>
        </MuiDialogContent>
      </Dialog>
    </>
  );
};
