import { CircularProgress, makeStyles, Popover } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {
  Accommodation,
  getAvailabilitiesFromAccommodation,
  Period,
} from 'components/Utils/getAvailabilitiesFromAccommodation';
import {
  addDays,
  differenceInDays,
  eachDayOfInterval,
  format,
  getDay,
  isEqual,
  isWithinInterval,
} from 'date-fns';
import FR from 'date-fns/locale/fr';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useEffect, useState } from 'react';
import { Button, InputProps, useGetOne, useInput, useTranslate } from 'react-admin';
import { DateRange, RangeFocus } from 'react-date-range';
import { RotationDays } from 'types/accommodation.types';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  spinnerDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  dateRangePicker: {
    '& .rdrInRange': {
      filter: 'none',
    },
    '& .rdrStartEdge': {
      filter: 'none',
    },
    '& .rdrEndEdge': {
      filter: 'none',
    },
  },
});

interface DateRangeInputProps extends InputProps {
  toPast?: boolean;
  toFuture?: boolean;
  buttonLabel?: string;
  accommodationId?: string;
  disabled?: boolean;
}

const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const DateRangeInput = ({
  toPast,
  toFuture,
  buttonLabel,
  accommodationId,
  disabled,
  ...props
}: DateRangeInputProps) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [focusedRange, setFocusedRange] = useState<RangeFocus>([0, 0]);
  const { data: accommodation, loading } = useGetOne<Accommodation>(
    'Accommodation',
    accommodationId || ''
  );
  const {
    input: { onChange, value, ...inputProps },
  } = useInput(props);
  const [range, setRange] = useState([
    {
      startDate: value?.startAt ? new Date(value?.startAt) : new Date(),
      endDate: value?.endAt ? new Date(value?.endAt) : new Date(),
      key: 'selection',
    },
  ]);

  const handleFocusedRange = async (newFocusedRange: RangeFocus, popupState: any) => {
    setFocusedRange(newFocusedRange);
    if (newFocusedRange[1] === 0) {
      bindPopover(popupState).onClose();
    }
  };

  useEffect(() => {
    const [period] = range;
    if (focusedRange[1] === 0 && period.startDate.getTime() !== period.endDate.getTime()) {
      onChange({
        startAt: format(period.startDate, 'yyyy-MM-dd'),
        endAt: format(period.endDate, 'yyyy-MM-dd'),
      });
    }
  }, [range]);

  const getButtonContent = () => {
    const [period] = range;
    if (period.startDate.getTime() !== period.endDate.getTime()) {
      return `
        ${translate('pos.from')} ${format(period.startDate, 'dd MMMM yyyy', { locale: FR })}
        ${translate('pos.to')} ${format(period.endDate, 'dd MMMM yyyy', { locale: FR })}
      `;
    }
    return buttonLabel || translate('pos.modifyDate');
  };

  const isDisableDay = (date: Date) => {
    // Si valeur dans range et que le focus est sur la date de départ, on rend disabled les jours avant la startDate du range
    if (range && focusedRange[1] === 1 && differenceInDays(new Date(date), range[0].startDate) < 0)
      return true;

    let isAvailableDay = true;

    if (!loading && accommodation !== undefined && accommodation.periods) {
      let isAvailable = true;
      const availabilities = getAvailabilitiesFromAccommodation(accommodation);
      const unavailabilitiesPeriods = accommodation.periods.filter(
        (period: Period) => !period.available
      );

      // Si valeur dans range et que le focus est sur la date de départ...
      if (range && focusedRange[1] === 1) {
        const rangeToCheck = { start: range[0].startDate, end: date };
        unavailabilitiesPeriods.forEach((period: Period) => {
          const unavailabilities = eachDayOfInterval({
            start: new Date(`${period.startAt}T00:00:00`),
            end: new Date(`${period.endAt}T00:00:00`),
          });
          unavailabilities.pop();
          unavailabilities.forEach((unavailability: Date) => {
            // ... si l'un des jours d'indisponibilité fait partie de la periode entre la startDate du range et la date en cours de vérification ==> isAvailable = false
            if (isWithinInterval(unavailability, rangeToCheck)) {
              isAvailable = false;
            }
          });
        });
        const eachDaysOfRange = eachDayOfInterval(rangeToCheck);
        // ... pour chaque jour entre la startDate du range et la date en cours de vérification ...
        eachDaysOfRange.forEach((day: Date) => {
          // ... si l'un de ces jours ne fait pas partie des jours disponibles ==> isAvailable = false
          if (
            !availabilities.some((availability) =>
              isEqual(new Date(`${availability.date}T00:00:00`), day)
            )
          )
            isAvailable = false;
        });
      }

      // Si la date n'est pas incluse dans le tableau de dispos ==> isAvailable = false
      if (
        !availabilities.some((availability) =>
          isEqual(new Date(`${availability.date}T00:00:00`), date)
        )
      ) {
        isAvailable = false;
      }
      const day = daysOfWeek[getDay(date)];
      accommodation.periods
        .filter((period: Period) => period.available)
        .forEach((period: Period) => {
          const periodRange = {
            start: new Date(`${period.startAt}T00:00:00`),
            end: new Date(`${period.endAt}T00:00:00`),
          };
          const { arrivalDays, departureDays, minimumDelay, minimumLength } = period;
          // Si le delais minimum n'est pas respecté ==> isAvailable = false
          const minimumDate = addDays(new Date(), minimumDelay);
          if (isWithinInterval(date, periodRange) && differenceInDays(date, minimumDate) < 0)
            isAvailable = false;
          // Si la durée minimum n'est pas respectée ==> isAvailable = false
          if (
            isWithinInterval(date, periodRange) &&
            focusedRange[1] === 1 &&
            differenceInDays(date, range[0].startDate) < minimumLength
          )
            isAvailable = false;
          // Si le focus est sur la date d'arrivee && que le jour de la date fait partie des jours d'arrivée de la période à laquelle appartient la date ==> isAvailable = false
          if (
            isWithinInterval(date, periodRange) &&
            focusedRange[1] === 0 &&
            !arrivalDays[day as keyof RotationDays]
          ) {
            isAvailable = false;
          }
          // Si le focus est sur la date de depart && que le jour de la date fait partie des jours de depart de la période à laquelle appartient la date ==> isAvailable = false
          if (
            isWithinInterval(date, periodRange) &&
            focusedRange[1] === 1 &&
            !departureDays[day as keyof RotationDays]
          ) {
            isAvailable = false;
          }
        });
      isAvailableDay = isAvailable;
    }
    return !isAvailableDay;
  };

  if (loading) {
    return (
      <div className={classes.spinnerDiv}>
        <CircularProgress size="2rem" />
      </div>
    );
  }

  return (
    <PopupState variant="popover" popupId="date-range-popup">
      {(popupState) => (
        <div className={classes.container}>
          <Button
            disabled={disabled}
            alignIcon="left"
            label={getButtonContent()}
            {...bindTrigger(popupState)}
          >
            <DateRangeIcon />
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <DateRange
              {...inputProps}
              className={classes.dateRangePicker}
              minDate={toFuture ? new Date() : undefined}
              maxDate={toPast ? new Date() : undefined}
              focusedRange={focusedRange}
              onChange={(item: any) => setRange([item.selection])}
              ranges={range}
              locale={FR}
              dateDisplayFormat={'dd MMMM yyyy'}
              fixedHeight={true}
              onRangeFocusChange={(newFocusedRange) =>
                handleFocusedRange(newFocusedRange, popupState)
              }
              disabledDay={(day: Date) => isDisableDay(day)}
            />
          </Popover>
        </div>
      )}
    </PopupState>
  );
};

export default DateRangeInput;
