import InlineSvgField from 'components/CustomFields/InlineSvgField';
import { ImageInput, InputProps, useInput } from 'react-admin';

const InlineSvgInput = (props: InputProps) => {
  const {
    input: { name, onChange, value },
  } = useInput(props);

  const handleChange = async (file: File): Promise<void> => {
    try {
      const fileContent = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(String(reader.result));
        reader.onerror = reject;
        reader.readAsText(file);
      });
      onChange(fileContent);
    } catch (err) {
      console.error('Reading SVG file content:', err);
    }
  };

  // parse value from ImageInput for API
  const parse = ({ fileContent }: { fileContent: string }): string => {
    return fileContent;
  };

  // format API value for ImageInput
  const format = (fileContent: string) => {
    return { fileContent };
  };

  return (
    <ImageInput
      value={value}
      onChange={handleChange}
      accept="image/svg+xml"
      source={props.source}
      name={name}
      label={props.label}
      parse={parse}
      format={format}
    >
      <InlineSvgField source="fileContent" />
    </ImageInput>
  );
};

export default InlineSvgInput;
