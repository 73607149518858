import { Button, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import StarRatingField from 'components/CustomFields/StarRatingField';
import LinkToRecord from 'components/Link/LinkToRecord';
import {
  DateField,
  EditContextProvider,
  EmailField,
  NumberField,
  SimpleForm,
  TextField,
  useEditController,
  useTranslate,
} from 'react-admin';

import { ParkApplication } from '../../../types/parkApplication.types';
import AddressField from '../../CustomFields/AddressField';
import ParkApplicationEditToolbar from './ParkApplicationEditTollbar';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 40,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em',
  },
  form: {
    [theme.breakpoints.up('xs')]: {
      width: 250,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      marginTop: -30,
    },
  },
  inlineField: {
    display: 'inline-block',
    width: '50%',
  },
  linkButton: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-around',
    width: '200px',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
}));

interface ParkApplicationEditProps {
  id: string;
  onCancel: () => void;
}

const ParkApplicationEdit = ({ onCancel, ...props }: ParkApplicationEditProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  const controllerProps = useEditController<ParkApplication>(props);
  if (!controllerProps.record) {
    return null;
  }

  function getDateField() {
    if (controllerProps.record && controllerProps.record.accepted) {
      return <DateField source="reviewedAt" label="Date d'acceptation" />;
    }
    return <DateField source="reviewedAt" label="Date de rejet" />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">{translate('resources.ParkApplication.editTitle')}</Typography>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <EditContextProvider value={controllerProps}>
        <SimpleForm
          className={classes.form}
          record={controllerProps.record}
          save={controllerProps.save}
          version={controllerProps.version}
          redirect="list"
          resource="ParkApplication"
          toolbar={<ParkApplicationEditToolbar />}
        >
          <TextField source="name" />
          <TextField source="chain" />
          <AddressField />
          <StarRatingField size="small" source="ranking" />
          {controllerProps.record.residentsNumber ? <NumberField source="residentsNumber" /> : null}
          {controllerProps.record.phone ? <TextField source="phone" /> : null}
          <EmailField source="email" />
          <DateField source="createdAt" />
          {controllerProps.record && controllerProps.record.reviewedAt ? getDateField() : null}
          {controllerProps.record && controllerProps.record.accepted ? (
            <LinkToRecord
              basePath="/Park"
              id={controllerProps.record.parkId}
              linkType="edit"
              text={
                <Button className={classes.linkButton} variant="outlined" color="primary">
                  <EditIcon /> {translate('resources.ParkApplication.parkEditLink')}
                </Button>
              }
            />
          ) : null}
        </SimpleForm>
      </EditContextProvider>
    </div>
  );
};

export default ParkApplicationEdit;
