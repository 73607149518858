import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import DateRangeInput from 'components/CustomInput/DateRangeInput';
import {
  AutocompleteInput,
  Create,
  CreateProps,
  InputProps,
  Record,
  RecordMap,
  ReferenceInput,
  required,
  SimpleForm,
  useTranslate,
} from 'react-admin';
import { useFormState } from 'react-final-form';

export const styles: Styles<Theme, any> = () => ({
  inlineInfo: {
    width: '256px',
  },
});

const useStyles = makeStyles(styles);

const CustomAccommodationInput = () => {
  const translate = useTranslate();
  const classes = useStyles(styles);
  const { values } = useFormState();
  return (
    <ReferenceInput
      label={translate('resources.Stay.fields.accommodation.name')}
      source="accommodationId"
      reference="Accommodation"
      disabled={!values.parkId}
      filter={{ parkId: values.parkId }}
      className={classes.inlineInfo}
    >
      <AutocompleteInput
        className={classes.inlineInfo}
        validate={[required()]}
        optionText={(record: RecordMap<Record>) => `${record.name}`}
      />
    </ReferenceInput>
  );
};

export const StayCreate = (props: CreateProps) => {
  const translate = useTranslate();

  const CustomDateRangeInput = ({ source }: InputProps) => {
    const { values } = useFormState();
    return (
      <DateRangeInput
        accommodationId={values.accommodationId}
        disabled={!values.accommodationId}
        source={source}
      />
    );
  };

  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput
          label={translate('resources.Stay.fields.park.name')}
          source="parkId"
          reference="Park"
        >
          <AutocompleteInput
            validate={[required()]}
            optionText={(record: RecordMap<Record>) => `${record.name}`}
          />
        </ReferenceInput>
        <CustomAccommodationInput />
        <CustomDateRangeInput source="range" />
      </SimpleForm>
    </Create>
  );
};
