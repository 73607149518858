import { CircularProgress, InputAdornment } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import { DefaultRotationDaysSelection } from 'components/Calendar/DefaultRotationDaysSelection';
import TranslatableValueField from 'components/CustomFields/TranslatableValueField';
import FloatNumberInput from 'components/CustomInput/FloatNumberInput';
import IntegerInput from 'components/CustomInput/IntegerInput';
import ManyToManyReferenceInput from 'components/CustomInput/ManyToManyReferenceInput';
import { SectionTitle, Separator } from 'components/formUtils';
import { getMaxDiscountChoices, getVintageChoices } from 'components/Utils/getSelectChoices';
import {
  AutocompleteInput,
  BooleanInput,
  Edit,
  EditProps,
  FormDataConsumer,
  FormTab,
  InputProps,
  NumberInput,
  Record,
  RecordMap,
  ReferenceInput,
  ReferenceManyField,
  required,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  useGetOne,
  useTranslate,
} from 'react-admin';
import { useForm } from 'react-final-form';

import MultiImageInput from '../../CustomInput/MultiImageInput';
import SortableGalleryDatagrid from '../../DragAndDrop/SortableGalleryDatagrid';
import AccommodationCalendar from './AccommodationCalendar';

const styles: Styles<Theme, any> = (theme) => ({
  inlineInfo: {
    [theme.breakpoints.up('sm')]: { display: 'inline-block', marginRight: '15px' },
  },
  accommodationTitle: {
    height: '5.25rem',
    width: '23rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  defaultRotationDays: {
    [theme.breakpoints.up('md')]: { flexWrap: 'nowrap' },
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  defaultValue: {
    [theme.breakpoints.down('md')]: { width: '40%', margin: '10px 0' },
    [theme.breakpoints.up('md')]: { width: '300px', marginRight: '30px' },
  },
  defaultValuesOthers: {
    [theme.breakpoints.up('sm')]: { flexDirection: 'column-reverse' },
    [theme.breakpoints.up('lg')]: { flexDirection: 'row' },
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row',
  },
});

const useStyles = makeStyles(styles);

const AccommodationTitleInput = ({ source }: InputProps) => {
  const form = useForm();
  const translate = useTranslate();

  return (
    <FormDataConsumer>
      {({ formData }) => {
        const { accommodationTypeId, fleetId, bedroom } = formData;
        const { data: fleet, loading: fleetLoading } = useGetOne('Fleet', fleetId);
        const { data: accommodationType, loading: accommodationTypeLoading } = useGetOne(
          'AccommodationType',
          accommodationTypeId
        );
        if (formData.titleOverrided) {
          return (
            <TextInput
              source={source}
              validate={[required()]}
              label={translate('resources.Accommodation.fields.title')}
            />
          );
        }
        if (fleetLoading || accommodationTypeLoading) return <CircularProgress size="2rem" />;
        const fleetName = fleet?.name || '';
        const accommodationTypeName = accommodationType?.name || '';
        form.change(
          'title',
          `${accommodationTypeName} ${fleetName} ${translate(
            'resources.Accommodation.bedroom',
            bedroom
          )}`
        );
        return (
          <TextInput
            disabled
            source={source}
            validate={[required()]}
            label={translate('resources.Accommodation.fields.title')}
          />
        );
      }}
    </FormDataConsumer>
  );
};

export const AccommodationEdit = (props: EditProps) => {
  const translate = useTranslate();
  const classes = useStyles(styles);

  return (
    <Edit {...props}>
      <TabbedForm redirect={false}>
        <FormTab label={translate('resources.Accommodation.tabs.infos')}>
          <AccommodationTitleInput source="title" formClassName={classes.accommodationTitle} />
          <BooleanInput
            source="titleOverrided"
            label={translate('resources.Accommodation.fields.titleOverrided')}
          />
          <Separator />
          <TextInput source="name" validate={[required()]} formClassName={classes.inlineInfo} />
          <ReferenceInput source="ownerId" reference="Owner" formClassName={classes.inlineInfo}>
            <AutocompleteInput
              validate={[required()]}
              resettable={true}
              optionText={(record: RecordMap<Record>) => {
                if (record.company) return `${record?.company?.name}`;
                return `${record.person.firstName} ${record.person.lastName}`;
              }}
            />
          </ReferenceInput>
          <ReferenceInput source="park.id" reference="Park" formClassName={classes.inlineInfo}>
            <AutocompleteInput
              validate={[required()]}
              resettable={true}
              optionText={(record: RecordMap<Record>) => `${record.name} ${record.postcode}`}
            />
          </ReferenceInput>
          <ReferenceInput
            source="accommodationType.id"
            reference="AccommodationType"
            formClassName={classes.inlineInfo}
          >
            <AutocompleteInput
              validate={[required()]}
              resettable={true}
              optionText={(record: RecordMap<Record>) => `${record.name}`}
            />
          </ReferenceInput>
          <SelectInput
            source="vintage"
            choices={getVintageChoices(50)}
            translateChoice={false}
            formClassName={classes.inlineInfo}
          />
          <Separator />
          <SectionTitle label={translate('resources.Accommodation.sections.accommodation')} />
          <NumberInput
            source="maximumPerson"
            validate={[required()]}
            formClassName={classes.inlineInfo}
            min={0}
          />
          <NumberInput
            source="bedroom"
            validate={[required()]}
            formClassName={classes.inlineInfo}
            min={0}
          />
          <NumberInput
            source="bathroom"
            validate={[required()]}
            formClassName={classes.inlineInfo}
            min={0}
          />
          <NumberInput
            source="toilet"
            validate={[required()]}
            formClassName={classes.inlineInfo}
            min={0}
          />
          <NumberInput
            source="surface"
            validate={[required()]}
            formClassName={classes.inlineInfo}
            InputProps={{
              startAdornment: <InputAdornment position="start">m²</InputAdornment>,
            }}
            min={0}
          />
          <Separator />
          <BooleanInput source="disabledAccessible" />
          <BooleanInput source="petAllowed" />
          <BooleanInput source="partyAllowed" />
          <BooleanInput source="smokingAllowed" />
          <Separator />
          <SelectInput
            source="maxDiscount"
            choices={getMaxDiscountChoices()}
            validate={[required()]}
            formClassName={classes.maxDiscount}
          />
          <Separator />
          <SectionTitle label={translate('resources.Accommodation.sections.terrace')} />
          <BooleanInput source="terrace" className={classes.terrace} />
          <Separator />
          <SectionTitle label={translate('resources.Accommodation.sections.picture')} />
          <MultiImageInput
            source="picture"
            resource="AccommodationPicture"
            accept="image/*"
            target="accommodation"
            label={false}
            multiple
          />
          <ReferenceManyField
            reference="AccommodationPicture"
            sort={{ field: 'priority', order: 'ASC' }}
            target="accommodationId"
            fullWidth
            label=""
          >
            <SortableGalleryDatagrid />
          </ReferenceManyField>
          <Separator />
          <SectionTitle label={translate('resources.Accommodation.sections.equipments')} />
          <ManyToManyReferenceInput
            reference="AccommodationEquipment"
            resource="Accommodation"
            target="accommodationId"
            label={translate('resources.Accommodation.addEquipment')}
            renderReferenceForm={({ record }) => (
              <ReferenceInput
                filter={{ notAccommodationId: record?.id }}
                source="accommodationEquipmentId"
                reference="AccommodationEquipment"
              >
                <AutocompleteInput resettable optionText="name" />
              </ReferenceInput>
            )}
          >
            <TextField source="name" />
            <TranslatableValueField translatePath="categories" source="category" />
          </ManyToManyReferenceInput>
        </FormTab>
        <FormTab label={translate('resources.Accommodation.tabs.calendar')}>
          <AccommodationCalendar source="fleetPeriods" />
          <Separator />
          <SectionTitle label={translate('resources.Accommodation.sections.defaultValues')} />
          <DefaultRotationDaysSelection
            label={translate('pos.arrivalDays')}
            source="defaultArrivalDays"
            className={classes.defaultRotationDays}
          />
          <Separator />
          <DefaultRotationDaysSelection
            label={translate('pos.departureDays')}
            source="defaultDepartureDays"
            className={classes.defaultRotationDays}
          />
          <Separator />
          <div className={classes.defaultValuesOthers}>
            <FloatNumberInput
              className={classes.defaultValue}
              label={translate('resources.Accommodation.fields.defaultPrice')}
              source="defaultPrice"
              endAdornment="€"
            />
            <IntegerInput
              className={classes.defaultValue}
              source="defaultMinimumDelay"
              label={translate('pos.defaultMinimumDelay')}
            />
            <IntegerInput
              className={classes.defaultValue}
              source="defaultMinimumLength"
              label={translate('pos.defaultMinimumLength')}
            />
          </div>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
