import { makeStyles } from '@material-ui/core';
import { PasswordInputModal } from 'components/CustomInput/PasswordInputModal';
import PhoneNumberInput from 'components/CustomInput/PhoneNumberInput';
import { Separator } from 'components/formUtils';
import {
  DeleteButton,
  Edit,
  EditProps,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { PropertyManagerCreateDataForm } from 'types/propertyManager';

const useStyles = makeStyles((theme) => ({
  inlineInput: {
    [theme.breakpoints.up('md')]: { display: 'inline-block', marginRight: '15px' },
  },
  toolbar: {
    justifyContent: 'space-between',
  },
}));

const CustomToolbar = (props: ToolbarProps) => {
  const classes = useStyles();

  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton />
      <DeleteButton mutationMode="pessimistic" />
    </Toolbar>
  );
};

export const PropertyManagerEdit = (props: EditProps) => {
  const classes = useStyles();
  const ownerId = parseInt(
    useSelector((state: PropertyManagerCreateDataForm) => state.propertyManagerScope.ownerId),
    10
  );

  if (!ownerId) return null;

  return (
    <Edit {...props}>
      <SimpleForm
        toolbar={<CustomToolbar />}
        redirect={`/Owner/${ownerId}`}
        initialValues={{ ownerId }}
      >
        <TextInput
          source="person.lastName"
          validate={required()}
          formClassName={classes.inlineInput}
        />
        <TextInput
          source="person.firstName"
          validate={required()}
          formClassName={classes.inlineInput}
        />
        <Separator />
        <TextInput source="email" validate={[required()]} formClassName={classes.inlineInput} />
        <PhoneNumberInput source="person.phone" formClassName={classes.inlineInput} />
        <PasswordInputModal source="password" />
      </SimpleForm>
    </Edit>
  );
};
