import { InputAdornment, TextField } from '@material-ui/core';
import { ChangeEvent } from 'react';
import { InputProps, useInput } from 'react-admin';
import NumberFormat from 'react-number-format';

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

const FloatNumberInput = (props: InputProps) => {
  const { label, endAdornment = null, className } = props;
  const {
    input: { onChange, value, ...inputProps },
    meta: { touched, error },
    isRequired,
  } = useInput(props);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(parseFloat(event.target.value));
  };
  return (
    <TextField
      {...inputProps}
      value={value || 0}
      variant="filled"
      className={className}
      label={label}
      error={!!(touched && error)}
      onChange={handleChange}
      helperText={touched && error}
      required={isRequired}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
        endAdornment: endAdornment && (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ),
      }}
    />
  );
};

export default FloatNumberInput;
