import { FieldProps, ReferenceField, TextField } from 'react-admin';

import FullNameField from './FullNameField';

const DenominationField = ({ record }: FieldProps) => {
  if (record?.person?.id) {
    return (
      <ReferenceField reference="Person" source="person.id" link={false}>
        <FullNameField />
      </ReferenceField>
    );
  }
  return (
    <ReferenceField reference="Company" source="company.id" link={false}>
      <TextField source="name" />
    </ReferenceField>
  );
};

DenominationField.defaultProps = {
  label: 'resources.Owner.fields.denomination',
  addLabel: true,
};

export default DenominationField;
