import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import DenominationField from 'components/CustomFields/DenominationField';
import {
  AutocompleteInput,
  Datagrid,
  Filter,
  FilterList,
  FilterListItem,
  List,
  ListProps,
  Record,
  RecordMap,
  ReferenceInput,
  SearchInput,
} from 'react-admin';

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const OwnerAsideFilter = () => {
  return (
    <Card>
      <CardContent>
        <Filter context="form">
          <ReferenceInput label="resources.Park.name" source="parks.id" reference="Park" alwaysOn>
            <AutocompleteInput
              resettable={true}
              optionText={(record: RecordMap<Record>) =>
                record.id ? `${record.name} ${record.postcode}` : ''
              }
            />
          </ReferenceInput>
          <SearchInput source="q" alwaysOn />
        </Filter>
        <FilterList label="resources.Owner.category" icon={<HomeWorkIcon />}>
          <FilterListItem label="resources.Owner.categories.company" value={{ isCompany: true }} />
          <FilterListItem label="resources.Owner.categories.private" value={{ isCompany: false }} />
        </FilterList>
        <FilterList label="resources.Owner.fields.ownerType" icon={<GroupIcon />}>
          <FilterListItem
            label="resources.Owner.ownerTypes.parkOwner"
            value={{ ownerType: 'parkOwner' }}
          />
          <FilterListItem
            label="resources.Owner.ownerTypes.accommodationOwner"
            value={{ ownerType: 'accommodationOwner' }}
          />
        </FilterList>
      </CardContent>
    </Card>
  );
};

export const OwnerList = (props: ListProps) => {
  return (
    <List {...props} bulkActionButtons={false} aside={<OwnerAsideFilter />}>
      <Datagrid rowClick="edit">
        <DenominationField />
      </Datagrid>
    </List>
  );
};
