import { makeStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import * as React from 'react';
import { FC } from 'react';
import { FieldProps } from 'react-admin';

const useStyles = makeStyles({
  root: {
    opacity: 0.87,
    whiteSpace: 'nowrap',
    display: 'flex',
  },
  large: {
    color: '#f8b704',
    width: 20,
    height: 20,
  },
  small: {
    color: '#f8b704',
    width: 15,
    height: 15,
  },
});

interface OwnProps {
  size?: 'large' | 'small';
  source?: string;
}

const StarRatingField: FC<FieldProps & OwnProps> = ({
  record,
  size = 'large',
  source = 'rating',
}) => {
  const classes = useStyles();
  return record ? (
    <span className={classes.root}>
      {Array(5)
        .fill(true)
        .map((_, i) => {
          if (i < record[source]) {
            return (
              <StarIcon key={i} className={size === 'large' ? classes.large : classes.small} />
            );
          }
          return (
            <StarBorderIcon key={i} className={size === 'large' ? classes.large : classes.small} />
          );
        })}
    </span>
  ) : null;
};

StarRatingField.defaultProps = {
  label: 'resources.reviews.fields.rating',
  source: 'rating',
  addLabel: true,
};

export default StarRatingField;
