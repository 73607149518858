import { differenceInDays, eachDayOfInterval, format } from 'date-fns';
import { Record } from 'react-admin';

import { RotationDays } from '../../types/accommodation.types';

export interface Period {
  price: number;
  startAt: Date;
  endAt: Date;
  arrivalDays: RotationDays;
  departureDays: RotationDays;
  minimumDelay: number;
  minimumLength: number;
  available: boolean;
}

interface Availability {
  price: number;
  date: string;
  isArrivalDay: boolean;
  isDepartureDay: boolean;
}

export interface Accommodation extends Record {
  periods: Period[];
}

const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export function getAvailabilitiesFromAccommodation(accommodation: Accommodation) {
  const periods: Period[] = [
    ...(accommodation.periods as []),
    ...(accommodation.fleetPeriods as []),
  ];

  const unavailablePeriods = periods.filter((period) => period.available === false);
  const availablePeriods = periods.filter((period) => period.available === true);
  const unavailableDays: string[] = [];
  const onlyArrivalAvailabilities = [];

  const getEachDayOfPeriod = (period: Period) => {
    const start = new Date(period.startAt);
    const end = new Date(period.endAt);
    return eachDayOfInterval({ start, end }).map((day) => format(day, 'yyyy-MM-dd'));
  };

  unavailablePeriods.forEach((period) => {
    const dates = getEachDayOfPeriod(period);
    onlyArrivalAvailabilities.push(dates.pop());
    unavailableDays.push(...dates);
  });

  return availablePeriods.reduce((availabilities: Availability[], period) => {
    const dates = getEachDayOfPeriod(period);

    dates.forEach((date: string) => {
      if (differenceInDays(new Date(date), new Date()) < 0) return;
      if (unavailableDays.includes(date)) return;
      const currentDay = daysOfWeek[new Date(date).getDay()];
      const isArrivalDay: boolean = period.arrivalDays[currentDay as keyof RotationDays];
      const isDepartureDay: boolean = period.departureDays[currentDay as keyof RotationDays];
      availabilities.push({
        price: period.price,
        date,
        isArrivalDay,
        isDepartureDay,
      });
    });
    return availabilities;
  }, []);
}
