import Auth from '@aws-amplify/auth';
import { Avatar, Card, Theme } from '@material-ui/core';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import { ThemeProvider } from '@material-ui/styles';
import classnames from 'classnames';
import { camelCase } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  createElement,
  HtmlHTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Notification, TitleComponent, useCheckAuth, useNotify } from 'react-admin';
import { StaticContext } from 'react-router';
import { useHistory } from 'react-router-dom';

import { lightTheme } from '../layout/themes';
import { ChangePasswordForm } from './ChangePasswordForm';
import { FormData, LoginForm } from './LoginForm';

const Login: React.FunctionComponent<LoginProps> = (props) => {
  const { theme, ...rest } = props;
  const muiTheme = useMemo(() => createTheme(theme), [theme]);

  return (
    <ThemeProvider theme={muiTheme}>
      <LoginView {...rest} />
    </ThemeProvider>
  );
};

Login.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.object,
  staticContext: PropTypes.object,
};

Login.defaultProps = {
  theme: lightTheme,
  notification: Notification,
  backgroundImage: '/images/beach.jpeg',
};

export interface LoginProps extends Omit<HtmlHTMLAttributes<HTMLDivElement>, 'title'> {
  backgroundImage?: string;
  classes?: any;
  className?: string;
  notification?: any;
  staticContext?: StaticContext;
  theme?: any;
  title?: TitleComponent;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
    },
    card: {
      minWidth: 300,
      marginTop: '6em',
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      backgroundColor: theme.palette.secondary.main,
    },
  }),
  { name: 'RaLogin' }
);

const LoginView: React.FunctionComponent<Omit<LoginProps, 'theme'>> = (props) => {
  const { className, backgroundImage, notification } = props;
  const classes = useStyles(props);
  const checkAuth = useCheckAuth();
  const notify = useNotify();
  const history = useHistory();
  const containerRef = useRef<HTMLDivElement>(null);
  const [userForm, setUserForm] = useState({ username: '', password: '' });
  const [cognitoUser, setCognitoUser] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);

  const getInvalidPasswordError = (amplifyError: string) => {
    let error = amplifyError;
    while (String(error).includes(':')) {
      error = String(error).substring(String(error).indexOf(':') + 1);
    }
    return camelCase(error);
  };

  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        history.push('/');
      })
      .catch(() => {
        // not authenticated, stay on the login page
      });
  }, [checkAuth, history]);

  let backgroundImageLoaded = false;

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
      backgroundImageLoaded = true;
    }
  };

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image();
      img.onload = updateBackgroundImage;
      img.src = backgroundImage;
    }
  };

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage();
    }
  });

  const submitLogin = async ({ username, password }: FormData) => {
    setLoading(true);
    Auth.signIn(username, password)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          notify('pos.notifications.auth.changePasswordRequired');
          setUserForm({ username, password });
          setCognitoUser(user);
        } else {
          window.location.reload();
        }
      })
      .catch((err: string) => {
        let errorString = String(err).split(':')[0];
        errorString = errorString.charAt(0).toLowerCase() + errorString.slice(1);
        notify(`pos.notifications.auth.${errorString}`, 'warning');
      });
    setLoading(false);
  };

  const submitChangePassword = async ({ newPassword }: { newPassword: string }) => {
    setLoading(true);
    if (!cognitoUser) throw new Error('Unauthorized');
    Auth.completeNewPassword(cognitoUser, newPassword)
      .then(() => {
        window.location.reload();
      })
      .catch((err: string) => {
        let errorString: string = String(err).split(':')[0];
        errorString = errorString.charAt(0).toLowerCase() + errorString.slice(1);
        if (errorString === 'invalidPasswordException') {
          notify(`pos.notifications.auth.${getInvalidPasswordError(err)}`, 'warning');
        } else {
          notify(`pos.notifications.auth.${errorString}`, 'warning');
        }
      });
    setLoading(false);
  };

  return (
    <div className={classnames(classes.main, className)} ref={containerRef}>
      <Card className={classes.card}>
        <div className={classes.avatar}>
          <Avatar className={classes.icon}>
            <LockIcon />
          </Avatar>
        </div>
        {cognitoUser ? (
          <ChangePasswordForm
            submitChangePassword={submitChangePassword}
            user={userForm}
            loading={loading}
          />
        ) : (
          <LoginForm submitLogin={submitLogin} loading={loading} />
        )}
      </Card>
      {notification && createElement(notification)}
    </div>
  );
};

export default Login;
