import { Alert, AlertTitle } from '@material-ui/lab';
import { get } from 'lodash';
import { FieldProps, useTranslate } from 'react-admin';

const OwnerAccountStatusField = ({ record, source }: FieldProps) => {
  const translate = useTranslate();
  const isOwnerAccountValid = source ? get(record, source) : false;

  return (
    <div style={{ margin: '10px 0' }}>
      <Alert severity={isOwnerAccountValid ? 'success' : 'error'}>
        <AlertTitle>
          {translate(
            `resources.Accommodation.fields.statuses.ownerAccount.${
              isOwnerAccountValid ? 'valid' : 'notValid'
            }.title`
          )}
        </AlertTitle>
        {isOwnerAccountValid
          ? null
          : translate(`resources.Accommodation.fields.statuses.ownerAccount.notValid.description`)}
      </Alert>
    </div>
  );
};
export default OwnerAccountStatusField;
