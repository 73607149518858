import CustomDateField from 'components/CustomFields/CustomDateField';
import FullNameField from 'components/CustomFields/FullNameField';
import {
  Datagrid,
  DateField,
  ReferenceField,
  ReferenceManyField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  useTranslate,
} from 'react-admin';

import { SectionTitle } from '../../formUtils/index';

export const UserShow = (props: ShowProps) => {
  const translate = useTranslate();
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <SectionTitle label={translate('resources.User.sections.informations')} />
        <FullNameField source="person" />
        <CustomDateField
          label={translate('resources.User.fields.person.birthdate')}
          source="person.birthdate"
          format="dd/MM/yyyy"
          withDiff
        />
        <TextField
          source="person.addressLine1"
          emptyText={translate('resources.User.emptyTexts.addressLine1')}
        />
        <TextField
          source="person.addressLine2"
          emptyText={translate('resources.User.emptyTexts.addressLine2')}
        />
        <TextField source="person.city" emptyText={translate('resources.User.emptyTexts.city')} />
        <TextField
          source="person.postcode"
          emptyText={translate('resources.User.emptyTexts.postcode')}
        />
        <TextField
          source="person.country"
          emptyText={translate('resources.User.emptyTexts.country')}
        />
        <TextField source="person.email" emptyText={translate('resources.User.emptyTexts.email')} />
        <TextField source="person.phone" emptyText={translate('resources.User.emptyTexts.phone')} />
        <ReferenceManyField fullWidth label="Séjours" reference="Stay" target="tenantId">
          <Datagrid rowClick="edit">
            <ReferenceField
              label={translate('resources.Stay.fields.accommodation.park.name')}
              source="accommodation.park.id"
              reference="Park"
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              label={translate('resources.Stay.fields.accommodation.name')}
              source="accommodation.id"
              reference="Accommodation"
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <DateField source="createdAt" />
            <DateField source="startAt" />
            <DateField source="endAt" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};
