import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';

export default function setToMainPropertyManager(
  type: string,
  { id, ownerId }: Record<string, any>
) {
  return {
    query: gql`mutation ${type}($id: Int!, $ownerId: Int!) {
      ${type}(id: $id, ownerId: $ownerId) {
        id
      }
    }`,
    variables: { id, ownerId },
    parseResponse: ({ data }: ApolloQueryResult<any>) => {
      if (data[type]) {
        return { data: { id } };
      }
      throw new Error(`Error to pass #${id} to main property manager for owner ${ownerId}`);
    },
  };
}
