import { makeStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import BirthdateInput from 'components/CustomInput/BirthdateInput';
import PhoneNumberInput from 'components/CustomInput/PhoneNumberInput';
import { SectionTitle, Separator } from 'components/formUtils';
import { useState } from 'react';
import {
  Create,
  CreateProps,
  InputProps,
  required,
  SelectInput,
  SimpleForm,
  TextInput as RaTextInput,
  useTranslate,
} from 'react-admin';

const useStyles = makeStyles(() => ({
  inlineInput: {
    marginRight: '15px',
  },
  spinnerDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

export const OwnerCreate = (props: CreateProps) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [ownerCategory, setOwnerCategory] = useState<string | null>('company');
  const ownerTypes = [
    {
      id: 'parkOwner',
      name: translate('resources.Owner.ownerTypes.parkOwner'),
      disabled: ownerCategory === 'private',
    },
    {
      id: 'accommodationOwner',
      name: translate('resources.Owner.ownerTypes.accommodationOwner'),
    },
  ];

  const handleChange = (event: React.MouseEvent<HTMLElement>, newCategory: string | null) => {
    setOwnerCategory(newCategory);
  };

  const TextInput = ({ source, ...rest }: InputProps) => {
    return (
      <RaTextInput
        {...rest}
        source={source}
        className={classes.inlineInput}
        label={translate(`resources.Owner.fields.${source}`)}
      />
    );
  };

  const getFormToDisplay = () => {
    if (ownerCategory === 'company') {
      return (
        <>
          <Separator />
          <SectionTitle label={translate('resources.Owner.sections.createCompany')} />
          <TextInput source="company.name" validate={[required()]} />
          <TextInput source="company.addressLine1" validate={[required()]} />
          <TextInput source="company.addressLine2" />
          <TextInput source="company.postcode" validate={[required()]} />
          <TextInput source="company.city" validate={[required()]} />
          <TextInput source="company.country" validate={[required()]} />
          <TextInput source="company.siren" validate={[required()]} />
          <PhoneNumberInput source="company.phone" />
          <Separator />
          <SelectInput
            label={translate('resources.Owner.fields.ownerType')}
            source="ownerType"
            choices={ownerTypes}
            validate={[required()]}
          />
          <Separator />
          <SectionTitle label={translate('resources.Owner.sections.mainPropertyManager')} />
          <TextInput source="mainPropertyManager.person.lastName" validate={[required()]} />
          <TextInput source="mainPropertyManager.person.firstName" validate={[required()]} />
          <TextInput source="mainPropertyManager.email" validate={[required()]} />
          <PhoneNumberInput source="phone" />
        </>
      );
    }
    return (
      <>
        <Separator />
        <SectionTitle label={translate('resources.Owner.sections.createPrivate')} />
        <TextInput source="person.lastName" validate={[required()]} />
        <TextInput source="person.firstName" validate={[required()]} />
        <TextInput source="person.addressLine1" validate={[required()]} />
        <TextInput source="person.addressLine2" />
        <TextInput source="person.postcode" validate={[required()]} />
        <TextInput source="person.city" validate={[required()]} />
        <TextInput source="person.country" validate={[required()]} />
        <TextInput source="mainPropertyManager.email" validate={[required()]} />
        <PhoneNumberInput source="person.phone" />
        <Separator />
        <BirthdateInput
          variant="filled"
          label={translate('resources.Person.fields.birthdate')}
          source="person.birthdate"
          validate={[required()]}
        />
      </>
    );
  };

  return (
    <Create {...props}>
      <SimpleForm
        initialValues={{ ownerType: ownerCategory === 'private' && 'accommodationOwner' }}
      >
        <ToggleButtonGroup value={ownerCategory} exclusive onChange={handleChange}>
          <ToggleButton value="company" aria-label="company">
            <span>{translate('resources.Owner.categories.company')}</span>
          </ToggleButton>
          <ToggleButton value="private" aria-label="private">
            <span>{translate('resources.Owner.categories.private')}</span>
          </ToggleButton>
        </ToggleButtonGroup>
        <Separator />
        {getFormToDisplay()}
      </SimpleForm>
    </Create>
  );
};
