import { RangeFocus } from 'react-date-range';
import { Period } from 'types/calendar.types';

const getFocusableRange = (periods: Period[]): RangeFocus | undefined => {
  const notReadOnlyPeriods = periods.filter((period: Period) => !period.isReadOnly);
  if (notReadOnlyPeriods.length < 1) return undefined;
  const readOnlyPeriods = periods.filter((period: Period) => period.isReadOnly);
  if (readOnlyPeriods.length < 1) return [0, 0];
  const firstIndex = periods.indexOf(notReadOnlyPeriods[0]);
  return [firstIndex, 0] as RangeFocus;
};

export default getFocusableRange;
