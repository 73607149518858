import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import TranslatableValueField from 'components/CustomFields/TranslatableValueField';
import CountrySelectInput from 'components/CustomInput/CountrySelectInput';
import FloatNumberInput from 'components/CustomInput/FloatNumberInput';
import ManyToManyReferenceInput from 'components/CustomInput/ManyToManyReferenceInput';
import StarRatingInput from 'components/CustomInput/StarRatingInput';
import TimePickerInput from 'components/CustomInput/TimePickerInput';
import { translationLocales } from 'i18n';
import { toLower } from 'lodash';
import { useEffect } from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  CreateButton,
  Datagrid,
  Edit,
  EditProps,
  FormTab,
  Identifier,
  ListBase,
  ListToolbar,
  Record,
  RecordMap,
  ReferenceArrayInput,
  ReferenceInput,
  ReferenceManyField,
  required,
  TabbedForm,
  TextField,
  TextInput,
  TranslatableInputs,
  useTranslate,
} from 'react-admin';
import { useDispatch } from 'react-redux';

import MultiImageInput from '../../CustomInput/MultiImageInput';
import SortableGalleryDatagrid from '../../DragAndDrop/SortableGalleryDatagrid';
import { defineParkId } from './actions';
import ParkCalendar from './ParkCalendar';

const styles: Styles<Theme, any> = (theme) => ({
  inlineInfo: {
    [theme.breakpoints.up('md')]: { display: 'inline-block', marginRight: '15px' },
  },
  touristtax: {
    height: '83px',
    [theme.breakpoints.up('sm')]: { display: 'inline-block', marginRight: '15px' },
  },
  timeRange: {
    height: '83px',
    flex: '2',
    [theme.breakpoints.up('sm')]: { display: 'inline-block', marginRight: '15px' },
  },
  addressLine1: {
    width: '256px',
    [theme.breakpoints.up('md')]: { width: '45%', display: 'inline-block' },
    [theme.breakpoints.up('lg')]: { width: '48%', display: 'inline-block' },
  },
  addressLine2: {
    width: '256px',
    [theme.breakpoints.up('md')]: { width: '45%', display: 'inline-block', marginLeft: '32px' },
    [theme.breakpoints.up('lg')]: { width: '48%', display: 'inline-block', marginLeft: '32px' },
  },
  postcode: { [theme.breakpoints.up('md')]: { display: 'inline-block' } },
  city: { [theme.breakpoints.up('md')]: { display: 'inline-block', margin: '0 32px' } },
  title: {
    borderBottom: '1px solid #9c9c9c',
    width: '100%',
    marginTop: '30px',
    height: '35px',
    fontSize: '1.25rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: '0.0075em',
  },
});

const useStyles = makeStyles(styles);

export const ParkEdit = (props: EditProps) => {
  const classes = useStyles(styles);
  const translate = useTranslate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(defineParkId(props.id || ''));
  }, [props.id]);
  return (
    <Edit {...props}>
      <TabbedForm syncWithLocation={false}>
        <FormTab label={translate('resources.Park.tabs.infos')}>
          <TextInput source="name" validate={[required()]} formClassName={classes.inlineInfo} />
          <TextInput source="chain" formClassName={classes.inlineInfo} />
          <ReferenceInput source="ownerId" reference="Owner" formClassName={classes.inlineInfo}>
            <AutocompleteInput
              validate={[required()]}
              resettable={true}
              optionText={(record: RecordMap<Record>) => {
                if (record.company) return `${record?.company?.name}`;
                return `${record.person.firstName} ${record.person.lastName}`;
              }}
            />
          </ReferenceInput>
          <StarRatingInput
            label={translate('resources.Reviews.fields.rating')}
            source="ranking"
            defaultValue={0}
            validate={[required()]}
            formClassName={classes.inlineInfo}
          />
          <TranslatableInputs locales={translationLocales}>
            <TextInput source="description" formClassName={classes.textInput} multiline />
          </TranslatableInputs>
          <h2 className={classes.title}>{translate('resources.Park.sections.themes')}</h2>
          <ReferenceArrayInput source="themes" reference="Theme">
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
          <h2 className={classes.title}>{translate('resources.Park.sections.address')}</h2>
          <TextInput
            source="addressLine1"
            validate={[required()]}
            formClassName={classes.addressLine1}
            fullWidth
          />
          <TextInput source="addressLine2" formClassName={classes.addressLine2} fullWidth />
          <TextInput source="postcode" validate={[required()]} formClassName={classes.postcode} />
          <TextInput source="city" validate={[required()]} formClassName={classes.city} />
          <CountrySelectInput source="country" validate={[required()]} />
          <h2 className={classes.title}>{translate('resources.Park.sections.location')}</h2>
          <TextInput source="latitude" validate={[required()]} formClassName={classes.inlineInfo} />
          <TextInput
            source="longitude"
            validate={[required()]}
            formClassName={classes.inlineInfo}
          />
          <ManyToManyReferenceInput
            reference="Location"
            resource="Park"
            target="parkId"
            label="Ajouter une destination"
            renderReferenceForm={({ record }) => (
              <ReferenceInput
                filter={{ notParkId: record?.id }}
                source="locationId"
                reference="Location"
              >
                <AutocompleteInput
                  resettable={true}
                  optionText={(location: any) => {
                    const categoryName = translate(
                      `resources.Location.categories.${toLower(location.category)}`
                    );
                    return `${location.name} - (${categoryName})`;
                  }}
                />
              </ReferenceInput>
            )}
          >
            <TextField source="name" />
            <TranslatableValueField translatePath="categories" source="category" />
            <TextField source="searchRadiusKilometers" />
          </ManyToManyReferenceInput>
          <h2 className={classes.title}>{translate('resources.Park.sections.rules')}</h2>
          <BooleanInput source="disabledAccessible" />
          <BooleanInput source="petAllowed" />
          <BooleanInput source="partyAllowed" />
          <BooleanInput source="smokingAllowed" />

          <h5 className={classes.title}>{translate('resources.Park.sections.arrivalTimeRange')}</h5>
          <TimePickerInput source="arrivalTimeStartAt" defaultHours="15" defaultMinutes="0" />
          <TimePickerInput source="arrivalTimeEndAt" defaultHours="22" defaultMinutes="0" />
          <h4 className={classes.title}>
            {translate('resources.Park.sections.departureTimeRange')}
          </h4>
          <TimePickerInput source="departureTimeStartAt" defaultHours="6" defaultMinutes="0" />
          <TimePickerInput source="departureTimeEndAt" defaultHours="11" defaultMinutes="0" />
          <h2 className={classes.title}>{translate('resources.Park.sections.taxes')}</h2>
          <FloatNumberInput
            validate={[required()]}
            source="touristtax"
            label={translate('resources.Park.fields.touristtax')}
            formClassName={classes.touristtax}
            endAdornment="€"
          />
          <FloatNumberInput
            source="touristtaxNakedPlot"
            label={translate('resources.Park.fields.touristtaxNakedPlot')}
            formClassName={classes.touristtax}
            endAdornment="€"
          />
          <FloatNumberInput
            source="touristtaxRealEstate"
            label={translate('resources.Park.fields.touristtaxRealEstate')}
            formClassName={classes.touristtax}
            endAdornment="€"
          />
          <FloatNumberInput
            source="commissionPercent"
            label={translate('resources.Park.fields.commissionPercent')}
            formClassName={classes.touristtax}
            endAdornment="%"
          />
          <h2 className={classes.title}>{translate('resources.Park.sections.openingDays')}</h2>
          <ParkCalendar source="openingDays" />
          <h2 className={classes.title}>{translate('resources.Park.sections.pictures')}</h2>
          <MultiImageInput
            source="picture"
            resource="ParkPicture"
            accept="image/*"
            target="park"
            label=""
            multiple
          />
          <ReferenceManyField
            reference="ParkPicture"
            sort={{ field: 'priority', order: 'ASC' }}
            target="parkId"
            fullWidth
            label=""
          >
            <SortableGalleryDatagrid />
          </ReferenceManyField>
          <h2 className={classes.title}>{translate('resources.Park.sections.facilities')}</h2>
          <ManyToManyReferenceInput
            reference="ParkFacility"
            resource="Park"
            target="parkId"
            label="Ajouter un aménagement"
            renderReferenceForm={({ record }) => (
              <ReferenceInput
                filter={{ notParkId: record?.id }}
                source="parkFacilityId"
                reference="ParkFacility"
              >
                <AutocompleteInput resettable optionText="name" />
              </ReferenceInput>
            )}
          >
            <TextField source="name" />
          </ManyToManyReferenceInput>
        </FormTab>
        <FormTab label={translate('resources.Park.tabs.fleet')}>
          <ListBase>
            <ListToolbar
              actions={
                <div>
                  <CreateButton
                    record={{ id: props?.id as Identifier }}
                    basePath="/Fleet"
                    label="ra.action.create"
                  />
                </div>
              }
            />
          </ListBase>
          <ReferenceManyField
            resource="Fleet"
            reference="Fleet"
            target="parkId"
            label="resources.Park.tabs.fleet"
            fullWidth
          >
            <Datagrid rowClick="edit">
              <TextField source="name" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
