import i18nCountries from 'i18n-iso-countries';
import * as React from 'react';
import { useEffect } from 'react';
import { InputProps, Loading, SelectInput, useLocale } from 'react-admin';

const CountrySelectInput = (props: InputProps) => {
  const locale = useLocale();
  const [countries, setCountries] = React.useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    const getCountryList = async () => {
      i18nCountries.registerLocale(await import(`i18n-iso-countries/langs/${locale}.json`));
      const localeCountries = i18nCountries.getNames(locale);
      setCountries(
        Object.keys(localeCountries).map((id) => ({
          id,
          name: localeCountries[id],
        }))
      );
    };
    getCountryList();
  }, [locale]);
  if (countries.length === 0) return <Loading />;
  return <SelectInput {...props} choices={countries} />;
};

export default CountrySelectInput;
