import { useSortable } from '@dnd-kit/sortable';
import { makeStyles } from '@material-ui/styles';
import { CSSProperties, ReactElement } from 'react';
import { SimpleShowLayout, useShowController } from 'react-admin';

const useStyles = makeStyles({
  container: {
    '&:first-child': {
      borderTop: 'none',
    },
    touchAction: 'none',
    background: 'white',
    display: 'block',
    position: 'relative',
    borderTop: `1px solid #e0e0e3`,
    padding: '0.6rem 1rem',
    fontFamily: 'Roboto',
  },
  field: {
    margin: 0,
    padding: '0 !important',
    display: 'flex',
    gap: '20px',
    zIndex: 'inherit',
  },
});

export interface ItemProps {
  id: any;
  resource: string;
  children: ReactElement | ReactElement[];
}

export const SortableItem = ({ id, resource, children }: ItemProps) => {
  const classes = useStyles();
  const showItemController = useShowController({
    id,
    record: { id },
    basePath: `/${resource}/${id}`,
    resource,
  });
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  // Build card style
  const style = {
    cursor: isDragging ? 'grabbing' : 'grab',
    zIndex: isDragging ? 2 : 1,
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0px)` : null,
    transition,
    boxShadow: isDragging ? '2px 2px 5px gray' : null,
    borderTop: isDragging ? 'none' : null,
  } as CSSProperties;

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={classes.container}
      {...attributes}
      {...listeners}
    >
      <SimpleShowLayout {...showItemController} className={classes.field}>
        {children}
      </SimpleShowLayout>
    </div>
  );
};
