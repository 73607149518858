import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ImageField, ImageFieldProps } from 'react-admin';

import { useStorageField } from '../../hooks/useStorageField';

interface AmplifyImageFieldProps extends ImageFieldProps {
  source?: string;
  record?: any;
  storageOptions?: any;
}

const useStyles = makeStyles({
  loading: {
    '& img': {
      opacity: 0.5,
    },
  },
  iconContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
  },
});

export const AmplifyImageField = ({
  source,
  record = {},
  storageOptions = {},
  ...props
}: AmplifyImageFieldProps) => {
  const classes = useStyles();
  const fieldProps = useStorageField({ source, record, storageOptions });

  if (!fieldProps) {
    return (
      <div className={classes.loading}>
        <ImageField source={source} record={record} {...props} />
        <div className={classes.iconContainer}>
          <CircularProgress />
        </div>
      </div>
    );
  }

  return <ImageField {...fieldProps} {...props} />;
};
