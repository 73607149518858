import { InputPeriod, InputPeriods, PeriodsWithKey, RotationDays } from 'types/calendar.types';

import { getAccommodationColor, getFleetColor } from './colors';

// Récuperation d'un objet de périodes à partir d'un array
export const getPeriodsObject = (
  inputValue: InputPeriod[] | undefined,
  defaultArrivalDays: RotationDays,
  defaultDepartureDays: RotationDays,
  defaultMinimumDelay: number,
  defaultMinimumLength: number,
  defaultPrice: number
): PeriodsWithKey => {
  const initialValue = {};
  if (!inputValue || inputValue.length === 0) {
    return {
      newPeriod: {
        key: 'newPeriod',
        startDate: new Date(),
        endDate: new Date(),
        arrivalDays: defaultArrivalDays,
        departureDays: defaultDepartureDays,
        minimumDelay: defaultMinimumDelay,
        minimumLength: defaultMinimumLength,
        price: defaultPrice,
        isAvailable: true,
        ...getFleetColor(0),
      },
    };
  }
  return inputValue.reduce((periods: InputPeriods, period: InputPeriod, index: number) => {
    return {
      ...periods,
      [`selection${index}`]: {
        key: `selection${index}`,
        startDate: new Date(`${period.startAt}T00:00:00`),
        endDate: new Date(`${period.endAt}T00:00:00`),
        arrivalDays: period.arrivalDays,
        departureDays: period.departureDays,
        minimumDelay: period.minimumDelay,
        minimumLength: period.minimumLength,
        price: period.price,
        isAvailable: period.available,
        ...getFleetColor(index),
      },
    };
  }, initialValue);
};

// Récuperation d'un objet d'availablePeriods à partir d'un array
export const getAvailablePeriodsObject = (
  inputValue: InputPeriod[] | undefined,
  defaultArrivalDays: RotationDays,
  defaultDepartureDays: RotationDays,
  defaultMinimumDelay: number,
  defaultMinimumLength: number,
  defaultPrice: number
): PeriodsWithKey => {
  const initialValue = {};
  let availablePeriods;
  if (inputValue && inputValue.length !== 0) {
    availablePeriods = inputValue.filter((period) => period.available !== false);
  }
  if (!availablePeriods || availablePeriods.length === 0) {
    return {
      newPeriod: {
        key: 'newPeriod',
        startDate: new Date(),
        endDate: new Date(),
        arrivalDays: defaultArrivalDays,
        departureDays: defaultDepartureDays,
        minimumDelay: defaultMinimumDelay,
        minimumLength: defaultMinimumLength,
        price: defaultPrice,
        isAvailable: true,
        ...getAccommodationColor(false, true),
      },
    };
  }
  return availablePeriods.reduce((periods: InputPeriods, period: InputPeriod, index: number) => {
    return {
      ...periods,
      [`selection${index}`]: {
        key: `selection${index}`,
        // Définition des heures afin d'éviter des probèmes de collision des périodes
        startDate: new Date(`${period.startAt}T00:00:00`),
        endDate: new Date(`${period.endAt}T00:00:00`),
        arrivalDays: period.arrivalDays,
        departureDays: period.departureDays,
        minimumDelay: period.minimumDelay,
        minimumLength: period.minimumLength,
        price: period.price,
        isAvailable: true,
        ...(period.isReadOnly ? { isReadOnly: true } : {}),
        ...getAccommodationColor(!!period.isReadOnly, true),
      },
    };
  }, initialValue);
};

// Récuperation d'un objet d'unavailablePeriods à partir d'un array
export const getUnavailablePeriodsObject = (
  inputValue: InputPeriod[] | undefined
): PeriodsWithKey => {
  const initialValue = {};
  let unavailablePeriods;
  if (inputValue && inputValue.length !== 0) {
    unavailablePeriods = inputValue.filter((period) => period.available === false);
  }
  if (!unavailablePeriods || unavailablePeriods.length === 0) return initialValue;
  return unavailablePeriods.reduce((periods: InputPeriods, period: InputPeriod, index: number) => {
    return {
      ...periods,
      [`selection${index}`]: {
        key: `selection${index}`,
        // Définition des heures afin d'éviter des probèmes de collision des périodes
        startDate: new Date(`${period.startAt}T00:00:00`),
        endDate: new Date(`${period.endAt}T00:00:00`),
        arrivalDays: period.arrivalDays,
        departureDays: period.departureDays,
        minimumDelay: period.minimumDelay,
        minimumLength: period.minimumLength,
        price: undefined,
        isAvailable: period.available,
        ...getAccommodationColor(false, false),
      },
    };
  }, initialValue);
};
