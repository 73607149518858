import { Reducer } from 'redux';

import { PROPERTY_MANAGER_SCOPE_BY_OWNER } from '../components/pages/owners/actions';

const initialState = '';

const propertyManagerScopeReducer: Reducer = (state = initialState, { type, payload }) => {
  if (type === PROPERTY_MANAGER_SCOPE_BY_OWNER) {
    return payload;
  }
  return state;
};
export default propertyManagerScopeReducer;
