import {
  AutocompleteInput,
  Create,
  CreateProps,
  Record,
  RecordMap,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { FleetCreateDataForm } from 'types/fleet.types';

export const FleetCreate = (props: CreateProps) => {
  const translate = useTranslate();
  const parkId = useSelector((state: FleetCreateDataForm) => state.fleetScope.parkId);
  if (parkId === '') return null;
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput
          label={translate('resources.ParkOffer.fields.park.name')}
          source="parkId"
          reference="Park"
          initialValue={parkId}
        >
          <AutocompleteInput
            disabled
            optionText={(record: RecordMap<Record>) => `${record.name}`}
          />
        </ReferenceInput>
        <TextInput source="name" validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};
