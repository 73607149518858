import { makeStyles, Theme } from '@material-ui/core';
import { Styles } from '@material-ui/styles';
import { DefaultRotationDaysSelection } from 'components/Calendar/DefaultRotationDaysSelection';
import FloatNumberInput from 'components/CustomInput/FloatNumberInput';
import IntegerInput from 'components/CustomInput/IntegerInput';
import ManyToManyReferenceInput from 'components/CustomInput/ManyToManyReferenceInput';
import { SectionTitle, Separator } from 'components/formUtils';
import {
  AutocompleteInput,
  Edit,
  EditProps,
  FormTab,
  ReferenceInput,
  TabbedForm,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { FleetCreateDataForm } from 'types/fleet.types';

import FleetCalendar from './FleetCalendar';

const styles: Styles<Theme, any> = (theme) => ({
  defaultRotationDays: {
    [theme.breakpoints.up('md')]: { flexWrap: 'nowrap' },
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  defaultValuesOthers: {
    [theme.breakpoints.up('sm')]: { flexDirection: 'column-reverse' },
    [theme.breakpoints.up('lg')]: { flexDirection: 'row' },
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row',
  },
  defaultValue: {
    [theme.breakpoints.down('md')]: { width: '40%', margin: '10px 0' },
    [theme.breakpoints.up('md')]: { width: '350px', marginRight: '30px' },
  },
});

const useStyles = makeStyles(styles);

export const FleetEdit = (props: EditProps) => {
  const classes = useStyles(styles);
  const translate = useTranslate();
  const parkId = useSelector((state: FleetCreateDataForm) => state.fleetScope.parkId);
  if (parkId === '') return null;
  return (
    <Edit {...props}>
      <TabbedForm redirect={false}>
        <FormTab label={'resources.Fleet.tabs.availabilities'}>
          <FleetCalendar source="periods" />
          <SectionTitle label={translate('resources.Fleet.sections.defaultValues')} />
          <DefaultRotationDaysSelection
            label={translate('pos.arrivalDays')}
            source="defaultArrivalDays"
            className={classes.defaultRotationDays}
          />
          <Separator />
          <DefaultRotationDaysSelection
            label={translate('pos.departureDays')}
            source="defaultDepartureDays"
            className={classes.defaultRotationDays}
          />
          <Separator />
          <div className={classes.defaultValuesOthers}>
            <FloatNumberInput
              className={classes.defaultValue}
              label={translate('resources.Fleet.fields.defaultPrice')}
              source="defaultPrice"
              endAdornment="€"
            />
            <IntegerInput
              className={classes.defaultValue}
              source="defaultMinimumDelay"
              label={translate('resources.Fleet.fields.defaultMinimumDelay')}
            />
            <IntegerInput
              className={classes.defaultValue}
              source="defaultMinimumLength"
              label={translate('resources.Fleet.fields.defaultMinimumLength')}
            />
          </div>
        </FormTab>
        <FormTab label={'resources.Fleet.tabs.infos'}>
          <TextInput source="name" />
          <Separator />
          <ManyToManyReferenceInput
            reference="Accommodation"
            resource="Fleet"
            target="fleetId"
            label={translate('resources.Fleet.addAccommodation')}
            renderReferenceForm={() => (
              <ReferenceInput
                source="accommodationId"
                reference="Accommodation"
                filter={{ parkId, withoutFleet: true }}
              >
                <AutocompleteInput resettable optionText="name" />
              </ReferenceInput>
            )}
          >
            <TextField source="name" />
          </ManyToManyReferenceInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
