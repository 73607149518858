import { useSortable } from '@dnd-kit/sortable';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { CSSProperties, MouseEvent, useEffect, useState } from 'react';
import { Confirm, DatagridRowProps, useTranslate } from 'react-admin';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    display: 'flex',
    boxSizing: 'border-box',
    transformOrigin: '0 0',
    touchAction: 'none',
    width: 200,
    height: 200,
    padding: 0,
    margin: 2,
    // gridColumn: 'span 2',
    background: '#292828',
    color: '#fff',
  },
  picture: {
    display: 'inline-block',
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  dragButton: {
    color: 'rgba(255, 255, 255, 0.7)',
    position: 'absolute',
    top: 10,
    right: 10,
    cursor: 'grab',
  },
  removeButton: {
    color: 'rgba(255, 255, 255, 0.7)',
    position: 'absolute',
    top: 10,
    right: 40,
  },
  toggleFeaturedButton: {
    color: 'rgba(255, 255, 255, 0.7)',
    position: 'absolute',
    top: 10,
    right: 70,
  },
});

export interface ItemProps extends DatagridRowProps {
  id: string;
  onRemove: () => Promise<void>;
  onToogleFeatured: (event: MouseEvent<HTMLButtonElement>) => Promise<void>;
  getUrl: (key: string) => Promise<string>;
}

export const SortablePicture = ({ id, record, onRemove, getUrl, onToogleFeatured }: ItemProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [isConfirmDeletehOpen, setConfirmDeleteOpen] = useState(false);
  const [url, setUrl] = useState<string | undefined>(undefined);
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
    transition: {
      duration: 150,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  });

  // Get signed url from S3
  useEffect(() => {
    async function fetchPicture() {
      setUrl(await getUrl(record?.picture.key));
    }
    fetchPicture();
  }, [record]);

  // Build card style
  const style = {
    borderRadius: isDragging ? 5 : 0,
    cursor: isDragging ? 'grabbing' : 'initial',
    zIndex: isDragging ? 2 : 1,
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0px) scale(${isDragging ? 1.05 : 1})`
      : null,
    transition,
    boxShadow: isDragging ? '10px 10px 30px black' : null,
  } as CSSProperties;

  const handlePublishConfirmation = () => setConfirmDeleteOpen(true);
  const handleClosePublishConfirmation = () => setConfirmDeleteOpen(false);
  const handleDelete = () => {
    onRemove();
    setConfirmDeleteOpen(false);
  };

  return (
    <li ref={setNodeRef} className={classes.container} style={style}>
      <IconButton
        {...attributes}
        {...listeners}
        className={classes.dragButton}
        size="small"
        aria-label="delete"
      >
        <DragHandleIcon fontSize="inherit" />
      </IconButton>
      <IconButton
        onClick={handlePublishConfirmation}
        className={classes.removeButton}
        size="small"
        aria-label="delete"
      >
        <DeleteOutlineIcon fontSize="inherit" />
      </IconButton>
      <Confirm
        isOpen={isConfirmDeletehOpen}
        title={translate(`ra.message.are_you_sure`)}
        content={translate(`ra.message.bulk_delete_content`, 1)}
        onConfirm={handleDelete}
        onClose={handleClosePublishConfirmation}
      />
      <IconButton
        onClick={onToogleFeatured}
        className={classes.toggleFeaturedButton}
        size="small"
        aria-label="toggle featured"
        title={record?.publishedAt ? 'Retirer cette photo' : 'Publier cette photo'}
      >
        {record?.publishedAt ? (
          <CheckCircleIcon color="primary" fontSize="inherit" />
        ) : (
          <CheckCircleIcon fontSize="inherit" />
        )}
      </IconButton>
      <img src={url} className={classes.picture} />
    </li>
  );
};
