import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
} from 'date-fns';
import { useTranslate } from 'react-admin';

interface DateDiffProps {
  date: Date;
}

const DateDiff = ({ date }: DateDiffProps) => {
  const translate = useTranslate();
  const now = new Date();
  const years = differenceInYears(now, date);
  if (years && years > 0) {
    return <>{translate('pos.dateDiff.diffInYears', years)}</>;
  }
  const months = differenceInMonths(now, date);
  if (months && months > 0) {
    return <>{translate('pos.dateDiff.diffInMonths', months)}</>;
  }
  const weeks = differenceInWeeks(now, date);
  if (weeks && weeks > 0) {
    return <>{translate('pos.dateDiff.diffInWeeks', weeks)}</>;
  }
  const days = differenceInDays(now, date);
  if (days && days > 0) {
    return <>{translate('pos.dateDiff.diffInDays', days)}</>;
  }
  const hours = differenceInHours(now, date);
  if (hours && hours > 0) {
    return <>{translate('pos.dateDiff.diffInHours', hours)}</>;
  }
  const minutes = differenceInMinutes(now, date);
  return <>{translate('pos.dateDiff.diffInMinutes', minutes === 0 ? 1 : minutes)}</>;
};

export default DateDiff;
