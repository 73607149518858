import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import CountrySelectInput from 'components/CustomInput/CountrySelectInput';
import { translationLocales } from 'i18n';
import * as React from 'react';
import {
  Create,
  CreateProps,
  required,
  SimpleForm,
  TextInput,
  TranslatableInputs,
  useTranslate,
} from 'react-admin';

import StarRatingInput from '../../CustomInput/StarRatingInput';
import { SectionTitle, Separator } from '../../formUtils';

export const styles: Styles<Theme, any> = (theme) => ({
  parkName: { [theme.breakpoints.up('md')]: { display: 'inline-block' } },
  parkStar: { [theme.breakpoints.up('md')]: { display: 'inline-block', margin: '0 32px' } },
  addressLine1: {
    width: '256px',
    [theme.breakpoints.up('md')]: { width: '45%', display: 'inline-block' },
    [theme.breakpoints.up('lg')]: { width: '48%', display: 'inline-block' },
  },
  addressLine2: {
    width: '256px',
    [theme.breakpoints.up('md')]: { width: '45%', display: 'inline-block', marginLeft: '32px' },
    [theme.breakpoints.up('lg')]: { width: '48%', display: 'inline-block', marginLeft: '32px' },
  },
  postcode: { [theme.breakpoints.up('md')]: { display: 'inline-block' } },
  city: { [theme.breakpoints.up('md')]: { display: 'inline-block', margin: '0 32px' } },
  country: { [theme.breakpoints.up('md')]: { display: 'inline-block' } },
});

const useStyles = makeStyles(styles);

export const ParkCreate = (props: CreateProps) => {
  const translate = useTranslate();
  const classes = useStyles(styles);
  return (
    <Create {...props}>
      <SimpleForm>
        <SectionTitle label={translate('resources.Park.sections.infos')} />
        <TextInput
          source="name"
          validate={[required()]}
          formClassName={classes.parkName}
          autoFocus
        />
        <StarRatingInput
          label={translate('resources.Reviews.fields.rating')}
          source="ranking"
          formClassName={classes.parkStar}
          defaultValue={0}
        />
        <TranslatableInputs locales={translationLocales}>
          <TextInput source="description" formClassName={classes.textInput} />
        </TranslatableInputs>
        <Separator />
        <SectionTitle label={translate('resources.Park.sections.address')} />
        <TextInput
          source="addressLine1"
          validate={[required()]}
          fullWidth
          formClassName={classes.addressLine1}
        />
        <TextInput source="addressLine2" fullWidth formClassName={classes.addressLine2} />
        <TextInput source="postcode" validate={[required()]} formClassName={classes.postcode} />
        <TextInput source="city" validate={[required()]} formClassName={classes.city} />
        <CountrySelectInput source="country" validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};
