import { AmplifyImageInput } from 'components/CustomInput/AmplifyImageInput';
import { translationLocales } from 'i18n';
import {
  Edit,
  EditProps,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  TranslatableInputs,
  useTranslate,
} from 'react-admin';

import { getCategoryChoices } from './index';

export const LocationEdit = (props: EditProps) => {
  const translate = useTranslate();
  const categoryChoices = getCategoryChoices(translate);
  return (
    <Edit {...props}>
      <SimpleForm>
        <TranslatableInputs locales={translationLocales}>
          <TextInput source="localizedName" validate={[required()]} />
        </TranslatableInputs>
        <SelectInput source="category" choices={categoryChoices} validate={[required()]} />
        <TextInput source="lat" validate={[required()]} />
        <TextInput source="lng" validate={[required()]} />
        <NumberInput source="searchRadiusKilometers" validate={[required()]} />
        <AmplifyImageInput source="picture" accept="image/*" />
      </SimpleForm>
    </Edit>
  );
};
