import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';

const styles: Styles<Theme, any> = () => ({
  calendar: {
    display: 'flex',
    flexDirection: 'column',
  },
  dateRangePicker: {
    '& .rdrMonthAndYearWrapper select': {
      fontSize: '20px',
    },
    '& .rdrMonths': {
      alignItems: 'center',
      justifyContent: 'space-evenly',
      flexDirection: 'row',
      '& .rdrMonth': {
        flexDirection: 'row',
        width: '250px',
      },
    },
  },
  periods: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',
  },
  periodInfos: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    margin: '5px 0',
  },
  dateTextField: {
    margin: '0 5px',

    '& .MuiInputBase-root': {
      height: '30px',

      '& .MuiInputBase-inputHiddenLabel': {
        textAlign: 'center',
        cursor: 'pointer',
      },
    },
  },
  priceTextField: {
    margin: '0 5px',

    '& .MuiInputBase-root': {
      height: '30px',

      '& .MuiInputBase-inputHiddenLabel': {
        textAlign: 'center',
      },
    },
  },
  spinnerDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '10rem',
  },
});

export default styles;
