import DateDiff from 'components/Utils/DateDiff';
import { format as formatDate, isValid } from 'date-fns';
import get from 'lodash/get';
import { FieldProps } from 'react-admin';

interface CustomDateFieldProps extends FieldProps {
  withDiff?: boolean;
  format?: string;
}

const CustomDateField = ({
  record,
  source,
  format = 'dd/MM/yyyy HH:mm',
  withDiff = false,
}: CustomDateFieldProps) => {
  const date = new Date(get(record, source || ''));
  if (!isValid(date)) return null;
  return (
    <span>
      {formatDate(date, format)} ({withDiff && <DateDiff date={date} />})
    </span>
  );
};

CustomDateField.defaultProps = {
  addLabel: true,
};

export default CustomDateField;
