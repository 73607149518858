import InlineSvgField from 'components/CustomFields/InlineSvgField';
import { Datagrid, List, ListProps, TextField } from 'react-admin';

export const ParkFacilityList = (props: ListProps) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <InlineSvgField source="svg" size="small" />
      <TextField source="name" />
    </Datagrid>
  </List>
);
